import { UseMutateFunction, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DocumentType, TaskDTO, TaskType } from '../../api/dto';
import { getTasks } from '../../api/task';
import { HttpCode } from '../../api/types';
import { isApiError } from '../../api/utils';
import { artificialDelay } from '../../lib/delay';
import { makeDocumentTaskFilter } from '../../lib/filters/tasks';
import { translateApiError } from '../../lib/language';
import { TaskActionConfiguration } from './TaskTypeConfiguration';
import { useTaskCanBeCompleted } from './useTaskCanBeCompleted';
import { useTaskIsImportant } from './useTaskIsImportant';

/**
 * Task types ordered by importance (most important first).
 * If a value is missing from the array it will simply be considered as being of lowest importance.
 */
const TASK_TYPE_ORDER: TaskType[] = [
    // Signature
    TaskType.SIGNATURE,

    // Edit and submit
    TaskType.SUBMIT,
    TaskType.EDIT,

    TaskType.ADD_IS37_SENSORS,
    TaskType.DISABLE_SENSORS,
    TaskType.RECOMMISSION_SENSORS,
    TaskType.SCHEDULE_RECOMMISSIONING,
    TaskType.DIMR_CLOSURE,
    TaskType.DIMR_FEEDBACK,
    TaskType.FACILITY_COORDINATOR_SCHEDULE,

    TaskType.COMPLETE_WORK,
    TaskType.CONFIRM_RECEPTION_YELLOW_PAPER,
    TaskType.CONFIRM_RETURN_YELLOW_PAPER,
    TaskType.LOCK_LOCKOUT,
    TaskType.PROVIDE_YELLOW_PAPER,
    TaskType.RETURN_YELLOW_PAPER,
    TaskType.UNLOCK_LOCKOUT,

    // Below are actions that are possible but not mandatory

    TaskType.ADD_COMMENT,

    TaskType.FINISH,
    TaskType.ATTACH_IS37,
    TaskType.EDIT_SENSORS,
    TaskType.CHANGE_ALARM_TYPE,
    TaskType.CREATE_IS37_CHANGE_REQUEST,
    TaskType.FP_CLOSEOUT,
    TaskType.CLOSE,
    TaskType.CREATE_CHANGE_REQUEST,
    TaskType.ADD_SIGNATURE_TASK,

    // Soft interruption
    TaskType.DIMR_NEW_VERSION,
    TaskType.ACTIVITY_INTERRUPT,
    TaskType.ACTIVITY_RESUME,

    // Cancellation
    TaskType.CANCEL_CHANGE_REQUEST,
    TaskType.CANCEL,
];

/**
 * If there are overlapping completable tasks, only the most general one will appear in "my tasks".
 * This is configured with this array:
 * - Left-hand side: the overlapping, less general task (= least useful)
 * - Right-hand side: the most general task (= most useful)
 * The task itself should normally not appear on the right-hand side. The graph must be acyclic.
 * Transitivity is theoretically possible but NOT currently supported (implementation restriction).
 */
const TASK_REDUNDANCY: Partial<Record<TaskType, TaskType>> = {
    [TaskType.EDIT]: TaskType.SUBMIT,
};

export type OnSubmitCallback = (
    taskHandler: UseMutateFunction<
        any,
        AxiosResponse<any, any>,
        any, // The document form
        unknown
    >
) => Promise<void>;
interface DocumentDetails {
    documentType: DocumentType;
    documentId: number;
}
interface UseTasksProps {
    documentDetails: [DocumentDetails, ...DocumentDetails[]];
    onTaskSubmit: OnSubmitCallback;
    onTaskError: (response: AxiosResponse) => void;
    refreshData: () => void;
    pageSize?: number;
}

const handleError = (error: any, props: UseTasksProps, intl: IntlShape) => {
    if (isApiError(error) && error?.status === HttpCode.CONFLICT) {
        const validationError = translateApiError(error.data, intl.locale);
        toast.warning(validationError.message);
    } else {
        props?.onTaskError(error);
    }
};

export const useTasks = (props: UseTasksProps | undefined) => {
    const navigate = useNavigate();
    const intl = useIntl();
    const {
        isLoading,
        isError,
        data: tasksRaw,
    } = useQuery(
        ['tasks', props?.documentDetails.map((d) => `${d.documentType}:${d.documentId}`)?.join(',')],
        () => {
            if (props === undefined) throw new Error(); // Should not happen, due to `enabled`
            return getTasks({
                filter: makeDocumentTaskFilter({
                    documentDetails: props.documentDetails,
                }),
                size: props.pageSize || 1000,
            });
        },
        {
            enabled: props !== undefined,
        }
    );
    const { isLoading: isMutationLoading, mutateAsync: perform } = useMutation(
        async ({
            task,
            action,
            comment,
        }: {
            task: TaskDTO;
            action: TaskActionConfiguration;
            comment: string | null;
        }) => {
            if (props === undefined) {
                throw new Error(); // Should not happen
            }
            return props
                .onTaskSubmit((data) =>
                    action.mutation({ id: props.documentDetails[0].documentId, task, data, comment, navigate })
                )
                .then(artificialDelay);
        },
        props !== undefined
            ? {
                  onSuccess: props.refreshData,
                  onError: (error: any) => handleError(error, props, intl),
              }
            : undefined
    );
    const { isLoading: isValidationLoading, mutateAsync: validate } = useMutation(
        async ({
            task,
            action,
            callback,
        }: {
            task: TaskDTO;
            action: TaskActionConfiguration;
            callback: () => void;
        }) => {
            if (props === undefined) {
                throw new Error();
            }
            const { validation } = action;
            if (!validation) {
                throw new Error();
            }
            return props.onTaskSubmit((data) =>
                validation({ id: props.documentDetails[0].documentId, task, data, comment: null, navigate }).then(() =>
                    callback()
                )
            );
        },
        props !== undefined
            ? {
                  onError: (error: any) => handleError(error, props, intl),
              }
            : undefined
    );
    const canBeCompleted = useTaskCanBeCompleted();

    // The tasks are organized in three categories:
    // 1. Active & assigned to the current user
    // 2. Active & assigned to other people
    // 3. Closed (= completed or cancelled)
    // Within the first two categories, the tasks are then sorted using the following order:
    // 1. Type (see `TASK_TYPE_ORDER`)
    // 2. Created date (earliest first)
    // And within the third
    // 1. Performed (performed first, not performed last)
    // 2. Closed date (latest first)
    const sort = useCallback(
        <T>(...comparators: ((task: T) => number)[]) =>
            (items: T[]): T[] =>
                items.sort(
                    (a, b) => comparators.map((comparing) => comparing(a) - comparing(b)).filter((r) => r !== 0)[0] ?? 0
                ),
        []
    );
    const sortActiveTasks = useCallback(
        (tasks: TaskDTO[]) => {
            const createdDateComparator = (task: TaskDTO) => new Date(task.createdDate).getTime();
            const typeComparator = (task: TaskDTO) => {
                const index = TASK_TYPE_ORDER.indexOf(task.type.type);
                return index !== -1 ? index : TASK_TYPE_ORDER.length;
            };
            return sort(typeComparator, createdDateComparator)(tasks);
        },
        [sort]
    );
    const sortInactiveTasks = useCallback(
        (tasks: TaskDTO[]) => {
            const closedDateComparator = (task: TaskDTO) => -new Date(task.closedDate ?? task.updatedDate).getTime();
            return sort(closedDateComparator)(tasks);
        },
        [sort]
    );

    const tasksCategories = useMemo(() => {
        const assignableTasks: TaskDTO[] = [];
        const otherTasks: TaskDTO[] = [];
        const completedTasks: TaskDTO[] = [];
        const cancelledTasks: TaskDTO[] = [];
        tasksRaw?.content?.forEach((task) => {
            if (task.taskActive) {
                if (canBeCompleted(task)) {
                    assignableTasks.push(task);
                } else {
                    otherTasks.push(task);
                }
            } else {
                if (task.taskExecutor !== null) {
                    completedTasks.push(task);
                } else {
                    cancelledTasks.push(task);
                }
            }
        });
        const assignableTaskTypes = new Set(assignableTasks.map((task) => task.type.type));
        const actuallyAssignableTasks: TaskDTO[] = [];
        assignableTasks.forEach((task) => {
            const parent = TASK_REDUNDANCY[task.type.type];
            if (parent !== undefined && assignableTaskTypes.has(parent)) {
                otherTasks.push(task);
            } else {
                actuallyAssignableTasks.push(task);
            }
        });
        return {
            assignableTasks: sortActiveTasks(actuallyAssignableTasks),
            otherTasks: sortActiveTasks(otherTasks),
            completedTasks: sortInactiveTasks(completedTasks),
            cancelledTasks: sortInactiveTasks(cancelledTasks),
        } satisfies Record<string, TaskDTO[]>;
    }, [tasksRaw, canBeCompleted, sortActiveTasks, sortInactiveTasks]);

    const [currentTask, setCurrentTask] = useState<TaskDTO | null>(null);

    const isTaskImportant = useTaskIsImportant();

    useLayoutEffect(() => {
        // Fine in this context
        const { assignableTasks } = tasksCategories;
        const importantTasks = assignableTasks.filter(isTaskImportant);
        if (importantTasks.length > 0) {
            setCurrentTask(importantTasks[0]);
        }
    }, [setCurrentTask, tasksCategories, isTaskImportant]);

    return {
        isLoading,
        isError,
        disabled: isMutationLoading || isValidationLoading,
        ...tasksCategories,
        currentTask,
        setCurrentTask,
        perform,
        validate,
    };
};
