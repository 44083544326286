import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Fragment, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import z from 'zod';
import { getActivity } from '../../../api/activities';
import {
    getFirePermit,
    getFirePermitValidationRules,
    getFirePermitWorkerTypes,
} from '../../../api/documents/firePermits';
import { DocumentType, FirePermitWorkerTypeCode } from '../../../api/dto';
import { getLocation } from '../../../api/locations';
import { ImpactHelmet } from '../../../components/ImpactHelmet';
import { defaultUrlParamsSchema, withUrlParams } from '../../../components/wrappers/withUrlParams';
import { FirePermitForm } from '../../../forms/documents/FirePermitForm';
import { FirePermitPageVariant } from './FirePermitPageVariant';

const schema = z.union([
    defaultUrlParamsSchema.extend({
        variant: z.literal(FirePermitPageVariant.CREATE),
        queryParams: z.object({
            activity: z.coerce.number(),
            location: z.coerce.number().optional(),
        }),
    }),
    defaultUrlParamsSchema.extend({
        variant: z.union([z.literal(FirePermitPageVariant.CLONE), z.literal(FirePermitPageVariant.READ)]),
        pathParams: z.object({
            id: z.coerce.number(),
        }),
    }),
]);

const FirePermitContent: React.FC<z.infer<typeof schema>> = ({ variant, pathParams, queryParams }) => {
    const intl = useIntl();

    const {
        data: firePermitData,
        isLoading: firePermitDataLoading,
        isError: firePermitDataFailed,
        remove,
        refetch,
    } = useQuery(
        [DocumentType.FP, variant !== FirePermitPageVariant.CREATE ? pathParams.id : undefined],
        () => {
            if (variant === FirePermitPageVariant.CREATE) {
                throw new Error();
            }
            return getFirePermit({ id: String(pathParams.id) });
        },
        {
            enabled: variant !== FirePermitPageVariant.CREATE,
        }
    );

    const activityId = variant === FirePermitPageVariant.CREATE ? queryParams.activity : firePermitData?.activity.id;
    const locationId = variant === FirePermitPageVariant.CREATE ? queryParams.location : undefined;

    const {
        data: activityData,
        isLoading: isActivityDataLoading,
        isError: activityDataFailed,
    } = useQuery(
        ['activity', activityId],
        () => {
            if (activityId === undefined) {
                throw new Error();
            }
            return getActivity({ id: String(activityId) });
        },
        {
            enabled: activityId !== undefined,
        }
    );

    const {
        data: locationData,
        isLoading: isLocationDataLoading,
        isError: locationDataFailed,
    } = useQuery(
        ['location', locationId],
        () => {
            if (locationId === undefined) {
                throw new Error();
            }
            return getLocation({ id: String(locationId) });
        },
        {
            enabled: locationId !== undefined,
        }
    );

    const {
        data: rulesData,
        isLoading: isRulesDataLoading,
        isError: rulesDataFailed,
    } = useQuery([DocumentType.FP, variant === FirePermitPageVariant.READ ? pathParams.id : undefined, 'rules'], () =>
        getFirePermitValidationRules({ id: variant === FirePermitPageVariant.READ ? pathParams.id : undefined })
    );

    const {
        data: workerTypesData,
        isLoading: isWorkerTypesDataLoading,
        isError: workerTypesDataFailed,
    } = useQuery(['firePermitWorkerTypes'], () => getFirePermitWorkerTypes());

    const refreshData = useCallback(() => {
        remove();
        return refetch();
    }, [remove, refetch]);

    if (
        (firePermitDataLoading && variant !== FirePermitPageVariant.CREATE) ||
        (isActivityDataLoading && activityId !== undefined) ||
        (isLocationDataLoading && locationId !== undefined) ||
        isRulesDataLoading ||
        isWorkerTypesDataLoading
    ) {
        return (
            <>
                <LinearProgress />
                <ImpactHelmet documentType={DocumentType.FP} title={intl.formatMessage({ id: 'title.loading' })} />
            </>
        );
    } else if (
        firePermitDataFailed ||
        activityDataFailed ||
        rulesDataFailed ||
        workerTypesDataFailed ||
        locationDataFailed
    ) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                <FormattedMessage id="firePermit.error.document" />
                <ImpactHelmet documentType={DocumentType.FP} title={intl.formatMessage({ id: 'title.error' })} />
            </Alert>
        );
    } else if (
        (variant === FirePermitPageVariant.CREATE && activityData && (locationId === undefined || locationData)) ||
        (variant === FirePermitPageVariant.CLONE && firePermitData) ||
        (variant === FirePermitPageVariant.READ && firePermitData)
    ) {
        return (
            <FirePermitForm
                data={
                    variant === FirePermitPageVariant.CREATE
                        ? { variant, activityData: activityData!, locationData: locationData }
                        : { variant, firePermitData: firePermitData!, activityData: activityData! }
                }
                defaultWorkerType={workerTypesData.find(
                    (workerType) => workerType.code === FirePermitWorkerTypeCode.INTERNAL
                )}
                rules={rulesData}
                refreshData={refreshData}
            />
        );
    } else {
        return <Fragment />;
    }
};

export const FirePermitPage = withUrlParams(FirePermitContent, schema);
