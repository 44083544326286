import { createContext, useContext, useMemo } from 'react';
import { FieldAttributesBase } from './rule/deserializer';

const FieldsAttributesContext = createContext<Record<string, FieldAttributesBase> | undefined>(undefined);

export const useFieldsAttributesContext = (fields: (string | undefined)[][]): FieldAttributesBase[][] => {
    const record = useContext(FieldsAttributesContext);
    const defaultValues: FieldAttributesBase = useMemo(
        () => ({
            editable: true,
            visible: true,
            mandatory: false,
        }),
        []
    );
    return useMemo(
        () =>
            fields.map((array) =>
                array.map((field) => {
                    if (field !== undefined && record !== undefined) {
                        const value = record[field];
                        return value ?? defaultValues;
                    } else {
                        return defaultValues;
                    }
                })
            ),
        [fields, record, defaultValues]
    );
};

export const FieldsAttributesContextProvider = FieldsAttributesContext.Provider;
