import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { Fragment, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import z from 'zod';
import { getActivity } from '../../../api/activities';
import { getNdcByIdOrNdcNumber, getNdcValidationRules } from '../../../api/documents/noteDeCoupures';
import { DocumentType } from '../../../api/dto';
import { ImpactHelmet } from '../../../components/ImpactHelmet';
import { defaultUrlParamsSchema, withUrlParams } from '../../../components/wrappers/withUrlParams';
import { NoteDeCoupureForm } from '../../../forms/documents/NoteDeCoupureForm';
import { getRoute, ROUTES } from '../../../routes/routes';
import { NdcPageVariant } from './NdcPageVariant';

const schema = z.union([
    defaultUrlParamsSchema.extend({
        variant: z.literal(NdcPageVariant.CREATE),
        queryParams: z.object({
            activity: z.coerce.number(),
        }),
    }),
    defaultUrlParamsSchema.extend({
        variant: z.literal(NdcPageVariant.CLONE),
        pathParams: z.object({
            id: z.string(),
        }),
        queryParams: z.object({
            activity: z.coerce.number(),
        }),
    }),
    defaultUrlParamsSchema.extend({
        variant: z.literal(NdcPageVariant.READ),
        pathParams: z.object({
            id: z.string(),
        }),
    }),
]);

const NoteDeCoupureContent: React.FC<z.infer<typeof schema>> = ({ variant, pathParams, queryParams }) => {
    const { formatMessage } = useIntl();
    const {
        data: noteDeCoupureData,
        isLoading: isDataLoading,
        isError: ndcDataFailed,
        remove,
        refetch,
    } = useQuery(
        [DocumentType.NDC, variant !== NdcPageVariant.CREATE ? pathParams.id : undefined],
        () => {
            if (variant === NdcPageVariant.CREATE) {
                throw new Error();
            }
            return getNdcByIdOrNdcNumber({ idOrNdcNumber: pathParams.id });
        },
        {
            enabled: variant !== NdcPageVariant.CREATE,
        }
    );
    const refreshNoteDeCoupureData = useCallback(() => {
        remove();
        return refetch();
    }, [remove, refetch]);

    const navigate = useNavigate();
    const activityId =
        variant === NdcPageVariant.READ ? noteDeCoupureData?.activity.id ?? undefined : queryParams.activity;

    useEffect(() => {
        if (variant !== NdcPageVariant.CREATE && noteDeCoupureData && String(noteDeCoupureData.id) !== pathParams.id) {
            let path: string;
            if (variant === NdcPageVariant.READ) {
                path = ROUTES.noteDeCoupure.view;
            } else if (variant === NdcPageVariant.CLONE) {
                path = ROUTES.noteDeCoupure.clone;
            } else {
                throw new Error();
            }
            navigate(
                getRoute({
                    path,
                    params: { id: String(noteDeCoupureData.id) },
                    queryParams: variant === NdcPageVariant.CLONE ? { activity: String(activityId) } : undefined,
                }),
                {
                    replace: true,
                }
            );
        }
    }, [
        variant,
        noteDeCoupureData,
        variant !== NdcPageVariant.CREATE ? pathParams.id : undefined,
        activityId,
        navigate,
    ]);

    const {
        data: activityData,
        isLoading: isActivityDataLoading,
        isError: activityDataFailed,
    } = useQuery(['activity', activityId], () => getActivity({ id: String(activityId) }), {
        enabled: activityId !== undefined,
    });

    const {
        data: rulesData,
        isLoading: isRulesDataLoading,
        isError: rulesDataFailed,
    } = useQuery([DocumentType.NDC, variant !== NdcPageVariant.CREATE ? pathParams.id : undefined, 'rules'], () =>
        getNdcValidationRules({ idOrNdcNumber: variant !== NdcPageVariant.CREATE ? pathParams.id : undefined })
    );

    if (
        (isDataLoading && variant !== NdcPageVariant.CREATE) ||
        (isActivityDataLoading && activityId !== undefined) ||
        isRulesDataLoading
    ) {
        return (
            <>
                <LinearProgress />
                <ImpactHelmet documentType={DocumentType.NDC} title={formatMessage({ id: 'title.loading' })} />
            </>
        );
    } else if (ndcDataFailed || activityDataFailed || rulesDataFailed) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                <FormattedMessage id="ndc.error.document" />
                <ImpactHelmet documentType={DocumentType.NDC} title={formatMessage({ id: 'title.error' })} />
            </Alert>
        );
    } else if (
        (variant === NdcPageVariant.CREATE && activityData && rulesData) ||
        ((variant === NdcPageVariant.READ || variant === NdcPageVariant.CLONE) &&
            noteDeCoupureData &&
            activityData &&
            rulesData)
    ) {
        return (
            <NoteDeCoupureForm
                data={
                    variant === NdcPageVariant.CREATE
                        ? {
                              variant,
                              activityData,
                          }
                        : {
                              variant,
                              noteDeCoupureData: noteDeCoupureData!, // Cast
                              activityData,
                              idOrNdcNumber: pathParams.id,
                          }
                }
                rules={rulesData}
                refreshNoteDeCoupureData={refreshNoteDeCoupureData}
            />
        );
    } else {
        return <Fragment />;
    }
};

export const NoteDeCoupurePage = withUrlParams(NoteDeCoupureContent, schema);
