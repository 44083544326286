import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { FileTree } from 'mdi-material-ui';
import React, { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TreeStructure, TreeStructureProps } from './TreeStructure';

interface TreeWidgetProps<TNode> {
    TreeProps: TreeStructureProps<TNode>;
    title?: string;
    disabled?: boolean;
    readOnly?: boolean;
}
export const TreeWidget = <TNode extends object>({
    TreeProps,
    title = 'Tree',
    disabled,
    readOnly,
}: TreeWidgetProps<TNode>): ReactElement | null => {
    const [open, setOpen] = useState(false);
    const onClose = () => {
        setOpen(false);
    };

    return !readOnly ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={title} disableHoverListener={disabled}>
                <span>
                    <IconButton onClick={() => setOpen(true)} size="small" disabled={disabled} sx={{ my: -1, ml: 0.5 }}>
                        <FileTree />
                    </IconButton>
                </span>
            </Tooltip>

            <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    {title}
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <TreeStructure {...TreeProps} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        <FormattedMessage id="common.confirm" />
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    ) : null;
};
