import React, { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import z from 'zod';
import { NotFoundPage } from '../../pages/NotFoundPage';

interface UrlParamsParameters {
    pathParams: {};
    queryParams: {};
}

export const withUrlParams =
    <Params extends UrlParamsParameters>(
        Component: React.FC<Params>,
        schema: z.ZodType<Params>
    ): React.FC<Omit<Params, 'pathParams' | 'queryParams'>> =>
    (props) => {
        const pathParams = useParams();
        const [queryParams] = useSearchParams();
        const value = useMemo<Params | null>(() => {
            const input = {
                ...props,
                pathParams: pathParams,
                queryParams: Object.fromEntries(queryParams.entries()),
            } satisfies UrlParamsParameters;
            const parsed = schema.safeParse(input);
            if (parsed.success) {
                return parsed.data;
            } else {
                return null;
            }
        }, [schema, props, pathParams, queryParams]);
        if (value !== null) {
            return <Component {...value} />;
        } else {
            return <NotFoundPage />; // Redirection to 404 is only a temporary hack, this is instead far nicer
        }
    };

export const defaultUrlParamsSchema = z.object({
    pathParams: z.object({}),
    queryParams: z.object({}),
});
