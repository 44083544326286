import {
    Autocomplete,
    AutocompleteProps,
    Checkbox,
    ChipProps,
    CircularProgress,
    Stack,
    TextField,
    TextFieldProps,
} from '@mui/material';
import React, { Fragment, useMemo, useState } from 'react';
import { ControllerFieldState, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { AdornableElement } from '../types';

export type AutocompleteElementProps<
    T,
    M extends boolean | undefined,
    D extends boolean | undefined
> = AdornableElement & {
    strongValidateOptions?: boolean;
    field: ControllerRenderProps<FieldValues, string>;
    fieldState: ControllerFieldState;
    matchId?: boolean;
    idField?: string;
    labelField?: string;
    multiple?: M;
    loading?: boolean;
    showCheckbox?: boolean;
    required?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    adornmentOutside?: boolean;
    label?: TextFieldProps['label'];
    options: T[];
    getOptionLabel?: ((option: T, intl: IntlShape) => string) | undefined;
    renderTagChip?: (props: ChipProps, option: any) => React.ReactNode;
    textFieldProps?: Omit<TextFieldProps, 'name' | 'required' | 'label'>;
    autocompleteProps?: Omit<
        AutocompleteProps<T, M, D, any>,
        'name' | 'options' | 'loading' | 'renderInput' | 'disabled' | 'getOptionLabel'
    >;
};

export interface AutocompleteDefaultOption {
    id: string | number;
    label: string;
}

export const AutocompleteElement = <T, M extends boolean | undefined, D extends boolean | undefined>(
    props: AutocompleteElementProps<T | AutocompleteDefaultOption | string | any, M, D>
): React.ReactElement => {
    const {
        strongValidateOptions,
        field,
        fieldState,
        matchId,
        idField = 'id',
        labelField = 'label',
        multiple,
        options,
        loading,
        showCheckbox,
        required,
        disabled,
        readOnly,
        adornmentOutside,
        label,
        getOptionLabel: getOptionLabelCustom,
        renderTagChip,
        textFieldProps,
        autocompleteProps,
        endAdornment = () => null,
    } = props;
    const { value, onChange, onBlur, name, ref, ...fieldRest } = field;
    const { error } = fieldState;
    const intl = useIntl();
    const isOptionEqualToValue: ((option: any, value: any) => boolean) | undefined = useMemo(
        () =>
            autocompleteProps?.isOptionEqualToValue
                ? autocompleteProps.isOptionEqualToValue
                : (o, v) => (v ? o[idField] === ((v && v[idField]) ?? v) : false),
        [autocompleteProps?.isOptionEqualToValue, idField]
    );

    const [selectedOptions, setSelectedOptions] = useState<any[]>(value ? (multiple ? value : [value]) : []); // used for storing previously selected options when multiple (useful for async options)
    const currentOptions = useMemo(() => {
        const newSelectedOptions = strongValidateOptions
            ? []
            : selectedOptions.filter(
                  (selectedOption) => !options.some((option) => isOptionEqualToValue(option, selectedOption))
              );
        const existingSelectedOptions = options.filter((option) =>
            selectedOptions.some((selectedOption) => isOptionEqualToValue(option, selectedOption))
        );
        const nonSelectedOptions = options.filter(
            (option) => !selectedOptions.some((selectedOption) => isOptionEqualToValue(option, selectedOption))
        );
        return [...newSelectedOptions, ...existingSelectedOptions, ...nonSelectedOptions];
    }, [strongValidateOptions, options, selectedOptions]);
    const currentValue = useMemo<string | any>(() => {
        let v = strongValidateOptions
            ? multiple
                ? value?.filter((val: any) =>
                      currentOptions.find((j) => (j?.[idField] ?? j) === (val?.[idField] ?? val))
                  ) || []
                : value || null
            : multiple
            ? value || []
            : value || null;

        if (matchId) {
            v = multiple
                ? (value || []).map((i: any) => currentOptions.find((j) => ((j && j[idField]) ?? j) === i))
                : currentOptions.find((i) => ((i && i[idField]) ?? i) === value) || null;
        }
        return v;
    }, [multiple, value, matchId, currentOptions, strongValidateOptions]);

    const adornment = endAdornment({ field, disabled, readOnly });

    const getOptionLabel = useMemo(
        () =>
            getOptionLabelCustom
                ? (s: string) => getOptionLabelCustom(s, intl)
                : (option: any) =>
                      `${
                          option?.[labelField] ||
                          currentOptions.find((opt) => (matchId ? opt : opt?.[idField]) === option?.[idField])?.[
                              labelField
                          ] ||
                          option
                      }`,
        [getOptionLabelCustom, intl, labelField, currentOptions, matchId, idField]
    );

    return (
        <Stack direction="row" spacing={1}>
            <Autocomplete
                {...autocompleteProps}
                sx={{
                    ...autocompleteProps?.sx,
                    // Fix for the issue described here: https://github.com/mui/material-ui/issues/28465
                    '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                        paddingRight: '39px',
                    },
                    // Always show the "clear" button
                    '& .MuiAutocomplete-clearIndicator': {
                        visibility: !readOnly ? 'visible' : 'hidden',
                    },
                }}
                fullWidth
                value={currentValue}
                loading={loading}
                multiple={multiple}
                options={currentOptions}
                disabled={disabled || readOnly}
                disableCloseOnSelect={
                    typeof autocompleteProps?.disableCloseOnSelect === 'boolean'
                        ? autocompleteProps.disableCloseOnSelect
                        : !!multiple
                }
                isOptionEqualToValue={isOptionEqualToValue}
                getOptionLabel={getOptionLabel}
                onChange={(event, newValue, reason, details) => {
                    let changedVal = newValue;
                    if (matchId) {
                        changedVal = Array.isArray(newValue)
                            ? newValue.map((i: any) => (i && i[idField]) ?? i)
                            : (newValue && newValue[idField]) ?? newValue;
                    }
                    if (reason === 'selectOption') {
                        setSelectedOptions((previous) => {
                            if (multiple) {
                                const filteredSelectedOptions = previous.filter(
                                    (o) => !isOptionEqualToValue(o, details?.option)
                                );
                                const isDuplicateOption = previous
                                    .map((e) => (e && e[idField]) ?? e)
                                    .includes(details?.option && details?.option[idField ?? details?.option]);
                                return isDuplicateOption
                                    ? filteredSelectedOptions
                                    : [...filteredSelectedOptions, details?.option];
                            } else {
                                return [details?.option];
                            }
                        });
                    }
                    if (reason === 'removeOption' && newValue.length >= 1) {
                        setSelectedOptions((previous) =>
                            previous.filter((o) => !isOptionEqualToValue(o, details?.option))
                        );
                    }
                    if (reason === 'clear' || (reason === 'removeOption' && !newValue.length)) {
                        setSelectedOptions([]);
                    }
                    onChange(changedVal);
                    if (autocompleteProps?.onChange) {
                        autocompleteProps.onChange(event, newValue, reason, details);
                    }
                }}
                renderOption={
                    autocompleteProps?.renderOption ??
                    ((
                        optionProps,
                        option,
                        { selected } // Why not call `getOptionLabel`?
                    ) => (
                        <li {...optionProps} key={option[idField]}>
                            {showCheckbox && <Checkbox sx={{ marginRight: 1 }} checked={selected} />}
                            {getOptionLabelCustom?.(option, intl) || option?.[labelField] || option}
                        </li>
                    ))
                }
                onBlur={(event) => {
                    onBlur();
                    if (typeof autocompleteProps?.onBlur === 'function') {
                        autocompleteProps.onBlur(event);
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        inputRef={ref}
                        ref={null}
                        name={name}
                        required={!!required}
                        label={label}
                        {...params}
                        {...textFieldProps}
                        error={!!error || textFieldProps?.error}
                        InputProps={{
                            ...params.InputProps,
                            ...textFieldProps?.InputProps,
                            endAdornment: (
                                <>
                                    <Stack
                                        direction="row"
                                        sx={{
                                            mb: 0.3,
                                            // Not great, but works
                                            mr: !readOnly && !!currentValue && currentValue?.length !== 0 ? 0 : -4,
                                        }}
                                    >
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {adornmentOutside ? null : adornment}
                                    </Stack>
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            ...params.inputProps,
                            ...textFieldProps?.inputProps,
                        }}
                        helperText={error ? error.message : textFieldProps?.helperText}
                    />
                )}
                renderTags={
                    renderTagChip !== undefined
                        ? (optionValues, getTagProps) =>
                              // See https://github.com/mui/material-ui/blob/ba35dfcfa205fdb73d90d8d86a539b83e22f0139/packages/mui-material/src/Autocomplete/Autocomplete.js#L601
                              optionValues.map((optionValue, index) => {
                                  const { key, ...customTagProps } = getTagProps({ index });
                                  const chip = renderTagChip(
                                      { label: getOptionLabel(optionValue), size: 'small', ...customTagProps },
                                      optionValue
                                  );
                                  return <Fragment key={key}>{chip}</Fragment>;
                              })
                        : undefined
                }
                {...fieldRest}
            />
            {adornmentOutside ? adornment : null}
        </Stack>
    );
};
