import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { CommentDTO, CommentUpdateDTO, PersonReferenceDTO, TaskAddAssigneesDTO, TaskDTO, TaskTypeDTO } from './dto';
import { ActionOne, CreateOne, GetManyPaged, GetManyUnpaged } from './types';

export type TaskIdentifier = { taskId: string; comment?: CommentUpdateDTO };

const TASKS_PATH = 'tasks';

export const getTasks: GetManyPaged<TaskDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([TASKS_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const addTaskAssignees: ActionOne<TaskIdentifier & { newAssignees: PersonReferenceDTO[] }, void> = async ({
    taskId,
    newAssignees,
    comment,
    config,
}) => {
    const body: TaskAddAssigneesDTO = { newAssignees, comment: comment ?? { comment: null } };
    return client
        .post(buildPath([TASKS_PATH, taskId, 'add']), body, config)
        .then((response: AxiosResponse) => response.data);
};

export const getTaskTypes: GetManyUnpaged<{}, TaskTypeDTO> = async ({ config } = {}) =>
    client.get(buildPath([TASKS_PATH, 'types']), config).then((response: AxiosResponse) => response.data);

export const getDocumentComments: GetManyUnpaged<{ documentTypes: string[]; documentId: number }, CommentDTO> = async ({
    documentTypes,
    documentId,
    config,
}) =>
    client
        .get(buildPath([TASKS_PATH, 'comments']), { ...config, params: { documentTypes, documentId } })
        .then((response: AxiosResponse) => response.data);

export const addComment: CreateOne<{ data: CommentUpdateDTO; taskId: string }, CommentDTO> = async ({
    data,
    taskId,
    config,
}) =>
    client
        .post(buildPath([TASKS_PATH, taskId, 'add-comment']), data, config)
        .then((response: AxiosResponse) => response.data);
