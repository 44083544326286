/* eslint-disable @typescript-eslint/no-use-before-define */
import { Info, Warning } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import React, { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { createVic, getVicPdf, submitVic, updateVic, vicAttachmentsApi } from '../../api/documents/vics';
import {
    DocumentActionDTO,
    DocumentType,
    ListOfValuesReadDTO,
    VicContextReadDTO,
    VicGridReadDTO,
    VicReadDTO,
} from '../../api/dto';
import { useApplicationSettings } from '../../components/application/ApplicationSettingsProvider';
import { CommentList } from '../../components/comments/CommentList';
import { useConfirmationDialog } from '../../components/ConfirmationDialogContext';
import { DefaultAccordion } from '../../components/DefaultAccordion';
import { COMMON_DOCUMENT_BUTTONS, DocumentButtonsStack } from '../../components/documents/DocumentButtonsStack';
import { DocumentPageStructure } from '../../components/documents/DocumentPageStructure';
import { LinkedDocumentsPanel } from '../../components/documents/LinkedDocumentsPanel';
import { LocalizedDocumentPdfButton } from '../../components/documents/LocalizedDocumentPdfButton';
import { VicInformationPanel } from '../../components/documents/VicInformationPanel';
import { VicWarningPanel } from '../../components/documents/VicWarningPanel';
import { InactiveActivityWarning } from '../../components/InactiveActivityWarning';
import { useTaskContext } from '../../components/TaskContext';
import { TaskActions } from '../../components/tasks/TaskActions';
import { TaskHistory } from '../../components/tasks/TaskHistory';
import { useTasks } from '../../components/tasks/useTasks';
import { ValidationErrorsPanel } from '../../components/ValidationErrorsPanel';
import { WorkflowHistory } from '../../components/WorkflowHistory';
import { useDocumentCreationToast } from '../../hooks/useDocumentCreationToast';
import { useUnsavedChangesPrompt } from '../../hooks/useUnsavedChangesPrompt';
import { useDocumentSaveErrorCallback } from '../../hooks/validation';
import { artificialDelay } from '../../lib/delay';
import { EntityType } from '../../lib/information/types/EntityType';
import {
    makeVicDefaultValuesFromClonedVic,
    makeVicDefaultValuesFromLinkedActivity,
    mapVicToUpdateDTO,
} from '../../lib/records/documents/vic';
import { AttachmentsAction } from '../../pages/documents/ndc/AttachmentsAction';
import { VicContentHeader } from '../../pages/documents/vic/VicContentHeader';
import { VicPageData } from '../../pages/documents/vic/VicPageData';
import { VicPageVariant } from '../../pages/documents/vic/VicPageVariant';
import { getRoute, ROUTES } from '../../routes/routes';
import { FieldsAttributesContextProvider } from '../FieldsAttributesContext';
import { FormGenerator } from '../FormGenerator';
import { PreciseRulesDTO } from '../rule/deserializer';
import { useFormRules } from '../rule/useFormRules';
import { useIsSavableFromRules } from '../rule/useIsSavableFromRules';
import { vicFormSchema } from '../schemas/documents/vic';

interface VicFormProps {
    data: VicPageData;
    rules: PreciseRulesDTO<VicContextReadDTO>;
    refreshData: () => void;
    riskTypes: ListOfValuesReadDTO[];
}

export const VicForm: React.FC<VicFormProps> = ({ data: pageData, rules, refreshData, riskTypes }) => {
    const { withDispatchTaskEvent } = useTaskContext();
    const intl = useIntl();
    const { variant } = pageData;
    const navigate = useNavigate();
    const { currentUser } = useApplicationSettings();

    const defaultValues = useMemo(
        () =>
            pageData.variant === VicPageVariant.READ
                ? pageData.vicData
                : pageData.variant === VicPageVariant.CLONE
                ? makeVicDefaultValuesFromClonedVic(pageData.vicData)
                : makeVicDefaultValuesFromLinkedActivity(pageData.activityData, riskTypes),
        [pageData]
    );

    const tasks = useTasks(
        pageData.variant === VicPageVariant.READ
            ? {
                  documentDetails: [{ documentType: DocumentType.VIC, documentId: pageData.vicData.id }],

                  onTaskSubmit: (taskHandler) => {
                      clearErrors();
                      return handleSubmit((data) => taskHandler(data))();
                  },
                  onTaskError: (response: AxiosResponse) =>
                      handleFormSaveError(response, { validate: true, submit: false }),
                  refreshData,
              }
            : undefined
    );
    const context: VicContextReadDTO | null = useMemo(
        () =>
            rules.context !== null
                ? {
                      ...rules.context,
                      documentAction: variant === VicPageVariant.READ ? null : DocumentActionDTO.CREATE,
                      task: tasks.currentTask?.type?.type ?? null,
                  }
                : null,
        [rules.context, tasks.currentTask]
    );
    const { form, attributes } = useFormRules<VicReadDTO>({
        formProps: {
            mode: 'onSubmit',
            defaultValues: {
                ...defaultValues,
            },
        },
        rules,
        originalValues: defaultValues,
        context: context as any,
    });
    const { handleSubmit, clearErrors, formState } = form;

    const confirmation = useConfirmationDialog();
    const handleFormSaveError = useDocumentSaveErrorCallback(form, DocumentType.VIC);

    const sendDocumentCreationToast = useDocumentCreationToast('vic.name');

    const { mutate: saveVic, isLoading: isSaveLoading } = useMutation(
        ({ data: formData, submit = false }: { data: any; submit?: boolean }) => {
            if (variant === VicPageVariant.READ) {
                const updateOrSubmit = submit ? submitVic : updateVic;
                if (tasks.currentTask === null) {
                    throw new Error();
                }
                return updateOrSubmit({
                    id: pageData.vicData.id,
                    taskId: tasks.currentTask?.id,
                    data: mapVicToUpdateDTO(formData, false),
                });
            } else {
                if (submit) {
                    throw new Error(); // Must be created first
                }
                return createVic({ data: mapVicToUpdateDTO(formData, true) }).then(artificialDelay);
            }
        },
        {
            onSuccess: (result) => {
                if (variant === VicPageVariant.READ) {
                    refreshData();
                } else {
                    sendDocumentCreationToast();
                    navigate(
                        getRoute({
                            path: ROUTES.vic.view,
                            params: { id: String(result.id) },
                        }),
                        {
                            replace: true,
                        }
                    );
                }
            },
            onError: handleFormSaveError,
        }
    );
    const { isDirty: formIsDirty } = formState;
    const isLoading = isSaveLoading || tasks.disabled;
    const withUnsavedChangesPrompt = useUnsavedChangesPrompt({
        when: !isLoading && formIsDirty,
    });
    const refreshDataWithUnsavedChangesPrompt = () => withUnsavedChangesPrompt(refreshData);

    const isCompletable = tasks.isLoading || !!tasks.assignableTasks.length;
    const isSavable = useIsSavableFromRules(attributes);
    const isDirty = formIsDirty || variant !== VicPageVariant.READ;
    const isV1 = context?.isV1 ?? false;
    const uiSchemaVic = useMemo(() => vicFormSchema(intl, isSavable, isV1), [intl, isSavable]);

    return (
        <FormProvider {...form}>
            <DocumentPageStructure
                isLoading={isLoading}
                Header={
                    <VicContentHeader
                        data={pageData}
                        editable={isSavable || isCompletable}
                        isLoading={isLoading}
                        onRefreshClick={
                            variant === VicPageVariant.READ ? refreshDataWithUnsavedChangesPrompt : undefined
                        }
                        Main={null}
                        Left={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {tasks.currentTask !== null && (
                                    <TaskActions
                                        task={tasks.currentTask}
                                        onValidate={tasks.validate}
                                        onSubmit={tasks.perform}
                                        disabled={isLoading}
                                        getValues={form.getValues}
                                        context={context}
                                        isDirty={formIsDirty}
                                    />
                                )}
                                {isSavable && (
                                    <DocumentButtonsStack.Action
                                        {...(variant === VicPageVariant.READ
                                            ? COMMON_DOCUMENT_BUTTONS.SAVE
                                            : COMMON_DOCUMENT_BUTTONS.CREATE)}
                                        onClick={withDispatchTaskEvent(() => {
                                            clearErrors();
                                            return handleSubmit((data) => saveVic({ data }))();
                                        })}
                                        disabled={!isDirty}
                                        disablingReason={intl.formatMessage({
                                            id: 'document.form.disabledNoChange',
                                        })}
                                        important={!tasks.currentTask}
                                    />
                                )}
                                {pageData.variant === VicPageVariant.CREATE && pageData.activityData !== null ? (
                                    <>{!pageData.activityData.isActive && <InactiveActivityWarning />}</>
                                ) : null}
                            </Stack>
                        }
                        Right={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {variant === VicPageVariant.READ && pageData.vicData.availableActions.canClone && (
                                    <DocumentButtonsStack.Action
                                        {...COMMON_DOCUMENT_BUTTONS.CLONE}
                                        onClick={() => {
                                            window.open(
                                                getRoute({
                                                    path: ROUTES.vic.clone,
                                                    params: { id: String(pageData.vicData.id) },
                                                })
                                            );
                                        }}
                                        disabled={formIsDirty}
                                        disablingReason={intl.formatMessage({
                                            id: 'document.form.disabledUnsaved',
                                        })}
                                    />
                                )}
                                {variant === VicPageVariant.READ && (
                                    <AttachmentsAction
                                        attachments={pageData.vicData.attachments}
                                        linkAttachments={(attachments) =>
                                            vicAttachmentsApi.linkAttachments({
                                                id: pageData.vicData.id,
                                                taskId: tasks.currentTask?.id!,
                                                ...attachments,
                                            })
                                        }
                                        unlinkAttachments={(attachments) =>
                                            vicAttachmentsApi.unlinkAttachments({
                                                id: pageData.vicData.id,
                                                taskId: tasks.currentTask?.id!,
                                                ...attachments,
                                            })
                                        }
                                        updateAttachment={(data) =>
                                            vicAttachmentsApi.updateAttachment({
                                                id: pageData.vicData.id,
                                                taskId: tasks.currentTask?.id!,
                                                ...data,
                                            })
                                        }
                                        readOnly={!isSavable}
                                    />
                                )}
                                {pageData.variant === VicPageVariant.READ && !!pageData.vicData.pdf && (
                                    <LocalizedDocumentPdfButton
                                        queryKey={['vic', pageData.vicData.id]}
                                        fetchPdf={(language) =>
                                            getVicPdf({
                                                id: pageData.vicData.id,
                                                langCode: language,
                                            })
                                        }
                                        isPdfPreview={pageData.vicData.pdf.isPdfPreview}
                                        canGenerateInBothLanguages={true}
                                        dirty={formIsDirty}
                                    />
                                )}
                            </Stack>
                        }
                    />
                }
                Body={
                    <>
                        <LinkedDocumentsPanel
                            type={EntityType.Vic}
                            vic={
                                variant === VicPageVariant.READ
                                    ? pageData.vicData
                                    : pageData.variant === VicPageVariant.CREATE
                                    ? { activities: [pageData.activityData], clonedFrom: null }
                                    : {
                                          activities: pageData.vicData.activities,
                                          clonedFrom: pageData.vicData as VicGridReadDTO,
                                      }
                            }
                        />
                        <ValidationErrorsPanel schema={uiSchemaVic} />
                        <FieldsAttributesContextProvider value={attributes}>
                            <FormGenerator rootElement={uiSchemaVic} form={form} disabled={isLoading} />
                            {variant === VicPageVariant.READ && (
                                <>
                                    <WorkflowHistory documentType={DocumentType.VIC} documentId={pageData.vicData.id} />
                                    <CommentList
                                        documentTypes={[DocumentType.VIC]}
                                        documentId={pageData.vicData.id}
                                        currentTask={tasks.currentTask}
                                        disabled={isLoading}
                                    />
                                </>
                            )}
                        </FieldsAttributesContextProvider>
                        <Box sx={{ mt: 2 }}>
                            <DefaultAccordion
                                title={intl.formatMessage({ id: 'vic.safety.title' })}
                                icon={<Info color="action" />}
                            >
                                <VicInformationPanel />
                            </DefaultAccordion>
                        </Box>{' '}
                        <Box sx={{ mt: 2 }}>
                            <DefaultAccordion
                                title={intl.formatMessage({ id: 'vic.panel.warning' })}
                                icon={<Warning color="action" />}
                            >
                                <VicWarningPanel />
                            </DefaultAccordion>
                        </Box>
                    </>
                }
                Drawer={
                    variant === VicPageVariant.READ ? (
                        <TaskHistory
                            tasks={tasks}
                            disabled={isLoading}
                            withUnsavedChangesPrompt={withUnsavedChangesPrompt}
                            onTaskChange={() => form.reset()}
                            onTaskUpdate={refreshData}
                        />
                    ) : null
                }
            />
        </FormProvider>
    );
};
