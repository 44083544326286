import deepEqual from 'fast-deep-equal';
import {
    ComputedVariableTypeDTO,
    FieldAttributesDTO,
    NestedRulesDTO,
    OptionalDTO,
    RulesDTO,
    VariableDTO,
    VariableTypeDTO,
} from '../../api/dto';
import { joinFieldPath, splitFieldPath } from '../utils';

export type DTO = Record<string, any>;

export type PreciseVariableDTO<S extends DTO, T = any> =
    | ({ operands: null } & (
          | { type: VariableTypeDTO.FIELD_FORM; value: string }
          | { type: VariableTypeDTO.CONTEXT; value: null }
          | { type: VariableTypeDTO.ORIGINAL_FORM; value: null }
          | { type: VariableTypeDTO.CONSTANT; value: T }
          | { type: VariableTypeDTO.NESTED_SINGLETON; value: [NestedRulesDTO<S>] }
          | { type: VariableTypeDTO.NESTED_COLLECTION; value: [NestedRulesDTO<S>, string | null] }
          | { type: VariableTypeDTO.INDEX; value: null }
      ))
    | { type: VariableTypeDTO.RUNTIME; value: null; operands: [number, ...number[]] }
    | { type: VariableTypeDTO.ATTRIBUTE_OBJECT; value: string; operands: [number] }
    | { type: VariableTypeDTO.ATTRIBUTE_ARRAY; value: string; operands: [number] }
    | { type: VariableTypeDTO.ARRAY_ACCESS; value: null; operands: [number, number] }
    | { type: VariableTypeDTO.OPTIONAL; value: boolean; operands: [number] }
    | { type: VariableTypeDTO.COMPUTED; value: ComputedVariableTypeDTO; operands: number[] };

export interface AssignedVariable<S extends DTO, T = any> {
    variable: PreciseVariableDTO<S, T>;
    value: T;
}

export type PreciseRulesDTO<S extends DTO> = Omit<RulesDTO<S>, 'variables'> & {
    variables: PreciseVariableDTO<S>[];
};

const OPERATORS: Record<ComputedVariableTypeDTO, (...args: any[]) => any> = {
    IDENTITY: (v: any): any => v,
    EQUAL: (a: any, b: any): boolean => a === b || (a == null && a == b),
    NOT: (a: boolean): boolean => !a,
    AND: (...as: boolean[]): boolean => as.reduce((a, b) => a && b, true),
    OR: (...as: boolean[]): boolean => as.reduce((a, b) => a || b, false),
    CONCAT: (...as: any[][]): any[] => as.flat(),
    ONE_OF: <T>(needle: T, hay: T[]): boolean => {
        return hay ? hay.includes(needle) : false;
    },
    CONDITIONAL: <T>(c: boolean, t: T, f: T): T => (c ? t : f),
    DATE_GREATER_THAN: (left: string | null, right: string | null): boolean =>
        left != null && right != null ? new Date(left).getTime() > new Date(right).getTime() : false,
    DATE_ADD: (date: string | null, value: number | null, unit: string): string | null =>
        date != null && value != null
            ? (() => {
                  const newDate = new Date(date);
                  switch (unit) {
                      case 'DAYS':
                          newDate.setDate(newDate.getDate() + value);
                          break;
                      case 'HOURS':
                          newDate.setHours(newDate.getHours() + value);
                          break;
                      default:
                          throw new Error(unit); // Unrecognized/unsupported unit
                  }
                  return newDate.toISOString();
              })()
            : null,
    NUMBER_GREATER_THAN: (a: number | null, b: number | null): boolean | null =>
        a != null && b != null ? a > b : null,
    NUMBER_GREATER_THAN_EQUAL: (a: number | null, b: number | null): boolean | null =>
        a != null && b != null ? a >= b : null,
    STRING_LENGTH: (value: string | null): number => value?.length ?? 0,
    COLLECTION_LENGTH: (value: unknown[] | null): number => value?.length ?? 0,
    IS_DISTINCT: (value: unknown[] | null): boolean =>
        value == null || new Set(value.map((v) => JSON.stringify(v))).size === value.length,
};

const evaluate = <D extends DTO, S extends DTO>(
    variableIndex: number,
    variables: AssignedVariable<S>[],
    attributes: Record<string, FieldAttributesDTO>,
    originalForm: D,
    form: D,
    context: S,
    index: number | null
): any => {
    const variable = variables[variableIndex].variable satisfies VariableDTO;
    switch (variable.type) {
        case VariableTypeDTO.FIELD_FORM: {
            const attribute = attributes[variable.value];
            const editable: boolean = variables[attribute.editable].value;
            const mandatory: boolean = variables[attribute.mandatoryIfEditable].value;
            const overwriteValue: OptionalDTO<unknown> = variables[attribute.overwriteIfNotEditable].value;
            const originalValue = originalForm ? originalForm[variable.value] : null;
            const updatedValue = form ? form[variable.value] : null;
            let newValue;
            if (editable) {
                if (mandatory && form == null) {
                    newValue = updatedValue; // TODO
                } else {
                    newValue = updatedValue;
                }
            } else {
                if (overwriteValue.present) {
                    newValue = overwriteValue.value;
                } else {
                    newValue = originalValue;
                }
            }
            return newValue;
        }
        case VariableTypeDTO.NESTED_SINGLETON: {
            if (form == null) {
                return null;
            }
            const { rules: rulesWeak, field } = variable.value[0];
            const rules = rulesWeak as PreciseRulesDTO<S>;
            const originalValue = originalForm[field],
                updatedValue = form[field];
            // (mutual recursion)
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            const nestedAssignment = computeAttributes(rules, null, originalValue, updatedValue, context, null);
            return {
                rules,
                multiple: false,
                value: nestedAssignment,
            } satisfies ValueNested<S>;
        }
        case VariableTypeDTO.NESTED_COLLECTION:
            if (form == null) {
                return null;
            }
            const [{ rules: rulesWeak, field }, idField] = variable.value;
            const rules = rulesWeak as PreciseRulesDTO<S>;
            const updatedValue = form[field];
            const previousItemsById: Record<string | number, any> = {};
            ((originalForm ? (originalForm[field] as any[] | null | undefined) : null) ?? []).forEach((item) => {
                if (idField !== null && item) {
                    const key = item[idField];
                    if (key && (typeof key === 'number' || typeof key === 'string')) {
                        previousItemsById[key] = item;
                    }
                }
            });
            const nestedAssignments = ((updatedValue as any[] | null | undefined) ?? []).map((item, i) => {
                let originalItem = null;
                if (idField !== null && item) {
                    const key = item[idField];
                    if (key && (typeof key === 'number' || typeof key === 'string')) {
                        const value = previousItemsById[key];
                        if (value !== undefined) {
                            originalItem = value;
                        }
                    }
                }
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                return computeAttributes(rules, null, originalItem, item, context, i);
            });
            return {
                rules,
                multiple: true,
                value: nestedAssignments,
            } satisfies ValueNested<S>;
        case VariableTypeDTO.INDEX:
            return index;
        case VariableTypeDTO.RUNTIME:
            return variables[variable.operands[0]].value;
        case VariableTypeDTO.ARRAY_ACCESS:
            const array: any[] | null = variables[variable.operands[0]].value;
            const idx: number | null = variables[variable.operands[1]].value;
            return array != null && idx != null ? array[idx] : null;
        case VariableTypeDTO.CONTEXT:
            return context;
        case VariableTypeDTO.ORIGINAL_FORM:
            return originalForm;
        case VariableTypeDTO.ATTRIBUTE_OBJECT: {
            const { value } = variables[variable.operands[0]];
            return value != null ? value[variable.value] : value;
        }
        case VariableTypeDTO.ATTRIBUTE_ARRAY: {
            const { value } = variables[variable.operands[0]];
            return value != null ? value.map((o: any) => (o != null ? o[variable.value] : o)) : value;
        }
        case VariableTypeDTO.OPTIONAL: {
            const { value } = variables[variable.operands[0]];
            const nullable = variable.value;
            return (
                !nullable || value != null ? { present: true, value } : { present: false, value: null }
            ) satisfies OptionalDTO<unknown>; // TODO handle optionals properly
        }
        case VariableTypeDTO.CONSTANT:
            return variable.value;
        case VariableTypeDTO.COMPUTED:
            return OPERATORS[variable.value](...variable.operands.map((operand) => variables[operand].value));
    }
    const ignore: { type: never } = variable; // Do not remove, this is for the type-checker
};

type ValueNested<S extends DTO> = {
    rules: PreciseRulesDTO<S>;
} & (
    | {
          multiple: false;
          value: AssignedVariable<S>[];
      }
    | {
          multiple: true;
          value: AssignedVariable<S>[][];
      }
);

export interface FieldAttributesBase {
    editable: boolean;
    visible: boolean;
    mandatory: boolean;
}

export interface FieldAttributes extends FieldAttributesBase {
    newValue: any;
}

const computeAttributes = <D extends DTO, S extends DTO>(
    rules: PreciseRulesDTO<S>,
    invalidations: number[] | null,
    originalValues: D,
    newValues: D,
    context: S,
    index: number | null
): AssignedVariable<S>[] => {
    // TODO naive, for now we recompute everything (we should use the information in `rule` for better performance)
    // TODO support nested changes detection
    const assignments: AssignedVariable<S>[] = rules.variables.map((variable) => ({ variable, value: null }));
    for (let i = 0; i < rules.variables.length; i++) {
        assignments[i].value = evaluate(i, assignments, rules.attributes, originalValues, newValues, context, index);
    }
    if ('activity' in rules.attributes) {
        // logVariableAttributes('estCollectiveDose', rules, assignments);
    }
    return assignments;
};

export const rulesValidator = <D extends DTO, S extends DTO>(
    originalValues: D,
    data: D,
    rules: PreciseRulesDTO<S>,
    context: S,
    setValueExternal: (name: string, value: any) => void,
    ignoredFields: Set<string>
): Record<string, FieldAttributes> => {
    const setValueInternal = (path: string, value: any): void => {
        if (ignoredFields.has(path)) {
            return;
        }
        let o: Record<string, any> = data;
        const parts = splitFieldPath(path);
        parts.forEach((part, i) => {
            const isLast = i === parts.length - 1;
            if (isLast) {
                o[part] = value;
            } else {
                const next = typeof o[part] !== 'object' ? {} : o[part];
                o[part] = next;
                o = next;
            }
        });
    };
    const setValue = (name: string, value: any) => {
        setValueInternal(name, value);
        setValueExternal(name, value);
    };
    const resolveAttributes = (
        attributes: FieldAttributesDTO,
        localAssignments: AssignedVariable<S>[]
    ): FieldAttributes => ({
        newValue: localAssignments[attributes.newValue].value,
        editable: localAssignments[attributes.editable].value,
        visible: localAssignments[attributes.visibleIfNotEditable].value,
        mandatory: localAssignments[attributes.mandatoryIfEditable].value,
    });
    const resolveAll = (
        allRules: PreciseRulesDTO<S>,
        localAssignments: AssignedVariable<S>[]
    ): [string, FieldAttributes][] =>
        Object.entries(allRules.attributes).flatMap((entry) => {
            const [fieldName, attributes] = entry;
            let nestedEntries: [string, FieldAttributes][] = [];
            if (attributes.rules !== null) {
                // Nesting
                const nested: ValueNested<S> = localAssignments[attributes.rules].value;
                if (!nested.multiple) {
                    nestedEntries = resolveAll(nested.rules, nested.value).map(([entryFieldName, entryAttributes]) => [
                        `${fieldName}.${entryFieldName}`,
                        entryAttributes,
                    ]);
                } else {
                    nestedEntries = nested.value.flatMap(
                        (e, index) =>
                            resolveAll(nested.rules, e).map(([entryFieldName, entryAttributes]) => [
                                `${fieldName}.${index}.${entryFieldName}`,
                                entryAttributes,
                            ]) satisfies [string, FieldAttributes][]
                    );
                }
            }
            const resolvedEntry: [string, FieldAttributes] = [
                fieldName,
                resolveAttributes(attributes, localAssignments),
            ];
            return [resolvedEntry, ...nestedEntries];
        });
    // TODO use `name`
    const assignments: AssignedVariable<S>[] = computeAttributes(rules, null, originalValues, data, context, null);
    const resolved = resolveAll(rules, assignments).sort(([a], [b]) => a.length - b.length);
    const readonlyParents = new Set<string>();
    const resolvedFixedEditability: typeof resolved = [];
    // This is a temporary workaround until we work on the validation "v2"; the problem is that subfields do not inherit the editability from their parent
    resolved.forEach(([fieldName, fieldAttributes]) => {
        const fieldPath = splitFieldPath(fieldName);
        const readonlyParent: boolean = [...Array(fieldPath.length - 1).keys()].some((i) =>
            readonlyParents.has(joinFieldPath(fieldPath.slice(0, i + 1)))
        );
        const oldValue = fieldPath.reduce((o, key) => (o != null ? o[key] : undefined), data);
        const newValue = fieldAttributes.newValue;
        if (
            !readonlyParent &&
            !deepEqual(newValue, oldValue) &&
            !(
                typeof newValue === 'object' &&
                typeof oldValue === 'object' &&
                deepEqual({ ...oldValue, ...newValue }, oldValue)
            )
        ) {
            setValue(fieldName, newValue);
        }
        if (!fieldAttributes.editable) {
            readonlyParents.add(fieldName);
        }
        resolvedFixedEditability.push([
            fieldName,
            { ...fieldAttributes, editable: !readonlyParent && fieldAttributes.editable },
        ]);
    });
    return Object.fromEntries(resolvedFixedEditability);
};
