/* eslint-disable @typescript-eslint/no-use-before-define */
import { Box, Collapse, Stack, Switch, Tooltip } from '@mui/material';
import { CellLocation } from '@silevis/reactgrid';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
    cancelWdp,
    createWdpVersionAndMaster,
    getWdpPdf,
    reopenWdp,
    updateWdpVersion,
    wdpAttachmentsApi,
} from '../../api/documents/wdps';
import { DimrStatusCode, DocumentType, WdpVersionContextReadDTO, WdpVersionReadDTO } from '../../api/dto';
import { useApplicationSettings } from '../../components/application/ApplicationSettingsProvider';
import { ConfirmationDialog } from '../../components/ConfirmationDialog';
import { COMMON_DOCUMENT_BUTTONS, DocumentButtonsStack } from '../../components/documents/DocumentButtonsStack';
import { DocumentPageContainer } from '../../components/documents/DocumentPageContainer';
import { DocumentPageStructure } from '../../components/documents/DocumentPageStructure';
import { DoseReportButton } from '../../components/documents/DoseReportButton';
import { LinkedDocumentsPanel } from '../../components/documents/LinkedDocumentsPanel';
import { LocalizedDocumentPdfButton } from '../../components/documents/LocalizedDocumentPdfButton';
import {
    CustomErrorResolver,
    CustomLabeledErrorItem,
    ValidationErrorsPanel,
} from '../../components/ValidationErrorsPanel';
import { useUnsavedChangesPrompt } from '../../hooks/useUnsavedChangesPrompt';
import { useDocumentSaveErrorCallback } from '../../hooks/validation';
import { EntityType } from '../../lib/information/types/EntityType';
import {
    makeWdpDefaultValues,
    makeWdpDefaultValuesFromClonedWdp,
    makeWdpDefaultValuesFromLinkedDimr,
    makeWdpRpAssessmentsValuesFromDimr,
    mapWdpToUpdateDTO,
} from '../../lib/records/documents/wdp';
import { AttachmentsAction } from '../../pages/documents/ndc/AttachmentsAction';
import { WdpTab } from '../../pages/documents/wdp/tabs/WdpTab';
import { formatHeaderLabel, recomputeRowIndexes } from '../../pages/documents/wdp/tabs/WdpUtils';
import { WdpContentHeader } from '../../pages/documents/wdp/WdpContentHeader';
import { CellIndexedLocation, SpreadsheetHistory, WdpDataKey } from '../../pages/documents/wdp/WdpInterfaces';
import { WdpPageData } from '../../pages/documents/wdp/WdpPageData';
import { WdpPageVariant } from '../../pages/documents/wdp/WdpPageVariant';
import { WdpTabKey, WdpTabKeys, WdpTabs } from '../../pages/documents/wdp/WdpTabsConfig';
import { WdpTabsRegion, WdpTabV } from '../../pages/documents/wdp/WdpTabsRegion';
import { getRoute, ROUTES } from '../../routes/routes';
import { FieldsAttributesContextProvider } from '../FieldsAttributesContext';
import { FormGenerator } from '../FormGenerator';
import { PreciseRulesDTO } from '../rule/deserializer';
import { useFormRules } from '../rule/useFormRules';
import { useIsSavableFromRules } from '../rule/useIsSavableFromRules';
import { wdpFormSchema } from '../schemas/documents/wdp';

const LOCAL_STORAGE_KEY = 'selectedTab';

const WdpTabProps = Object.fromEntries(
    Object.entries(WdpTabs)
        .filter(([, { Component }]) => Component === WdpTab)
        .map(([key, o]) => ({ key: key as WdpTabKey, value: o as WdpTabV }))
        .map((o) => [o.value.props!.metadata.dataKey, o])
) as Record<WdpDataKey, { key: WdpTabKey; value: WdpTabV }>;

const areParentWorkStepsMissingFromRpAssessments = ({
    wdpMasterId,
    versionNumber,
    wdpWorkSteps,
    wdpRpAssessments,
}: Pick<WdpVersionReadDTO, 'wdpMasterId' | 'versionNumber' | 'wdpWorkSteps' | 'wdpRpAssessments'>): boolean => {
    const parentIds = new Set(wdpWorkSteps.filter((step) => step.parent === null).map((step) => step.workStepsIdx));
    const assessmentIds = new Set(
        wdpRpAssessments.flatMap((assessment) =>
            assessment.wdpWorkStepIdxs
                .filter(
                    (that) =>
                        (that.wdpMasterId === undefined && that.versionNumber === undefined) ||
                        (that.wdpMasterId === wdpMasterId && that.versionNumber === versionNumber)
                )
                .map((idx) => idx.workStepsIdx)
        )
    );
    for (const parentId of parentIds) {
        if (!assessmentIds.has(parentId)) {
            return true;
        }
    }
    return false;
};

const customWdpErrorResolver: (
    wdp: WdpVersionReadDTO,
    lastSubmittedWdp: WdpVersionReadDTO | undefined,
    setSelectedTab: (
        key: WdpTabKey,
        focusCell?: { location: CellLocation; indexedLocation: CellIndexedLocation }
    ) => void,
    intl: IntlShape
) => CustomErrorResolver = (wdp, lastSubmittedWdp, setSelectedTab, intl) => {
    return (e) => {
        if (e.path.length >= 1 && e.path[0] in WdpTabProps) {
            const tab = WdpTabProps[e.path[0] as WdpDataKey];
            const label = intl.formatMessage({ id: tab.value.labelTranslationKey });
            if (e.path.length >= 2) {
                const index = parseInt(e.path[1]); // Original index
                const indexLabel = `${intl.formatMessage({ id: 'document.field.nested.row' })} ${index + 1}`;
                let rowId: number | undefined;
                let currentRowIndex: number | undefined;
                let columnIndex: number | undefined;
                if (lastSubmittedWdp && tab.value.props) {
                    const { metadata } = tab.value.props;
                    rowId = (
                        lastSubmittedWdp[metadata.dataKey as WdpDataKey][index] as any as Record<
                            typeof metadata.idKey,
                            number
                        >
                    )[metadata.idKey];
                    currentRowIndex = wdp[metadata.dataKey as WdpDataKey].findIndex(
                        (row) => (row as any as Record<typeof metadata.idKey, number>)[metadata.idKey] === rowId
                    );
                    if (currentRowIndex === -1) {
                        currentRowIndex = undefined;
                    }
                    columnIndex = tab.value.props.headers.findIndex(
                        ({ name }) => e.path.length >= 3 && name === e.path[2]
                    );
                    if (columnIndex === -1) {
                        columnIndex = undefined;
                    }
                }
                const handleClick = () => {
                    if (currentRowIndex !== undefined && rowId !== undefined) {
                        setSelectedTab(tab.key, {
                            indexedLocation: { rowIdx: currentRowIndex, columnIdx: columnIndex ?? -1 },
                            location: {
                                rowId,
                                columnId:
                                    (columnIndex !== undefined
                                        ? tab.value.props?.headers?.[columnIndex]?.name
                                        : undefined) ?? '',
                            },
                        });
                    }
                };
                if (e.path.length >= 3) {
                    const header = (tab.value.props?.headers ?? []).filter((h) => h.name === e.path[2])[0];
                    return {
                        ...e,
                        labels: [label, indexLabel, header ? formatHeaderLabel(header, intl) : e.path[2]],
                        onClick: handleClick,
                    };
                }
                return {
                    ...e,
                    labels: [label, indexLabel],
                    onClick: handleClick,
                };
            } else {
                return {
                    ...e,
                    labels: [label],
                    onClick: () => setSelectedTab(tab.key),
                };
            }
        } else {
            return e;
        }
    };
};

interface WdpFormProps {
    data: WdpPageData;
    refreshData: () => void;
    rules: PreciseRulesDTO<WdpVersionContextReadDTO>;
    spreadsheetFullscreen: boolean;
    setSpreadsheetFullscreen: Dispatch<SetStateAction<boolean>>;
}

export const WdpForm: React.FC<WdpFormProps> = ({
    data: pageData,
    refreshData,
    rules,
    spreadsheetFullscreen,
    setSpreadsheetFullscreen,
}) => {
    const intl = useIntl();
    const { variant } = pageData;
    const navigate = useNavigate();
    const { currentUser } = useApplicationSettings();
    const [tabDataChanged, setTabDataChanged] = useState(false);
    const defaultValues = useMemo(
        () =>
            pageData.variant === WdpPageVariant.READ
                ? pageData.wdpData
                : pageData.variant === WdpPageVariant.CLONE
                ? makeWdpDefaultValuesFromClonedWdp(pageData.wdpData, currentUser)
                : {
                      ...makeWdpDefaultValues(),
                      ...(pageData.dimrData ? makeWdpDefaultValuesFromLinkedDimr(pageData.dimrData, currentUser) : {}),
                  },
        [pageData]
    );
    const [wdp, setWdp] = React.useState<WdpVersionReadDTO>(defaultValues as WdpVersionReadDTO);
    const context = useMemo(
        () =>
            ({
                strict: false,
                isEditor: wdp.availableActions.canSave,
                isRpValidate: !!rules.context?.isRpValidate,
            } satisfies WdpVersionContextReadDTO),
        [wdp.availableActions.canSave, rules.context?.isRpValidate]
    );
    const { form, attributes } = useFormRules<WdpVersionReadDTO>({
        formProps: {
            mode: 'onSubmit',
            defaultValues: {
                ...defaultValues,
            },
        },
        rules,
        originalValues: defaultValues,
        context,
    });
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const { handleSubmit, clearErrors, formState, watch, setValue } = form;

    const [lastSubmittedWdp, setLastSubmittedWdp] = useState<WdpVersionReadDTO | undefined>(); // Keep track of the last submitted WDP: we can match the errors to it
    const handleFormSaveError = useDocumentSaveErrorCallback(form, DocumentType.WDP);

    const [selectedTab, setSelectedTab] = useState<WdpTabKey>(
        (window.localStorage.getItem(LOCAL_STORAGE_KEY) as WdpTabKey) || WdpTabKeys.TEAMS
    );
    const [cellLocation, setCellLocation] = React.useState<Record<string, CellLocation | undefined>>({});
    const [focusedCell, setFocusedCell] = useState<CellIndexedLocation | undefined>();

    const setSelectedTabAndFocus = useCallback(
        (key: WdpTabKey, focusCell?: { location: CellLocation; indexedLocation: CellIndexedLocation }) => {
            setSelectedTab(key);
            setCellLocation({ ...cellLocation, [key]: focusCell?.location });
            setFocusedCell(focusCell?.indexedLocation);
        },
        [setSelectedTab, setFocusedCell, setCellLocation, cellLocation]
    );

    const [historyEntryIndex, setHistoryEntryIndex] = React.useState(0);
    const [spreadsheetHistory, setSpreadsheetHistory] = React.useState<SpreadsheetHistory[]>([
        { snapshot: defaultValues as WdpVersionReadDTO, tab: WdpTabKeys.TEAMS, cellLocation: cellLocation },
    ]);

    const popHistoryState = (newIndex: number) => {
        setCellLocation(spreadsheetHistory[newIndex].cellLocation);
        setWdp(spreadsheetHistory[newIndex].snapshot);
        setSelectedTab(spreadsheetHistory[newIndex].tab);
        setHistoryEntryIndex(newIndex);
    };

    const handleUndoChanges = () => {
        if (historyEntryIndex > 0) {
            popHistoryState(historyEntryIndex - 1);
        }
    };

    const handleRedoChanges = () => {
        if (historyEntryIndex + 1 < spreadsheetHistory.length) {
            popHistoryState(historyEntryIndex + 1);
        }
    };

    useEffect(() => {
        if (variant === WdpPageVariant.CREATE) {
            setTabDataChanged(true);
        }

        window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    }, []);

    const { mutate: saveWdp, isLoading: isSaveLoading } = useMutation(
        // `submit: true` means "Save", while `submit: false` means "Save as draft"
        ({ data: formData, submit = false }: { data: WdpVersionReadDTO; submit?: boolean }) => {
            const data: WdpVersionReadDTO = mapWdpToUpdateDTO(
                recomputeRowIndexes({
                    ...formData,
                    wdpWorkingTeams: wdp.wdpWorkingTeams,
                    wdpParticipants: wdp.wdpParticipants,
                    wdpWorkSteps: wdp.wdpWorkSteps,
                    wdpWorkingPositions: wdp.wdpWorkingPositions,
                    wdpRpAssessments: wdp.wdpRpAssessments.map((rpa) => ({
                        ...rpa,
                        // Remove all work steps that are coming from different WDPs
                        wdpWorkStepIdxs: rpa.wdpWorkStepIdxs.filter(
                            (step) =>
                                step.wdpMasterId === undefined ||
                                step.versionNumber === undefined ||
                                (step.wdpMasterId === formData.wdpMasterId &&
                                    step.versionNumber === formData.versionNumber)
                        ),
                    })),
                    wdpPerformedTasks: wdp.wdpPerformedTasks,
                }),
                variant !== WdpPageVariant.READ
            );
            setLastSubmittedWdp(wdp);
            if (variant === WdpPageVariant.READ) {
                return updateWdpVersion({
                    id: pageData.wdpData.wdpMasterId,
                    versionNumber: pageData.wdpData.versionNumber,
                    data: data,
                    strict: submit,
                });
            } else {
                return createWdpVersionAndMaster({
                    data: data,
                });
            }
        },
        {
            onSuccess: (result) => {
                setTabDataChanged(false);

                if (variant === WdpPageVariant.READ) {
                    refreshData();
                } else {
                    navigate(
                        getRoute({
                            path: ROUTES.wdp.view,
                            params: { id: result.wdpMasterId, version: result.versionNumber },
                        }),
                        {
                            replace: true,
                        }
                    );
                }
            },
            onError: handleFormSaveError,
        }
    );

    const { mutate: handleCancelWdp, isLoading: isCancelLoading } = useMutation(cancelWdp, {
        onSuccess: () => {
            refreshData();
        },
        onError: (response: AxiosResponse) => handleFormSaveError(response),
    });

    const { mutate: handleReopenWdp, isLoading: isReopenLoading } = useMutation(reopenWdp, {
        onSuccess: () => {
            refreshData();
        },
        onError: (response: AxiosResponse) => handleFormSaveError(response),
    });

    const isSavable = useIsSavableFromRules(attributes);
    const canBeSaved = defaultValues.availableActions.canSave && isSavable;
    const { canCancel, canReopen } = defaultValues.availableActions;
    const formIsDirty = form.formState.isDirty || variant !== WdpPageVariant.READ;
    const isLoading = isSaveLoading || isCancelLoading || isReopenLoading;
    const withUnsavedChangesPrompt = useUnsavedChangesPrompt({
        when:
            !isLoading &&
            formIsDirty &&
            (variant !== WdpPageVariant.READ || (variant === WdpPageVariant.READ && canBeSaved)),
    });
    const refreshDataWithUnsavedChangesPrompt = () => withUnsavedChangesPrompt(refreshData);

    const makeSpreadsheetFieldDirty = () => {
        switch (spreadsheetHistory[historyEntryIndex].tab) {
            case WdpTabKeys.TEAMS:
                setValue('wdpWorkingTeams', wdp.wdpWorkingTeams, { shouldDirty: true });
                break;
            case WdpTabKeys.PARTICIPANTS:
                setValue('wdpParticipants', wdp.wdpParticipants, { shouldDirty: true });
                break;
            case WdpTabKeys.WORKING_POSITIONS:
                setValue('wdpWorkingPositions', wdp.wdpWorkingPositions, { shouldDirty: true });
                break;
            case WdpTabKeys.WORK_STEPS:
                setValue('wdpWorkSteps', wdp.wdpWorkSteps, { shouldDirty: true });
                break;
            case WdpTabKeys.RP_ASSESSMENTS:
                setValue('wdpRpAssessments', wdp.wdpRpAssessments, { shouldDirty: true });
                break;
            case WdpTabKeys.AS_PERFORMED:
                setValue('wdpPerformedTasks', wdp.wdpPerformedTasks, { shouldDirty: true });
                break;
        }
    };

    useEffect(() => {
        if (!tabDataChanged && historyEntryIndex > 0) {
            setTabDataChanged(true);
        }

        if (!formIsDirty && historyEntryIndex > 0) {
            makeSpreadsheetFieldDirty();
        }
    }, [wdp]);

    // Synchronize the DIMR RP assessments of the linked DIMR
    const watchDimr = watch('dimr');
    useEffect(() => {
        if (!canBeSaved) {
            return;
        }
        if (watchDimr) {
            const existingRpAssessmentsByIds = Object.fromEntries(
                wdp.wdpRpAssessments.map((rpa) => [rpa.rpAssessment.id, rpa])
            );
            setWdp({
                ...wdp,
                wdpRpAssessments: makeWdpRpAssessmentsValuesFromDimr(watchDimr).map((wrpRpa) => {
                    const existingWdpRpa = existingRpAssessmentsByIds[wrpRpa.rpAssessment.id];
                    return existingWdpRpa !== undefined // Preserve existing RP assessment line)
                        ? existingWdpRpa
                        : wrpRpa;
                }),
            });
        } else {
            setWdp({ ...wdp, wdpRpAssessments: [] });
        }
    }, [watchDimr]);
    const showWorkStepsWarning = useMemo(() => {
        return (
            pageData.variant === WdpPageVariant.READ &&
            watchDimr !== null &&
            areParentWorkStepsMissingFromRpAssessments(wdp)
        );
    }, [pageData.variant, wdp, watchDimr]);
    const uiSchemaWdp = useMemo(() => wdpFormSchema(intl, tabDataChanged), [intl, tabDataChanged]);

    const customErrorResolver = useMemo(
        () => customWdpErrorResolver(wdp, lastSubmittedWdp, setSelectedTabAndFocus, intl),
        [wdp, lastSubmittedWdp, setSelectedTabAndFocus, intl]
    );

    const customErrorComparator = useCallback((a: CustomLabeledErrorItem, b: CustomLabeledErrorItem) => {
        const errorOrdinal = (error: CustomLabeledErrorItem): [number, number, number] => {
            // if applicable, it represents [tab, row, column]
            const { path } = error;
            const tabPropsArray = Object.values(WdpTabProps).map(({ value }) => value);
            const tabIndex = tabPropsArray.findIndex(
                (tab) => path.length > 0 && tab.props?.metadata?.dataKey === path[0]
            );
            if (tabIndex !== -1) {
                const tab = tabPropsArray[tabIndex];
                const rowIndex = path.length >= 2 ? parseInt(path[1]) : null;
                const columnIndex = tab.props?.headers.findIndex(({ name }) => path.length >= 3 && name === path[2]);
                return [tabIndex, rowIndex ?? -1, columnIndex !== -1 && columnIndex !== undefined ? columnIndex : -1];
            } else {
                return [-1, 0, 0];
            }
        };
        const va = errorOrdinal(a),
            vb = errorOrdinal(b);
        // Sort by triplet
        return va[0] !== vb[0] ? va[0] - vb[0] : va[1] - vb[1] !== 0 ? va[1] - vb[1] : va[2] - vb[2];
    }, []);

    const saveButtons = canBeSaved
        ? [
              [
                  {
                      ...(variant === WdpPageVariant.CREATE || variant === WdpPageVariant.CLONE
                          ? COMMON_DOCUMENT_BUTTONS.CREATE
                          : COMMON_DOCUMENT_BUTTONS.SAVE),
                  },
                  { submit: true },
              ] as const,
              ...(variant === WdpPageVariant.READ && !(wdp?.dimr && wdp?.dimr.status.code !== DimrStatusCode.DRAFT)
                  ? ([[COMMON_DOCUMENT_BUTTONS.SAVE_AS_DRAFT, { submit: false }]] as const)
                  : []),
          ]
        : [];

    const switchModes = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSpreadsheetFullscreen(e.target.checked);
    };

    return (
        <FormProvider {...form}>
            <DocumentPageStructure
                isLoading={isLoading}
                noContainer
                Header={
                    <WdpContentHeader
                        data={pageData}
                        editable={canBeSaved}
                        isLoading={isLoading}
                        longTitle={
                            (spreadsheetFullscreen && variant === WdpPageVariant.READ) ||
                            variant === WdpPageVariant.CLONE
                                ? pageData.wdpData?.title
                                : undefined
                        }
                        onRefreshClick={
                            variant === WdpPageVariant.READ ? refreshDataWithUnsavedChangesPrompt : undefined
                        }
                        Left={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {saveButtons.map(([button, payload], i) => (
                                    <DocumentButtonsStack.Action
                                        key={i}
                                        {...button}
                                        onClick={() => {
                                            window.localStorage.setItem(LOCAL_STORAGE_KEY, selectedTab);
                                            clearErrors();
                                            return handleSubmit((data) => saveWdp({ data, ...payload }))();
                                        }}
                                        // If dirty: disable draft button, and disable normal save button only if it's alone (= no draft button)
                                        disabled={(!button.important || saveButtons.length <= 1) && !formIsDirty}
                                        disablingReason={intl.formatMessage({
                                            id: 'document.form.disabledNoChange',
                                        })}
                                    />
                                ))}
                            </Stack>
                        }
                        Right={
                            <Stack direction="row" spacing={1} alignItems="center" sx={{ height: '100%' }}>
                                {variant === WdpPageVariant.READ && (
                                    <>
                                        {canCancel && (
                                            <>
                                                <DocumentButtonsStack.Action
                                                    {...COMMON_DOCUMENT_BUTTONS.CANCEL}
                                                    onClick={() => setConfirmationOpen(true)}
                                                />

                                                <ConfirmationDialog
                                                    open={confirmationOpen}
                                                    onClose={() => setConfirmationOpen(false)}
                                                    onConfirmation={() =>
                                                        handleCancelWdp({
                                                            id: pageData.wdpData.wdpMasterId,
                                                            versionNumber: pageData.wdpData.versionNumber,
                                                            version: pageData.wdpData.version,
                                                        })
                                                    }
                                                    confirmationButtonColor={'error'}
                                                >
                                                    <Stack direction="column" spacing={2}>
                                                        <Box>
                                                            <FormattedMessage
                                                                id="wdp.status.change.confirmation"
                                                                values={{
                                                                    val: intl
                                                                        .formatMessage({
                                                                            id: 'document.action.cancel',
                                                                        })
                                                                        .toLowerCase(),
                                                                }}
                                                            />
                                                        </Box>
                                                    </Stack>
                                                </ConfirmationDialog>
                                            </>
                                        )}
                                        {canReopen && (
                                            <>
                                                <DocumentButtonsStack.Action
                                                    {...COMMON_DOCUMENT_BUTTONS.REOPEN}
                                                    onClick={() => setConfirmationOpen(true)}
                                                />

                                                <ConfirmationDialog
                                                    open={confirmationOpen}
                                                    onClose={() => setConfirmationOpen(false)}
                                                    onConfirmation={() =>
                                                        handleReopenWdp({
                                                            id: pageData.wdpData.wdpMasterId,
                                                            versionNumber: pageData.wdpData.versionNumber,
                                                            version: pageData.wdpData.version,
                                                        })
                                                    }
                                                    confirmationButtonColor={'primary'}
                                                >
                                                    <Stack direction="column" spacing={2}>
                                                        <Box>
                                                            <FormattedMessage
                                                                id="wdp.status.change.confirmation"
                                                                values={{
                                                                    val: intl
                                                                        .formatMessage({
                                                                            id: 'document.action.reopen',
                                                                        })
                                                                        .toLowerCase(),
                                                                }}
                                                            />
                                                        </Box>
                                                    </Stack>
                                                </ConfirmationDialog>
                                            </>
                                        )}
                                        {
                                            <DocumentButtonsStack.Action
                                                {...COMMON_DOCUMENT_BUTTONS.CLONE}
                                                onClick={() => {
                                                    window.open(
                                                        getRoute({
                                                            path: ROUTES.wdp.clone,
                                                            params: {
                                                                id: String(pageData.wdpData.wdpMasterId),
                                                                version: String(pageData.wdpData.versionNumber),
                                                            },
                                                        })
                                                    );
                                                }}
                                                disabled={formIsDirty}
                                                disablingReason={intl.formatMessage({
                                                    id: 'document.form.disabledUnsaved',
                                                })}
                                            />
                                        }
                                        {pageData.wdpData.dimr && (
                                            <DoseReportButton dimrId={pageData.wdpData.dimr.dimrMaster.id} />
                                        )}
                                        <AttachmentsAction
                                            attachments={pageData.wdpData.attachments}
                                            linkAttachments={(attachments) =>
                                                wdpAttachmentsApi.linkAttachments({
                                                    id: pageData.wdpData.id,
                                                    taskId: undefined,
                                                    ...attachments,
                                                })
                                            }
                                            unlinkAttachments={(attachments) =>
                                                wdpAttachmentsApi.unlinkAttachments({
                                                    id: pageData.wdpData.id,
                                                    taskId: undefined,
                                                    ...attachments,
                                                })
                                            }
                                            updateAttachment={(data) =>
                                                wdpAttachmentsApi.updateAttachment({
                                                    id: pageData.wdpData.id,
                                                    taskId: undefined,
                                                    ...data,
                                                })
                                            }
                                            readOnly={!canBeSaved}
                                        />
                                    </>
                                )}
                                {variant === WdpPageVariant.READ && (
                                    <LocalizedDocumentPdfButton
                                        queryKey={['wdp', pageData.wdpData.wdpMasterId, pageData.wdpData.versionNumber]}
                                        fetchPdf={(language) =>
                                            getWdpPdf({
                                                id: pageData.wdpData.wdpMasterId,
                                                versionNumber: pageData.wdpData.versionNumber,
                                                langCode: language,
                                            })
                                        }
                                        isPdfPreview={false} // TODO?
                                        canGenerateInBothLanguages={true}
                                        dirty={formIsDirty}
                                    />
                                )}
                                <Tooltip
                                    title={intl.formatMessage({
                                        id: 'wdp.spreadsheetFullscreen',
                                    })}
                                >
                                    <Switch checked={spreadsheetFullscreen} onChange={switchModes} size="small" />
                                </Tooltip>
                            </Stack>
                        }
                        showWorkStepsWarning={showWorkStepsWarning}
                    />
                }
                Body={
                    <>
                        <Collapse in={!spreadsheetFullscreen} mountOnEnter unmountOnExit>
                            <DocumentPageContainer drawer={false} noBottomPadding>
                                <LinkedDocumentsPanel
                                    type={EntityType.Wdp}
                                    wdp={variant === WdpPageVariant.READ ? pageData.wdpData : { dimr: null }}
                                />
                                <ValidationErrorsPanel
                                    schema={uiSchemaWdp}
                                    customResolver={customErrorResolver}
                                    errorComparator={customErrorComparator}
                                />
                                <FieldsAttributesContextProvider value={attributes}>
                                    <FormGenerator rootElement={uiSchemaWdp} form={form} disabled={isLoading} />
                                </FieldsAttributesContextProvider>
                            </DocumentPageContainer>
                        </Collapse>
                        <DocumentPageContainer drawer={false} maxWidth={false} noTopPadding>
                            <WdpTabsRegion
                                wdp={wdp}
                                setWdp={setWdp!}
                                errors={formState.errors}
                                disabled={isLoading}
                                readOnly={!canBeSaved}
                                lastSubmittedWdp={lastSubmittedWdp}
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                cellLocation={cellLocation[selectedTab]}
                                setCellLocation={(newLocation) =>
                                    setCellLocation({ ...cellLocation, [selectedTab]: newLocation })
                                }
                                attributes={attributes}
                                focusedCell={focusedCell}
                                showWarning={tabDataChanged}
                                handleUndoChanges={handleUndoChanges}
                                handleRedoChanges={handleRedoChanges}
                                spreadsheetHistory={spreadsheetHistory}
                                setSpreadsheetHistory={setSpreadsheetHistory}
                                historyEntryIndex={historyEntryIndex}
                                setHistoryEntryIndex={setHistoryEntryIndex}
                            />
                        </DocumentPageContainer>
                    </>
                }
            />
        </FormProvider>
    );
};
