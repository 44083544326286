import { useMemo } from 'react';
import { FieldAttributesBase } from './deserializer';

interface EditableRecord {
    [key: string]: EditableRecordOrBoolean;
}

type EditableRecordOrBoolean = EditableRecord | boolean;

const isEditable = (entries: [string, boolean][]): boolean => {
    const split = (s: string): string[] => s.split('.');
    const hasEditable = (record: EditableRecordOrBoolean): boolean =>
        typeof record === 'boolean' ? record : Object.values(record).some((v) => hasEditable(v));
    let editableRecord: EditableRecordOrBoolean = {};
    // Deepest fields first
    entries
        .slice()
        .sort(([a], [b]) => split(b).length - split(a).length)
        .forEach(([key, editable]) => {
            const parts = split(key);
            let r = editableRecord;
            for (let i = 0; i < parts.length; i++) {
                const k = parts[i];
                if (typeof r === 'boolean') {
                    throw new Error(); // Impossible
                } else {
                    const last = i === parts.length - 1;
                    if (r[k] === undefined) {
                        r[k] = last ? editable : {};
                        r = r[k];
                    } else if (typeof r[k] === 'boolean' && last) {
                        r[k] = r[k] || editable;
                    } else if (typeof r[k] === 'object') {
                        if (!last) {
                            r = r[k];
                        } else {
                            r[k] = editable ? hasEditable(r[k]) : false;
                        }
                    } else {
                        throw new Error();
                    }
                }
            }
        });
    return hasEditable(editableRecord);
};

export const useIsSavableFromRules = (attributes: Record<string, FieldAttributesBase>) =>
    // Is there at least one field that is actually editable?
    useMemo(() => {
        const entries = Object.entries(attributes);
        return isEditable(entries.map(([k, { editable }]) => [k, editable]));
    }, [attributes]);
