import { Person } from '@mui/icons-material';
import { Chip } from '@mui/material';
import React from 'react';
import { PersonReadDTO } from '../../api/dto';
import { PersonTooltip } from '../../components/PersonTooltip';
import { FilterTypeEnum } from '../../components/searching/filters';
import { fetchPeopleByName, fetchPeopleForFiltering } from '../../lib/api';
import { getPersonLabel } from '../../lib/label';
import { AsyncAutocompleteFetchOptions, FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, TFilterElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

const sortPeopleAlphabetically = (people: PersonReadDTO[]) => {
    return people.slice().sort((a, b) => a.searchLabel.localeCompare(b.searchLabel));
};

export const personTypeProperties = ({
    multiple,
    fetchEndpoint,
    watchPaths,
    fetchOnKeystroke = true,
    fetchOnOpen,
    fetchOnLoad,
    preserveOrder,
}: {
    multiple?: boolean;
    fetchEndpoint?: AsyncAutocompleteFetchOptions<PersonReadDTO>;
    watchPaths?: string[] | ((path: string) => string[]);
    fetchOnKeystroke?: boolean;
    fetchOnOpen?: boolean;
    fetchOnLoad?: boolean;
    preserveOrder?: boolean;
} = {}): AsyncAutocompleteFormElement['typeProperties'] => {
    const originalFetchOptions = fetchEndpoint ?? fetchPeopleByName;

    const fetchOptions: AsyncAutocompleteFetchOptions<PersonReadDTO> = async (...args) => {
        const people = await originalFetchOptions(...args);
        return preserveOrder ? people : sortPeopleAlphabetically(people);
    };

    return {
        watchPaths,
        fetchOptions,
        fetchUseCases: [
            ...(fetchOnKeystroke ? [FetchUseCase.ON_KEYSTROKE] : []),
            ...(fetchOnOpen ? [FetchUseCase.ON_OPEN] : []),
            ...(fetchOnLoad ? [FetchUseCase.ON_LOAD] : []),
        ],
        getOptionLabel: getPersonLabel,
        multiple,
        renderTagChip: (chipProps, value) => (
            <PersonTooltip person={value} arrow>
                <span>
                    <Chip {...chipProps} />
                </span>
            </PersonTooltip>
        ),
        autocompleteProps: {
            forcePopupIcon: false,
        },
        endAdornment: ({ field, disabled }) => {
            const { value: person } = field;
            return (
                <>
                    {!multiple && person && !Array.isArray(person) ? (
                        <PersonTooltip person={person}>
                            <Person color={!disabled ? 'action' : 'disabled'} sx={{ cursor: 'help' }} />
                        </PersonTooltip>
                    ) : null}
                </>
            );
        },
    };
};

interface PersonSchemaParameters extends Pick<AsyncAutocompleteFormElement, 'path' | 'label' | 'required'> {
    readOnly?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    fetchEndpoint?: AsyncAutocompleteFetchOptions<PersonReadDTO>;
    watchPaths?: string[] | ((path: string) => string[]);
    fetchOnKeystroke?: boolean;
    fetchOnOpen?: boolean;
    fetchOnLoad?: boolean;
    preserveOrder?: boolean;
}

export const personSchema = ({
    path,
    label,
    required,
    readOnly,
    disabled,
    multiple,
    fetchEndpoint,
    watchPaths,
    fetchOnKeystroke,
    fetchOnOpen,
    fetchOnLoad,
    preserveOrder,
}: PersonSchemaParameters): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    path,
    label,
    required,
    readOnly,
    disabled,
    typeProperties: personTypeProperties({
        multiple,
        fetchEndpoint,
        watchPaths,
        fetchOnKeystroke,
        fetchOnOpen,
        fetchOnLoad,
        preserveOrder,
    }),
});

interface PersonFilterSchemaParameters extends Pick<TFilterElement, 'id' | 'label'> {}

export const personFilterSchema = ({ id, label }: PersonFilterSchemaParameters): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
    id,
    label,
    valueGetter: (value: PersonReadDTO) => value.searchLabel,
    typeProperties: {
        ...personTypeProperties({ fetchEndpoint: fetchPeopleForFiltering }),
        autocompleteProps: {
            disableCloseOnSelect: false,
            forcePopupIcon: false,
        },
    },
});
