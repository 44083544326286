import { Error, ExpandMore, WarningAmber } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Chip,
    Stack,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';

interface DefaultAccordionProps {
    title: string;
    additionalText?: string;
    error?: boolean;
    icon?: React.ReactNode;
    disabled?: boolean;
    disableGutters?: boolean;
    collapsed?: boolean;
    chipValue?: number;
    children: React.ReactNode;
    customStyles?: React.CSSProperties;
}
export const DefaultAccordion: React.FC<DefaultAccordionProps> = ({
    title,
    additionalText,
    error,
    icon,
    disabled,
    disableGutters,
    collapsed,
    chipValue,
    children,
    customStyles = {},
}) => {
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(!collapsed);

    useEffect(() => {
        setIsExpanded(!collapsed);
    }, [collapsed]);

    return (
        <Accordion expanded={isExpanded} onChange={() => setIsExpanded((prev) => !prev)} disableGutters>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                style={{ backgroundColor: theme.palette.grey.A200 }}
                disabled={disabled}
                sx={{ minHeight: '48px', '& .MuiAccordionSummary-content': { margin: 0 }, ...customStyles }}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    {icon}
                    <Typography>{title}</Typography>
                    {chipValue && <Chip size="small" label={chipValue} />}
                    {additionalText && (
                        <Tooltip title={additionalText} placement="right">
                            <WarningAmber color="warning" />
                        </Tooltip>
                    )}
                    {error && <Error color="error" />}
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ mt: disableGutters ? undefined : 1, p: disableGutters ? 0 : undefined }}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};
