import { Help } from '@mui/icons-material';
import { Box, InputAdornment, Stack, Tooltip, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { FormProvider, useForm, UseFormGetValues } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { TaskAction as TaskActionType, TaskDTO } from '../../api/dto';
import { FormGenerator } from '../../forms/FormGenerator';
import { TFormElement } from '../../forms/types';
import { UiSchemaType } from '../../forms/UiSchemaType';
import { useDeviceType } from '../../hooks/useDeviceType';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { DocumentDrawerContext } from '../documents/DocumentDrawerStructure';
import { Pulse } from '../Pulse';
import { ResponsiveButton } from '../ResponsiveButton';
import { useTaskContext } from '../TaskContext';
import { ActionKind, DEFAULT_ACTION_KIND, TaskActionConfiguration, TASK_CONFIGURATION } from './TaskTypeConfiguration';

type TaskSubmitPayload = { task: TaskDTO; action: TaskActionConfiguration };

export type TaskValidateCallback = (args: TaskSubmitPayload & { callback: () => void }) => Promise<void>;

export type TaskSubmitCallback = (args: TaskSubmitPayload & { comment: string | null }) => Promise<void>;

export const commentSchema = (
    intl: IntlShape,
    { helperText, toolTip }: { helperText?: string; toolTip?: string } = {}
): TFormElement => ({
    type: UiSchemaType.LAYOUT_VERTICAL,
    elements: [
        {
            type: UiSchemaType.INPUT,
            label: intl.formatMessage({ id: 'document.task.field.comment' }),
            path: 'comment',
            typeProperties: {
                multiline: true,
                helperText: helperText,
                endAdornment: toolTip
                    ? () => (
                          <InputAdornment position="end">
                              <Tooltip title={<Typography variant="caption">{toolTip}</Typography>}>
                                  <Help sx={{ cursor: 'help' }} />
                              </Tooltip>
                          </InputAdornment>
                      )
                    : undefined,
            },
        },
    ],
});

interface TaskActionProps {
    task: TaskDTO;
    actionIndex: number;
    onValidate: TaskValidateCallback;
    onSubmit: TaskSubmitCallback;
    configuration: TaskActionConfiguration;
    disabled: boolean;
    getValues: UseFormGetValues<any>;
    context: any;
    isDirty: boolean;
    noSave: boolean;
    ignoreBlur: boolean;
}

const TaskAction: React.FC<TaskActionProps> = ({
    task,
    actionIndex,
    onValidate,
    onSubmit,
    configuration,
    disabled,
    getValues,
    context,
    isDirty,
    noSave,
    ignoreBlur,
}) => {
    const { withDispatchTaskEvent } = useTaskContext();
    const intl = useIntl();

    const { isMobile } = useDeviceType();

    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
    const { secondary, icon, buttonContent, confirmationContent, kind } = configuration;

    const payload = { task, action: configuration };

    const commentForm = useForm<{ comment: string | undefined }>({
        defaultValues: {
            comment: undefined,
        },
    });

    const openConfirmation = () => {
        commentForm.reset();
        setConfirmationOpen(true);
    };

    const handleSubmit = withDispatchTaskEvent(
        commentForm.handleSubmit(({ comment }) => onSubmit({ ...payload, comment: comment?.trim() || null }))
    );

    const { actionButtonKey } = useContext(DocumentDrawerContext)!;

    const disabledDirty = !disabled && noSave && isDirty;

    const buttonColor = (
        {
            [ActionKind.MUST_DO]: 'primary',
            [ActionKind.CAN_DO_SOFT]: 'warning',
            [ActionKind.CAN_DO_HARD]: 'error',
        } as const
    )[kind ?? DEFAULT_ACTION_KIND];

    return (
        <>
            <Pulse
                key={`${task.id}-${actionIndex}-${actionButtonKey}`}
                scale={!isMobile ? 1.25 : 2}
                enabled={!secondary && !disabled}
            >
                <ResponsiveButton
                    icon={icon}
                    buttonVariant={!secondary ? 'contained' : 'outlined'}
                    buttonColor={buttonColor}
                    disabled={disabled || disabledDirty}
                    tooltipText={
                        disabledDirty ? intl.formatMessage({ id: 'document.form.disabledUnsaved' }) : undefined
                    }
                    onClick={() =>
                        configuration.validation
                            ? onValidate({
                                  ...payload,
                                  callback: openConfirmation,
                              })
                            : openConfirmation()
                    }
                    ignoreBlur={ignoreBlur}
                >
                    {buttonContent({ intl })}
                </ResponsiveButton>
            </Pulse>
            <ConfirmationDialog
                open={isConfirmationOpen}
                onClose={() => setConfirmationOpen(false)}
                onConfirmation={handleSubmit}
                confirmationButtonColor={buttonColor}
            >
                <Stack direction="column" spacing={2}>
                    <Box>{confirmationContent && confirmationContent({ data: getValues(), context, intl })}</Box>
                    {(!!configuration.comment || task.commentEnabled) && (
                        <Box>
                            <FormProvider {...commentForm}>
                                <FormGenerator
                                    form={commentForm}
                                    rootElement={commentSchema(intl, {
                                        helperText: intl.formatMessage({ id: 'document.task.commentHelper' }),
                                    })}
                                />
                            </FormProvider>
                        </Box>
                    )}
                </Stack>
            </ConfirmationDialog>
        </>
    );
};

interface TaskActionsProps {
    task: TaskDTO;
    onValidate: TaskValidateCallback;
    onSubmit: TaskSubmitCallback;
    disabled: boolean;
    getValues: UseFormGetValues<any>;
    context: any;
    isDirty: boolean;
}

export const TaskActions: React.FC<TaskActionsProps> = ({
    task,
    onValidate,
    onSubmit,
    disabled,
    getValues,
    context,
    isDirty,
}) => {
    const taskConfiguration = TASK_CONFIGURATION[task.type.type];
    const configuredActions = task.actions
        .map((actionType) => ({
            type: actionType,
            configurations: taskConfiguration.all
                ? taskConfiguration.actions[actionType]
                : taskConfiguration.actions[task.documentType]?.[actionType],
        }))
        .filter(
            (o): o is { type: TaskActionType; configurations: TaskActionConfiguration | TaskActionConfiguration[] } =>
                o.configurations !== undefined
        )
        .map((o) => ({
            ...o,
            configurations: Array.isArray(o.configurations) ? o.configurations : [o.configurations],
        }));
    return (
        <>
            {configuredActions.length > 0 && (
                <Stack direction="row" spacing={1}>
                    {configuredActions.map(({ type, configurations }) =>
                        configurations.map((configuration, i) => (
                            <TaskAction
                                key={`${type}-${i}`}
                                actionIndex={i}
                                task={task}
                                onValidate={onValidate}
                                onSubmit={onSubmit}
                                configuration={configuration}
                                disabled={disabled}
                                getValues={getValues}
                                context={context}
                                isDirty={isDirty}
                                noSave={!!configuration.noSave}
                                ignoreBlur={!!configuration.ignoreBlur}
                            />
                        ))
                    )}
                </Stack>
            )}
        </>
    );
};
