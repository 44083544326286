import { CellLocation, ChevronCell, DefaultCellTypes, NumberCell } from '@silevis/reactgrid';
import React from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { IntlShape, MessageDescriptor } from 'react-intl';
import { WdpVersionReadDTO } from '../../../api/dto';
import { CheckpointsCell } from '../../../components/spreadsheet/CheckpointsCellTemplate';
import { CustomDateCell } from '../../../components/spreadsheet/CustomDateCellTemplate';
import { LongInputCell } from '../../../components/spreadsheet/LongInputCellTemplate';
import { DropdownsCell, MultiDropdownCell } from '../../../components/spreadsheet/MultiDropdownCellTemplate';
import { PersonCell } from '../../../components/spreadsheet/PersonAutocompleteTemplate';
import { WorkStepsCell } from '../../../components/spreadsheet/WorkStepsCellTemplate';
import { FieldAttributesBase } from '../../../forms/rule/deserializer';
import { WdpTabMenuContextValue } from './tabs/menu/WdpTabMenuContext';
import { WdpTabKey } from './WdpTabsConfig';

export interface MenuButton {
    label: string | ((intl: IntlShape) => string);
    Icon: React.FC;
    onClick: (context: WdpTabMenuContextValue) => void;
    tooltip?: string;
    disabled?: boolean;
}

export enum GridMenuItemType {
    Menu,
    Button,
}

export type GridMenuItem =
    | { type: GridMenuItemType.Menu; menu: GridMenu }
    | { type: GridMenuItemType.Button; button: MenuButton };

export interface GridMenu {
    direction: 'row' | 'column';
    divider?: boolean;
    items: GridMenuItem[];
}

export interface CellIndexedLocation {
    rowIdx: number;
    columnIdx: number;
}

export interface TableHeader {
    name: string;
    labelTranslationKey: MessageDescriptor['id'] | null;
    unitTranslationKey?: MessageDescriptor['id'];
    width?: number;
    readonly?: boolean | ((row: any, tree: TreeNode[] | undefined) => boolean);
    hideReadonly?: boolean; // Hides the value if `readonly` is (or returns) `true`
    type?: CellTypes['type'];
    customType?: FieldMetadata;
    idKey?: string;
    redactedKey?: string;
}

export interface SpreadsheetHistory {
    snapshot: WdpVersionReadDTO;
    tab: WdpTabKey;
    cellLocation: Record<string, CellLocation | undefined>;
}

export interface WdpTabsProps {
    wdp: WdpVersionReadDTO;
    setWdp: React.Dispatch<React.SetStateAction<WdpVersionReadDTO>>;
    selectedTab: WdpTabKey;
    setSelectedTab: (
        key: WdpTabKey,
        focusCell?: { location: CellLocation; indexedLocation: CellIndexedLocation }
    ) => void;
    cellLocation: CellLocation | undefined;
    setCellLocation: (cellLocation: CellLocation | undefined) => void;
    attributes: Record<string, FieldAttributesBase>;
    errors?: FieldErrors<WdpVersionReadDTO>;
    disabled?: boolean;
    readOnly?: boolean;
    lastSubmittedWdp?: WdpVersionReadDTO;
    focusedCell?: CellIndexedLocation;
    showWarning?: boolean;
    handleUndoChanges: () => void;
    handleRedoChanges: () => void;
    spreadsheetHistory: SpreadsheetHistory[];
    setSpreadsheetHistory: React.Dispatch<React.SetStateAction<SpreadsheetHistory[]>>;
    historyEntryIndex: number;
    setHistoryEntryIndex: React.Dispatch<React.SetStateAction<number>>;
}

export type CellTypes =
    | DefaultCellTypes
    | DropdownsCell
    | PersonCell
    | MultiDropdownCell
    | ChevronCell
    | NumberCell
    | CustomDateCell
    | CheckpointsCell
    | WorkStepsCell
    | LongInputCell;

export type WdpDataKey = keyof {
    [K in keyof WdpVersionReadDTO as WdpVersionReadDTO[K] extends unknown[] ? K : never]: unknown; // Value doesn't matter
} &
    string;

// Note: see https://www.typescriptlang.org/docs/handbook/advanced-types.html#distributive-conditional-types why the `T extends T`
export type WdpNestedFieldKey<T extends WdpDataKey, U> = T extends T
    ? keyof {
          [K in keyof WdpVersionReadDTO[T][number] as WdpVersionReadDTO[T][number][K] extends U ? K : never]: unknown;
      } &
          string
    : never;

export type WdpIdKey<T extends WdpDataKey> = WdpNestedFieldKey<T, number>;
export type WdpLabelKey<T extends WdpDataKey> = WdpNestedFieldKey<T, unknown>;

type WdpBacklinkKeyFor<T extends WdpDataKey> = WdpDataKey extends infer K1
    ? K1 extends keyof WdpVersionReadDTO
        ? WdpVersionReadDTO[K1] extends (infer V1)[]
            ? keyof V1 extends infer K2
                ? K2 extends keyof V1
                    ? V1[K2] extends unknown[]
                        ? WdpVersionReadDTO[T][number] extends V1[K2][number]
                            ? K2
                            : never
                        : never
                    : never
                : never
            : never
        : never
    : never;

export interface FieldMetadata {
    label: MessageDescriptor['id'];
    dataKey: string;
    idKey: string;
    labelKey: string;
    labelNestedKey?: string;
    fallbackLabelKey?: string;
    relationshipName?: string;
}

interface FieldMetadataInternal<T extends WdpDataKey, U extends WdpIdKey<T>, B extends WdpBacklinkKeyFor<T>>
    extends FieldMetadata {
    dataKey: T;
    idKey: U;
    labelKey: WdpLabelKey<T>;
    fallbackLabelKey?: WdpLabelKey<T>;
    relationshipName?: B;
}

const field = <T extends WdpDataKey, U extends WdpIdKey<T>, B extends WdpBacklinkKeyFor<T>>(
    o: FieldMetadataInternal<T, U, B>
) => o;

export const fieldsMetadata = {
    teams: field({
        label: 'wdp.spreadsheet.element.team',
        dataKey: 'wdpWorkingTeams',
        idKey: 'teamsIdx',
        labelKey: 'name',
        relationshipName: 'wdpWorkingTeamIdxs',
    }),
    participants: field({
        label: 'wdp.spreadsheet.element.participant',
        dataKey: 'wdpParticipants',
        idKey: 'participantsIdx',
        labelKey: 'person',
        labelNestedKey: 'searchLabel',
        fallbackLabelKey: 'name',
        relationshipName: 'wdpParticipantIdxs',
    }),
    positions: field({
        label: 'wdp.spreadsheet.element.workingPosition',
        dataKey: 'wdpWorkingPositions',
        idKey: 'workingPositionsIdx',
        labelKey: 'name',
        relationshipName: 'wdpWorkingPositionIdxs',
    }),
    workSteps: field({
        label: 'wdp.spreadsheet.element.workStep',
        dataKey: 'wdpWorkSteps',
        idKey: 'workStepsIdx',
        labelKey: 'description',
        relationshipName: 'wdpWorkStepIdxs',
    }),
    rpAssessments: field({
        label: 'wdp.spreadsheet.element.rpAssessment',
        dataKey: 'wdpRpAssessments',
        idKey: 'assessmentsIdx',
        labelKey: 'assessmentsIdx', // TODO: what is the proper value here?
    }),
    tasks: field({
        label: 'wdp.spreadsheet.element.performedTask',
        dataKey: 'wdpPerformedTasks',
        idKey: 'performedTasksIdx',
        labelKey: 'description',
        relationshipName: 'wdpPerformedTaskIdxs',
    }),
} satisfies Record<string, FieldMetadata>;

export interface TreeNode {
    id: number;
    parent: number | null;
    rowId: number;
    hasChildren: boolean;
    treeIndex: string;
}
