import {
    AddCircle,
    AddComment,
    AddTask,
    AssignmentTurnedIn,
    AttachFile,
    Cancel,
    CancelPresentation,
    CheckCircle,
    Close,
    DoDisturb,
    DriveFileRenameOutline,
    Edit,
    LockOpen,
    LockOutlined,
    ManageHistory,
    PauseCircle,
    RemoveCircle,
    ScheduleSend,
    Send,
    Sensors,
    SensorsOff,
    StopCircle,
    TaskOutlined,
    UploadFile,
} from '@mui/icons-material';
import { Alert, Box } from '@mui/material';
import { AccountReactivate, Leak, PlayCircle } from 'mdi-material-ui';
import React from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import { NavigateFunction } from 'react-router-dom';
import {
    addSignatureTask,
    cancelActivity,
    closeActivity,
    facilityCoordinatorSchedule,
    interruptActivity,
    resumeActivity,
    submitActivity,
    validateSubmitActivity,
} from '../../api/activities-nim';
import {
    cancelDimr,
    createNewDimrVersion,
    requestClosure,
    requestFeedback,
    submitDimr,
    validateSubmitDimr,
} from '../../api/documents/dimrs';
import {
    cancelFirePermit,
    closeFirePermit,
    submitFirePermit,
    validateSubmitFirePermit,
} from '../../api/documents/firePermits';
import {
    cancelIs37,
    cancelIs37ChangeRequest,
    changeAlarmTypeTask,
    disableTask,
    noSensorsTask,
    recommissionTask,
    scheduleRecommissioning,
    setSensorsTask,
    submitIs37,
    validateSubmitIs37,
} from '../../api/documents/is37s';
import {
    cancelLockout,
    completeWorkTask,
    confirmReceptionOfYellowPaperTask,
    lockLockoutTask,
    provideYellowPaperTask,
    receiveYellowPaperTask,
    returnYellowPaperTask,
    submitLockout,
    unlockLockoutTask,
    validateLock,
    validateSubmitLockout,
    validateUnlock,
} from '../../api/documents/lockouts';
import { cancelNdc, finishNdc, submitNdc, validateSubmitNdc } from '../../api/documents/noteDeCoupures';
import { cancelVic, submitVic, validateSubmitVic } from '../../api/documents/vics';
import {
    CommentUpdateDTO,
    DimrVersionContextReadDTO,
    DimrVersionReadDTO,
    DocumentType,
    TaskAction,
    TaskCode,
    TaskDTO,
    TaskType,
} from '../../api/dto';
import { approveSignature, rejectSignature } from '../../api/signatures';
import { isIs37LateSubmission, mapIs37ToUpdateDTO } from '../../lib/records/documents/is37';
import { mapLockoutToUpdateDTO } from '../../lib/records/documents/lockout';
import { mapNdcToUpdateDTO } from '../../lib/records/documents/noteDeCoupure';
import { getRoute, ROUTES } from '../../routes/routes';

/**
 * The importance of a task action. This is used to adapt the look of the task.
 */
export enum ActionKind {
    /**
     * This action must be done (or an equivalent one).
     */
    MUST_DO,
    /**
     * The action can be done (but usually isn't) and modifies the flow of the document with soft consequences.
     */
    CAN_DO_SOFT,
    /**
     * The action can be done (but usually isn't) and drastically modifies the flow of the document with major consequences.
     */
    CAN_DO_HARD,
}

/**
 * All actions are configured by default to be mandatory.
 */
export const DEFAULT_ACTION_KIND = ActionKind.MUST_DO;

const getCancelAction = (
    cancelCallback: (args: { id: number; comment: CommentUpdateDTO }) => Promise<any>
): TaskActionConfigurationRecord => ({
    [TaskAction.CANCEL]: {
        mutation: ({ task, comment }) => cancelCallback({ id: task.documentId, comment: { comment } }),
        icon: <DoDisturb />,
        buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.cancel' }),
        confirmationContent: ({ intl }) => intl.formatMessage({ id: 'document.task.cancel.confirm' }),
        comment: true,
        kind: ActionKind.CAN_DO_HARD,
    },
});

const getApproveRejectAction = ({ lockout }: { lockout?: boolean } = {}): TaskActionConfigurationRecord => ({
    [TaskAction.APPROVE]: {
        mutation: ({ task, comment }) => approveSignature({ taskId: task.id, comment: { comment } }),
        icon: <CheckCircle />,
        buttonContent: ({ intl }) =>
            intl.formatMessage({ id: !lockout ? 'document.action.approve' : 'document.action.request.approve' }),
        confirmationContent: ({ intl }) => intl.formatMessage({ id: 'document.signature.confirm.approve' }),
        noSave: true,
    },
    [TaskAction.REJECT]: {
        mutation: ({ task, comment }) => rejectSignature({ taskId: task.id, comment: { comment } }),
        icon: <Cancel />,
        secondary: true,
        buttonContent: ({ intl }) =>
            intl.formatMessage({ id: !lockout ? 'document.action.reject' : 'document.action.request.reject' }),
        confirmationContent: ({ intl }) => intl.formatMessage({ id: 'document.signature.confirm.reject' }),
        comment: true,
        kind: ActionKind.CAN_DO_HARD,
        noSave: true,
    },
});

const ndcSubmitConfirmation: (data: any) => React.ReactNode = () => (
    <>
        <Box>
            <FormattedMessage id="ndc.confirm.submit" />
        </Box>
        <Box>
            <FormattedMessage id="common.questionConfirm" />
        </Box>
    </>
);

const is37SubmitConfirmation: (data: any) => React.ReactNode = ({ startDate }) => (
    <>
        {isIs37LateSubmission({ startDate }) ? (
            <Alert severity="warning" sx={{ mb: 1 }}>
                <FormattedMessage id="is37.warning.lateSubmission" />
            </Alert>
        ) : (
            <></>
        )}
        <Box>
            <FormattedMessage id="is37.warning.creation" />
        </Box>
        <Box>
            <FormattedMessage id="is37.warning.consequence" />
        </Box>
        <Box>
            <FormattedMessage id="is37.warning.recommendation" />
        </Box>
        <Box>
            <FormattedMessage id="common.questionConfirm" />
        </Box>
    </>
);

const firePermitSubmitConfirmation: (data: any) => React.ReactNode = () => (
    <>
        <Box>
            <FormattedMessage id="firePermit.confirm.submit" />
        </Box>
        <Box>
            <FormattedMessage id="common.questionConfirm" />
        </Box>
    </>
);
const activitySubmitConfirmation: (data: any) => React.ReactNode = () => (
    <>
        <Box>
            <FormattedMessage id="activity.confirm.submit" />
        </Box>
        <Box>
            <FormattedMessage id="common.questionConfirm" />
        </Box>
    </>
);
const vicSubmitConfirmation: (data: any) => React.ReactNode = () => (
    <>
        <Box>
            <FormattedMessage id="vic.confirm.submit" />
        </Box>
        <Box>
            <FormattedMessage id="common.questionConfirm" />
        </Box>
    </>
);

const dimrSubmitConfirmation = (data: any, context: any): React.ReactNode => (
    <>
        {context != null &&
            !!(context as Partial<DimrVersionContextReadDTO>).canSkipApprovals &&
            ((data as Partial<DimrVersionReadDTO>).forceReapproval ? (
                <Alert severity="warning" sx={{ mb: 1 }}>
                    <FormattedMessage id="dimr.warning.yesForceReapproval" />
                </Alert>
            ) : (
                <Alert severity="info" sx={{ mb: 1 }}>
                    <FormattedMessage id="dimr.warning.noForceReapproval" />
                </Alert>
            ))}
        <Box>
            <FormattedMessage id="dimr.confirm.submit" />
        </Box>
        <Box>
            <FormattedMessage id="common.questionConfirm" />
        </Box>
    </>
);

const lockoutSubmitConfirmation = (data: any): React.ReactNode => (
    <>
        <Box>
            <FormattedMessage id="lockout.confirm.submit" />
        </Box>
        <Box>
            <FormattedMessage id="common.questionConfirm" />
        </Box>
    </>
);

const getSubmitAction = (
    submitDocument: (args: { id: number; data: any }) => Promise<any>,
    validateDocument: (args: { id: number; data: any }) => Promise<any>,
    getConfirmationContent: (data: any, context: any) => React.ReactNode
): TaskActionConfigurationRecord => ({
    [TaskAction.SUBMITTED]: {
        mutation: ({ id, data }) => submitDocument({ id, data: data as any }),
        validation: ({ id, data }) => validateDocument({ id, data: data as any }),
        icon: <Send />,
        buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.submit' }),
        confirmationContent: ({ data, context }) => getConfirmationContent(data, context),
        ignoreBlur: true,
    },
});

export const TASK_CONFIGURATION: Record<TaskType, TaskConfiguration> = {
    [TaskType.DIMR_FEEDBACK]: {
        icon: <AddComment />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.requestFeedback.instruction' }),
        actions: {
            [DocumentType.DIMR]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task }) => requestFeedback({ id: task.documentId }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.perform' }),
                    confirmationContent: ({ intl }) =>
                        intl.formatMessage({ id: 'document.task.requestFeedback.confirm' }),
                    kind: ActionKind.MUST_DO,
                },
            },
        },
    },
    [TaskType.DIMR_CLOSURE]: {
        icon: <AddComment />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.requestClosure.instruction' }),
        actions: {
            [DocumentType.DIMR]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task }) => requestClosure({ id: task.documentId }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.perform' }),
                    confirmationContent: ({ intl }) =>
                        intl.formatMessage({ id: 'document.task.requestClosure.confirm' }),
                    kind: ActionKind.MUST_DO,
                },
            },
        },
    },
    [TaskType.DIMR_NEW_VERSION]: {
        icon: <AddCircle />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.dimrNewVersion.instruction' }),
        actions: {
            [DocumentType.DIMR]: {
                [TaskAction.NEW_VERSION]: [
                    {
                        mutation: async ({ task, navigate }) => {
                            const result = await createNewDimrVersion({ dimrVersionId: task.documentId });
                            navigate(
                                getRoute({
                                    path: ROUTES.dimr.view,
                                    params: { id: result.dimrMasterId, version: result.versionNumber },
                                })
                            );
                            return result;
                        },
                        icon: <AddCircle />,
                        buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.newVersion' }),
                        confirmationContent: ({ intl }) =>
                            intl.formatMessage({ id: 'document.task.dimrNewVersion.confirm' }),
                        kind: ActionKind.CAN_DO_SOFT,
                    },
                ],
            },
        },
    },
    [TaskType.ADD_IS37_SENSORS]: {
        icon: <Sensors />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.setSensors.instruction' }),
        actions: {
            [DocumentType.IS37]: {
                [TaskAction.COMPLETE]: [
                    {
                        mutation: ({ task, data }) =>
                            setSensorsTask({
                                id: task.documentId,
                                data: mapIs37ToUpdateDTO(data, false),
                            }),
                        icon: <CheckCircle />,
                        buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.perform' }),
                        confirmationContent: ({ intl }) =>
                            intl.formatMessage({ id: 'document.task.setSensors.confirm' }),
                    },
                ],
                [TaskAction.NO_SENSORS]: [
                    {
                        mutation: ({ task, data }) =>
                            noSensorsTask({
                                id: task.documentId,
                                data: mapIs37ToUpdateDTO(data, false),
                            }),
                        icon: <RemoveCircle />,
                        secondary: true,
                        buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.noSensors' }),
                        confirmationContent: ({ intl }) =>
                            intl.formatMessage({ id: 'document.task.noSensors.confirm' }),
                        kind: ActionKind.CAN_DO_HARD,
                    },
                ],
            },
        },
    },
    [TaskType.CHANGE_ALARM_TYPE]: {
        icon: <Sensors />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.reassign.instruction' }),
        actions: {
            [DocumentType.IS37]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task, data, comment }) =>
                        changeAlarmTypeTask({
                            id: task.documentId,
                            data: { data: mapIs37ToUpdateDTO(data, false), comment },
                        }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.perform' }),
                    confirmationContent: ({ intl }) => intl.formatMessage({ id: 'document.task.reassign.confirm' }),
                    comment: true,
                    kind: ActionKind.CAN_DO_SOFT,
                },
            },
        },
    },
    [TaskType.LOCK_LOCKOUT]: {
        icon: <LockOutlined />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.lockLockout.instruction' }),
        actions: {
            [DocumentType.LOK]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task, data, comment }) =>
                        lockLockoutTask({
                            id: task.documentId,
                            data: { data: mapLockoutToUpdateDTO(data, false), comment },
                        }),
                    validation: ({ task, data }) =>
                        validateLock({ id: task.documentId, data: mapLockoutToUpdateDTO(data) }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.lock' }),
                    confirmationContent: ({ intl }) => intl.formatMessage({ id: 'document.task.lockLockout.confirm' }),
                    kind: ActionKind.MUST_DO,
                },
            },
        },
    },
    [TaskType.UNLOCK_LOCKOUT]: {
        icon: <LockOpen />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.unlockLockout.instruction' }),
        actions: {
            [DocumentType.LOK]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task, data, comment }) =>
                        unlockLockoutTask({
                            id: task.documentId,
                            data: { data: mapLockoutToUpdateDTO(data, false), comment },
                        }),
                    validation: ({ task, data }) =>
                        validateUnlock({ id: task.documentId, data: mapLockoutToUpdateDTO(data) }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.unlock' }),
                    confirmationContent: ({ intl }) =>
                        intl.formatMessage({ id: 'document.task.unlockLockout.confirm' }),
                    kind: ActionKind.MUST_DO,
                },
            },
        },
    },
    [TaskType.COMPLETE_WORK]: {
        icon: <AssignmentTurnedIn />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.lockoutCompleteWork.instruction' }),
        actions: {
            [DocumentType.LOK]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task, data, comment }) =>
                        completeWorkTask({
                            id: task.documentId,
                            data: { data: mapLockoutToUpdateDTO(data, false), comment },
                        }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.completeWork' }),
                    confirmationContent: ({ intl }) =>
                        intl.formatMessage({ id: 'document.task.lockoutCompleteWork.confirm' }),
                    kind: ActionKind.MUST_DO,
                },
            },
        },
    },
    [TaskType.PROVIDE_YELLOW_PAPER]: {
        icon: <UploadFile />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.lockoutProvideYellowPaper.instruction' }),
        actions: {
            [DocumentType.LOK]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task, data, comment }) =>
                        provideYellowPaperTask({
                            id: task.documentId,
                            data: { data: mapLockoutToUpdateDTO(data, false), comment },
                        }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.provideYellowPaper' }),
                    confirmationContent: ({ intl }) =>
                        intl.formatMessage({ id: 'document.task.lockoutProvideYellowPaper.confirm' }),
                    kind: ActionKind.MUST_DO,
                },
            },
        },
    },
    [TaskType.CONFIRM_RECEPTION_YELLOW_PAPER]: {
        icon: <TaskOutlined />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.lockoutReceiveYellowPaper.instruction' }),
        actions: {
            [DocumentType.LOK]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task, data, comment }) =>
                        receiveYellowPaperTask({
                            id: task.documentId,
                            data: { data: mapLockoutToUpdateDTO(data, false), comment },
                        }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) =>
                        intl.formatMessage({ id: 'document.action.confirmReceptionYellowPaper' }),
                    confirmationContent: ({ intl }) =>
                        intl.formatMessage({ id: 'document.task.lockoutReceiveYellowPaper.confirm' }),
                    kind: ActionKind.MUST_DO,
                },
            },
        },
    },
    [TaskType.RETURN_YELLOW_PAPER]: {
        icon: <UploadFile />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.lockoutReturnYellowPaper.instruction' }),
        actions: {
            [DocumentType.LOK]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task, data, comment }) =>
                        returnYellowPaperTask({
                            id: task.documentId,
                            data: { data: mapLockoutToUpdateDTO(data, false), comment },
                        }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.returnYellowPaper' }),
                    confirmationContent: ({ intl }) =>
                        intl.formatMessage({ id: 'document.task.lockoutReturnYellowPaper.confirm' }),
                    kind: ActionKind.MUST_DO,
                },
            },
        },
    },
    [TaskType.CONFIRM_RETURN_YELLOW_PAPER]: {
        icon: <TaskOutlined />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.lockoutConfirmReturnYellowPaper.instruction' }),
        actions: {
            [DocumentType.LOK]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task, data, comment }) =>
                        confirmReceptionOfYellowPaperTask({
                            id: task.documentId,
                            data: { data: mapLockoutToUpdateDTO(data, false), comment },
                        }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.confirmReturnYellowPaper' }),
                    confirmationContent: ({ intl }) =>
                        intl.formatMessage({ id: 'document.task.lockoutConfirmReturnYellowPaper.confirm' }),
                    kind: ActionKind.MUST_DO,
                },
            },
        },
    },
    [TaskType.SCHEDULE_RECOMMISSIONING]: {
        instruction: (intl) => intl.formatMessage({ id: 'document.task.scheduleRecommissioning.instruction' }),
        actions: {
            [DocumentType.IS37]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task, data }) =>
                        scheduleRecommissioning({
                            data: mapIs37ToUpdateDTO(data, false),
                            is37id: String(task.documentId),
                        }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.perform' }),
                    confirmationContent: ({ intl }) =>
                        intl.formatMessage({ id: 'document.task.scheduleRecommissioning.confirm' }),
                },
            },
        },
    },
    [TaskType.RECOMMISSION_SENSORS]: {
        icon: <Leak />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.recommissionSensors.instruction' }),
        actions: {
            [DocumentType.IS37]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task, data }) =>
                        recommissionTask({
                            data: mapIs37ToUpdateDTO(data, false),
                            is37id: String(task.documentId),
                        }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.perform' }),
                    confirmationContent: ({ intl }) =>
                        intl.formatMessage({ id: 'document.task.recommissionSensors.confirm' }),
                },
            },
        },
    },
    [TaskType.DISABLE_SENSORS]: {
        icon: <SensorsOff />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.disableSensors.instruction' }),
        actions: {
            [DocumentType.IS37]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task, data }) =>
                        disableTask({
                            data: mapIs37ToUpdateDTO(data, false),
                            is37id: String(task.documentId),
                        }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.perform' }),
                    confirmationContent: ({ intl }) =>
                        intl.formatMessage({ id: 'document.task.disableSensors.confirm' }),
                },
            },
        },
    },
    [TaskType.CANCEL]: {
        icon: <DoDisturb />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.cancel.instruction' }),
        actions: {
            [DocumentType.IS37]: getCancelAction(cancelIs37),
            [DocumentType.FP]: getCancelAction(cancelFirePermit),
            [DocumentType.NDC]: getCancelAction(cancelNdc),
            [DocumentType.DIMR]: getCancelAction(cancelDimr),
            [DocumentType.ACTIVITY]: getCancelAction(cancelActivity),
            [DocumentType.VIC]: getCancelAction(cancelVic),
            [DocumentType.LOK]: getCancelAction(cancelLockout),
        },
    },
    [TaskType.CREATE_IS37_CHANGE_REQUEST]: {
        icon: <ManageHistory />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.createIs37ChangeRequest.instruction' }),
        actions: {
            [DocumentType.IS37]: {},
        },
    },
    [TaskType.FINISH]: {
        icon: <StopCircle />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.finish.instruction' }),
        all: true,
        actions: {
            [TaskAction.FINISH]: {
                mutation: ({ data, comment }) => finishNdc({ id: (data as any).id, comment: { comment } }),
                icon: <StopCircle />,
                buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.finish' }),
                confirmationContent: ({ intl }) => intl.formatMessage({ id: 'document.task.finish.confirm' }),
                comment: true,
                kind: ActionKind.CAN_DO_SOFT,
            },
        },
    },
    [TaskType.CANCEL_CHANGE_REQUEST]: {
        icon: <DoDisturb />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.cancelIs37ChangeRequest.instruction' }),
        actions: {
            [DocumentType.IS37]: {
                [TaskAction.CANCEL]: {
                    mutation: ({ data, comment }) =>
                        cancelIs37ChangeRequest({ id: (data as any).id, comment: { comment } }),
                    icon: <Cancel />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.cancel' }),
                    confirmationContent: ({ intl }) => intl.formatMessage({ id: 'is37.cancelChangeRequest.confirm' }),
                    comment: true,
                    kind: ActionKind.CAN_DO_SOFT,
                },
            },
        },
    },
    [TaskType.SIGNATURE]: {
        icon: <DriveFileRenameOutline />,
        instruction: (intl) => intl.formatMessage({ id: 'document.signature.instruction' }),
        actions: {
            [DocumentType.IS37]: getApproveRejectAction(),
            [DocumentType.IS37_CR]: getApproveRejectAction(),
            [DocumentType.FP]: getApproveRejectAction(),
            [DocumentType.NDC]: getApproveRejectAction(),
            [DocumentType.DIMR]: getApproveRejectAction(),
            [DocumentType.ACTIVITY]: getApproveRejectAction(),
            [DocumentType.VIC]: getApproveRejectAction(),
            [DocumentType.LOK]: getApproveRejectAction({ lockout: true }),
        },
    },
    [TaskType.SUBMIT]: {
        icon: <Send />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.submit.instruction' }),
        actions: {
            [DocumentType.NDC]: getSubmitAction(
                ({ id, data }) => submitNdc({ id, data: mapNdcToUpdateDTO(data) }),
                ({ id, data }) => validateSubmitNdc({ id, data: mapNdcToUpdateDTO(data) }),
                ndcSubmitConfirmation
            ),
            [DocumentType.IS37]: getSubmitAction(submitIs37, validateSubmitIs37, is37SubmitConfirmation),
            [DocumentType.FP]: getSubmitAction(
                submitFirePermit,
                validateSubmitFirePermit,
                firePermitSubmitConfirmation
            ),
            [DocumentType.DIMR]: getSubmitAction(submitDimr, validateSubmitDimr, dimrSubmitConfirmation),
            [DocumentType.ACTIVITY]: getSubmitAction(
                submitActivity,
                validateSubmitActivity,
                activitySubmitConfirmation
            ),
            [DocumentType.VIC]: getSubmitAction(submitVic, validateSubmitVic, vicSubmitConfirmation),
            [DocumentType.LOK]: getSubmitAction(submitLockout, validateSubmitLockout, lockoutSubmitConfirmation),
        },
    },
    [TaskType.EDIT]: {
        icon: <Edit />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.edit.instruction' }),
        important: ({ code }) => code !== TaskCode.DIMR_RP_VALIDATION_EDIT && code !== TaskCode.DIMR_APPROVAL_EDIT, // This is the only task that is generally considered important, despite no completion action
        actions: {
            [DocumentType.ACTIVITY]: {},
        },
    },
    [TaskType.EDIT_SENSORS]: {
        icon: <Edit />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.editSensors.instruction' }),
        actions: {
            [DocumentType.IS37]: {},
        },
    },
    [TaskType.ATTACH_IS37]: {
        icon: <AttachFile />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.attachIs37.instruction' }),
        actions: {
            [DocumentType.FP]: {},
            [DocumentType.ACTIVITY]: {
                [TaskAction.ADD]: {
                    mutation: ({ task, data }) => closeFirePermit({ id: task.documentId, data: data as any }),
                    icon: <AttachFile />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.attach' }),
                    confirmationContent: ({ intl }) =>
                        intl.formatMessage({ id: 'activity.task.attach_safety_document' }),
                },
            },
        },
    },
    [TaskType.FP_CLOSEOUT]: {
        icon: <CancelPresentation />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.fpCloseout.instruction' }),
        actions: {
            [DocumentType.FP]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task, data }) => closeFirePermit({ id: task.documentId, data: data as any }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.perform' }),
                    confirmationContent: ({ intl }) => intl.formatMessage({ id: 'document.task.fpCloseout.confirm' }),
                },
            },
        },
    },
    [TaskType.ADD_COMMENT]: {
        icon: <AddComment />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.addComment.instruction' }),
        actions: {},
    },
    // TODO configure the buttons properly
    [TaskType.ACTIVITY_INTERRUPT]: {
        icon: <PauseCircle />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.activityInterrupt.instruction' }),
        actions: {
            [DocumentType.ACTIVITY]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task }) => interruptActivity({ id: task.documentId }),
                    icon: <StopCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.interrupt' }),
                    confirmationContent: ({ intl }) =>
                        intl.formatMessage({ id: 'document.task.activityInterrupt.confirm' }),
                },
            },
        },
    },
    [TaskType.ACTIVITY_RESUME]: {
        icon: <PlayCircle />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.activityResume.instruction' }),
        actions: {
            [DocumentType.ACTIVITY]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task }) => resumeActivity({ id: task.documentId }),
                    icon: <AccountReactivate />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.resume' }),
                    confirmationContent: ({ intl }) =>
                        intl.formatMessage({ id: 'document.task.activityResume.confirm' }),
                },
            },
        },
    },
    [TaskType.CLOSE]: {
        icon: <CancelPresentation />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.close.instruction' }),
        actions: {
            [DocumentType.ACTIVITY]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task }) => closeActivity({ id: task.documentId }),
                    icon: <Close />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.close' }),
                    confirmationContent: ({ intl }) => intl.formatMessage({ id: 'document.task.close.confirm' }),
                },
            },
        },
    },
    [TaskType.CREATE_CHANGE_REQUEST]: {
        icon: <AddCircle />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.createActivityChangeRequest.instruction' }),
        actions: {
            [DocumentType.ACTIVITY]: {},
        },
    },
    [TaskType.FACILITY_COORDINATOR_SCHEDULE]: {
        icon: <ScheduleSend />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.activitySchedule.instruction' }),
        actions: {
            [DocumentType.ACTIVITY]: {
                [TaskAction.SCHEDULED]: {
                    mutation: ({ task, data }) =>
                        facilityCoordinatorSchedule({
                            id: task.documentId,
                        }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.schedule' }),
                    confirmationContent: ({ intl }) =>
                        intl.formatMessage({ id: 'document.task.activitySchedule.confirm' }),
                },
            },
        },
    },
    [TaskType.ADD_SIGNATURE_TASK]: {
        icon: <AddTask />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.addSignature.instruction' }),
        actions: {
            [DocumentType.ACTIVITY]: {
                [TaskAction.ADD]: {
                    mutation: ({ task }) => addSignatureTask({ id: task.documentId }),
                    icon: <AddComment />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.addSignature' }),
                    confirmationContent: ({ intl }) => intl.formatMessage({ id: 'document.task.addSignature.confirm' }),
                },
            },
        },
    },
};

interface TaskConfigurationAllDocuments {
    all: true;
    actions: TaskActionConfigurationRecord;
}

interface TaskConfigurationPerDocument {
    all?: false | undefined;
    actions: Partial<Record<DocumentType, TaskActionConfigurationRecord>>;
}

type TaskConfiguration = (TaskConfigurationAllDocuments | TaskConfigurationPerDocument) & {
    icon?: React.ReactNode;
    instruction: (intl: IntlShape) => string;
    /**
     * Forces this task to be considered important. By default, it will be deduced from {@link TaskActionConfiguration#kind}.
     */
    important?: (task: TaskDTO) => boolean;
};

// We allow multiple buttons for the same completion code
// First such use case is IMPACT-1607, but this will probably turn into an antipattern
type TaskActionConfigurationRecord = Partial<Record<TaskAction, TaskActionConfiguration | TaskActionConfiguration[]>>;

type TaskActionCallback = (args: {
    id: number;
    task: TaskDTO;
    data: unknown;
    comment: string | null;
    navigate: NavigateFunction;
}) => Promise<unknown>;

export interface TaskActionConfiguration {
    mutation: TaskActionCallback;
    validation?: TaskActionCallback;
    secondary?: boolean;
    icon: React.ReactNode;
    buttonContent: (args: { intl: IntlShape }) => string;
    confirmationContent?: (args: { data: any; context: any; intl: IntlShape }) => React.ReactNode;
    comment?: boolean;
    /**
     * The importance of the action, see {@link ActionKind}. This is used to change the severity of the task card.
     */
    kind?: ActionKind;
    /**
     * If the form can be saved *and* {@link mutation} endpoint does not accept a payload, then this field should be set to <code>true</code>.
     */
    noSave?: boolean;
    ignoreBlur?: boolean;
}
