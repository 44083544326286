import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Fragment, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import z from 'zod';
import { getActivity, getActivityValidationRules } from '../../api/activities-nim';
import { DocumentType } from '../../api/dto';
import { ImpactHelmet } from '../../components/ImpactHelmet';
import { defaultUrlParamsSchema, withUrlParams } from '../../components/wrappers/withUrlParams';
import { ActivityForm } from '../../forms/documents/ActivityForm';
import { ActivityPageVariant } from './ActivityPageVariant';

const schema = z.union([
    defaultUrlParamsSchema.extend({
        variant: z.literal(ActivityPageVariant.CREATE),
    }),
    defaultUrlParamsSchema.extend({
        variant: z.union([z.literal(ActivityPageVariant.CLONE), z.literal(ActivityPageVariant.READ)]),
        pathParams: z.object({
            id: z.coerce.number(),
        }),
    }),
]);

const ActivityPageContent: React.FC<z.infer<typeof schema>> = ({ variant, pathParams }) => {
    const intl = useIntl();

    const {
        data: activityData,
        isLoading: isActivityDataLoading,
        isError: activityDataFailed,
        remove,
        refetch,
    } = useQuery(
        [DocumentType.ACTIVITY, variant !== ActivityPageVariant.CREATE ? pathParams.id : undefined],
        () => {
            if (variant === ActivityPageVariant.CREATE) {
                throw new Error();
            }
            return getActivity({ id: String(pathParams.id) });
        },
        {
            enabled: variant !== ActivityPageVariant.CREATE,
        }
    );

    const refreshData = useCallback(() => {
        remove();
        return refetch();
    }, [remove, refetch]);

    const {
        data: rulesData,
        isLoading: isRulesDataLoading,
        isError: rulesDataFailed,
    } = useQuery(
        [DocumentType.ACTIVITY, variant === ActivityPageVariant.READ ? pathParams.id : undefined, 'rules'] as const,
        () => getActivityValidationRules({ id: variant === ActivityPageVariant.READ ? pathParams.id : undefined })
    );

    if ((isActivityDataLoading && variant !== ActivityPageVariant.CREATE) || isRulesDataLoading) {
        return <LinearProgress />;
    } else if (activityDataFailed || rulesDataFailed) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                <FormattedMessage id="activity.error" />
                <ImpactHelmet title={intl.formatMessage({ id: 'title.error' })} />
            </Alert>
        );
    } else if (
        variant === ActivityPageVariant.CREATE ||
        ((variant === ActivityPageVariant.READ || variant === ActivityPageVariant.CLONE) && activityData)
    ) {
        return (
            <ActivityForm
                data={variant === ActivityPageVariant.CREATE ? { variant } : { variant, activityData: activityData! }}
                refreshData={refreshData}
                rules={rulesData}
            />
        );
    } else {
        return <Fragment />;
    }
};

export const ActivityPage = withUrlParams(ActivityPageContent, schema);
