import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Fragment, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import z from 'zod';
import { getActivity } from '../../../api/activities';
import { getVic, getVicRiskTypes, getVicValidationRules } from '../../../api/documents/vics';
import { DocumentType } from '../../../api/dto';
import { ImpactHelmet } from '../../../components/ImpactHelmet';
import { defaultUrlParamsSchema, withUrlParams } from '../../../components/wrappers/withUrlParams';
import { VicForm } from '../../../forms/documents/VicForm';
import { VicPageVariant } from './VicPageVariant';

const schema = z.union([
    defaultUrlParamsSchema.extend({
        variant: z.literal(VicPageVariant.CREATE),
        queryParams: z.object({
            activity: z.coerce.number(),
        }),
    }),
    defaultUrlParamsSchema.extend({
        variant: z.union([z.literal(VicPageVariant.CLONE), z.literal(VicPageVariant.READ)]),
        pathParams: z.object({
            id: z.coerce.number(),
        }),
    }),
]);

const VicPageContent: React.FC<z.infer<typeof schema>> = ({ variant, pathParams, queryParams }) => {
    const intl = useIntl();

    const {
        data: vicData,
        isLoading: vicDataLoading,
        isError: vicDataFailed,
        remove,
        refetch,
    } = useQuery(
        [DocumentType.VIC, variant !== VicPageVariant.CREATE ? pathParams.id : undefined],
        () => {
            if (variant === VicPageVariant.CREATE) {
                throw new Error();
            }
            return getVic({ id: String(pathParams.id) });
        },
        {
            enabled: variant !== VicPageVariant.CREATE,
        }
    );

    const {
        data: riskTypes,
        isLoading: riskTypesLoading,
        isError: riskTypesFailed,
    } = useQuery(['riskTypes'], () => getVicRiskTypes());

    const {
        data: activityData,
        isLoading: isActivityDataLoading,
        isError: activityDataFailed,
    } = useQuery(
        ['activity', variant === VicPageVariant.CREATE ? queryParams.activity : undefined],
        () => {
            if (variant !== VicPageVariant.CREATE) {
                throw new Error();
            }
            return getActivity({ id: String(queryParams.activity) });
        },
        {
            enabled: variant === VicPageVariant.CREATE,
        }
    );

    const refreshData = useCallback(() => {
        remove();
        return refetch();
    }, [remove, refetch]);

    const {
        data: rulesData,
        isLoading: isRulesDataLoading,
        isError: rulesDataFailed,
    } = useQuery([DocumentType.VIC, variant === VicPageVariant.READ ? pathParams.id : undefined, 'rules'], () =>
        getVicValidationRules({ id: variant === VicPageVariant.READ ? pathParams.id : undefined })
    );

    if (
        (vicDataLoading && variant !== VicPageVariant.CREATE) ||
        (isActivityDataLoading && variant === VicPageVariant.CREATE) ||
        isRulesDataLoading ||
        riskTypesLoading
    ) {
        return (
            <>
                <LinearProgress />
                <ImpactHelmet documentType={DocumentType.VIC} title={intl.formatMessage({ id: 'title.loading' })} />
            </>
        );
    } else if (vicDataFailed || activityDataFailed || rulesDataFailed || riskTypesFailed) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                <FormattedMessage id="vic.error.document" />
                <ImpactHelmet documentType={DocumentType.VIC} title={intl.formatMessage({ id: 'title.error' })} />
            </Alert>
        );
    } else if (
        (variant === VicPageVariant.CREATE && activityData) ||
        (variant === VicPageVariant.READ && vicData) ||
        (variant === VicPageVariant.CLONE && vicData)
    ) {
        return (
            <VicForm
                data={
                    variant === VicPageVariant.CREATE
                        ? { variant, activityData: activityData! }
                        : { variant, vicData: vicData! }
                }
                rules={rulesData}
                refreshData={refreshData}
                riskTypes={riskTypes}
            />
        );
    } else {
        return <Fragment />;
    }
};

export const VicPage = withUrlParams(VicPageContent, schema);
