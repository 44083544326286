/* tslint:disable */
/* eslint-disable */

export interface AccessPointReadDTO {
    id: number;
    name: string;
}

export interface AccessPointReferenceDTO {
    id: number | null;
}

export interface ActivityChangeRequestReadDTO {
    newGrantedWorkingTime: ListOfValuesReadDTO | null;
    newGrantedWorkingTimeEnd: string | null;
    newGrantedWorkingTimeStart: string | null;
    newGrantedWorkingTimeText: string | null;
    newScheduledEndDate: string;
    newScheduledStartDate: string;
    requestorComments: string;
    status: ListOfValuesReadDTO;
}

export interface ActivityClusterDTO {
    createdBy: string | null;
    editedBy: string | null;
    id: string;
}

export interface ActivityClusterFacilityDTO {
    cluster: ActivityClusterNimReadDTO | null;
    facility: ListOfValuesReadDTO | null;
}

export interface ActivityClusterNimReadDTO {
    facility: ListOfValuesReadDTO;
    id: number | null;
    radiationDoseResponsible: PersonReadDTO | null;
    responsible: PersonReadDTO;
    status: ListOfValuesReadDTO;
    title: string;
}

export interface ActivityCommentDTO {
    activity: string | null;
    content: string | null;
    createdBy: PersonReadDTO | null;
    createdDate: string | null;
    editedBy: PersonReadDTO | null;
    editedDate: string | null;
    id: string;
}

export interface ActivityContextReadDTO {
    currentStatus: ListOfValuesReadDTO | null;
    currentUser: PersonReadDTO | null;
    documentAction: DocumentActionDTO | null;
    task: TaskType | null;
}

export interface ActivityDTO {
    activityHazards: HazardActivityDTO[];
    createdBy: PersonReadDTO;
    description: string | null;
    facility: ListOfValuesReadDTO | null;
    how: HowDTO | null;
    id: number;
    isActive: boolean | null;
    locationHazards: HazardLocationDTO[];
    priority: PriorityDTO | null;
    responsible: PersonReadDTO | null;
    rpAssessment: RPAssessmentDTO | null;
    safety: SafetyDTO | null;
    status: ListOfValuesReadDTO<ActivityStatusCode>;
    system: SystemDTO | null;
    tests: TestsDTO | null;
    title: string;
    type: ListOfValuesReadDTO | null;
    version: number | null;
    when: WhenDTO | null;
    where: WhereDTO | null;
    who: WhoDTO | null;
}

export interface ActivityGridReadDTO extends RecordDTO {
    accessEnd: string | null;
    accessStart: string | null;
    activityChangeRequests: ActivityChangeRequestReadDTO[];
    activityCluster: ActivityClusterNimReadDTO | null;
    activityHazards: HazardActivityReadDTO[];
    activityLocations: ActivityLocationNimReadDTO[];
    activityParticipants: ActivityParticipantNimReadDTO[];
    activityResponsible: PersonReadDTO | null;
    activityTags: TagActivityReadDTO[];
    adamsAccessLogs: AdamsAccessLogsReadDTO[];
    alarmTriggered: AlarmTriggeredReadDTO[];
    baselineEnd: string | null;
    baselineStart: string | null;
    coactivityHazards: HazardCoactivityReadDTO[];
    constraints: string | null;
    contactPhone: string | null;
    contaminatingWorks: boolean | null;
    description: string | null;
    duration: number | null;
    durationType: ListOfValuesReadDTO;
    earliestStart: string | null;
    editedBy: PersonReadDTO | null;
    editedDate: string | null;
    facility: ListOfValuesReadDTO | null;
    firePermits: FirePermitGridReadDTO[];
    id: number;
    impactAndTestQuestions: ImpactAndTestQuestionReadDTO[];
    impactedFacilities: ListOfValuesReadDTO[];
    interventionPeriod: InterventionPeriodNimReadDTO | null;
    is37s: IS37GridReadDTO[];
    lastModifiedBy: PersonReadDTO | null;
    lastModifiedDate: string | null;
    latestEnd: string | null;
    locationHazards: HazardLocationReadDTO[];
    materialResource: string | null;
    maxIndividualWorkingTime: number | null;
    maxNbParticipants: number | null;
    modusOperandi: string | null;
    notRequiresSafetyApproval: boolean | null;
    onSiteActivityComments: OnSiteActivityCommentReadDTO[];
    priority: ListOfValuesReadDTO | null;
    proposedDate: string | null;
    proposedInterventionPeriod: InterventionPeriodNimReadDTO | null;
    radiographyAffectedZoneId: number | null;
    radiographyDeviceType: ListOfValuesReadDTO | null;
    radiographySourceId: number | null;
    radiographyXraySource: string | null;
    rpAssessments: RpAssessmentGridReadDTO[];
    scheduledEnd: string | null;
    scheduledStart: string | null;
    shortTermWorkers: boolean | null;
    status: ListOfValuesReadDTO<ActivityStatusCode>;
    system: SystemBsSrcReadDTO | null;
    title: string;
    totalCollectiveWorkingTime: number | null;
    type: ListOfValuesReadDTO | null;
    warningDuration: number | null;
    warningDurationType: ListOfValuesReadDTO | null;
    waste: string | null;
    whenComments: string | null;
    whereComments: string | null;
    whoComments: string | null;
    workingTimeAvail: ListOfValuesReadDTO | null;
    workingTimeAvailEnd: string | null;
    workingTimeAvailStart: string | null;
    workingTimeAvailTxt: string | null;
    workingTimeGrtdEnd: string | null;
    workingTimeGrtdStart: string | null;
    workingTimeGrtdTxt: string | null;
    workingTimeGtd: ListOfValuesReadDTO | null;
}

export interface ActivityHazardDTO {
    comment: string | null;
    type: ListOfValuesReadDTO | null;
}

export interface ActivityHazardUpdateDTO {
    commentHazardType: string | null;
    compensatoryMeasures: CompensatoryMeasureUpdateDTO[];
    type: ListOfValuesReferenceDTO;
}

export interface ActivityLocationDTO {
    activityId: number | null;
    coordinateMn95east: number | null;
    coordinateMn95north: number | null;
    location: LocationDTO;
}

export interface ActivityLocationNimReadDTO {
    accessPoints: AccessPointReadDTO[];
    coordinateMn95east: number | null;
    coordinateMn95north: number | null;
    location: LocationDTO;
}

export interface ActivityLocationNimUpdateDTO {
    accessPoints: AccessPointReferenceDTO[];
    coordinateMn95east: number | null;
    coordinateMn95north: number | null;
    location: LocationReferenceDTO | null;
}

export interface ActivityNimReadDTO extends RecordDTO {
    accessEnd: string | null;
    accessStart: string | null;
    activityChangeRequests: ActivityChangeRequestReadDTO[];
    activityCluster: ActivityClusterNimReadDTO | null;
    activityHazards: HazardActivityReadDTO[];
    activityLocations: ActivityLocationNimReadDTO[];
    activityParticipants: ActivityParticipantNimReadDTO[];
    activityResponsible: PersonReadDTO | null;
    activityTags: TagActivityReadDTO[];
    adamsAccessLogs: AdamsAccessLogsReadDTO[];
    alarmTriggered: AlarmTriggeredReadDTO[];
    baselineEnd: string | null;
    baselineStart: string | null;
    coactivityHazards: HazardCoactivityReadDTO[];
    constraints: string | null;
    contactPhone: string | null;
    contaminatingWorks: boolean | null;
    description: string | null;
    duration: number | null;
    durationType: ListOfValuesReadDTO | null;
    earliestStart: string | null;
    editedBy: PersonReadDTO | null;
    editedDate: string | null;
    facility: ListOfValuesReadDTO | null;
    firePermits: FirePermitGridReadDTO[];
    id: number;
    impactAndTestQuestions: ImpactAndTestQuestionReadDTO[];
    impactedFacilities: ListOfValuesReadDTO[];
    interventionPeriod: InterventionPeriodNimReadDTO | null;
    is37s: IS37GridReadDTO[];
    lastModifiedBy: PersonReadDTO | null;
    lastModifiedDate: string | null;
    latestEnd: string | null;
    locationHazards: HazardLocationReadDTO[];
    materialResource: string | null;
    maxIndividualWorkingTime: number | null;
    maxNbParticipants: number | null;
    modusOperandi: string | null;
    notRequiresSafetyApproval: boolean | null;
    onSiteActivityComments: OnSiteActivityCommentReadDTO[];
    priority: ListOfValuesReadDTO | null;
    procedureDocuments: ActivityProcedureDocumentReadDTO[];
    proposedDate: string | null;
    proposedInterventionPeriod: InterventionPeriodNimReadDTO | null;
    radiographyAffectedZoneId: number | null;
    radiographyDeviceType: ListOfValuesReadDTO | null;
    radiographySourceId: number | null;
    radiographyXraySource: string | null;
    rpAssessments: RpAssessmentReadDTO[];
    scheduledEnd: string | null;
    scheduledStart: string | null;
    shortTermWorkers: boolean | null;
    status: ListOfValuesReadDTO<ActivityStatusCode>;
    system: SystemBsSrcReadDTO | null;
    title: string;
    totalCollectiveWorkingTime: number | null;
    type: ListOfValuesReadDTO | null;
    version: number | null;
    warningDuration: number | null;
    warningDurationType: ListOfValuesReadDTO | null;
    waste: string | null;
    whenComments: string | null;
    whereComments: string | null;
    whoComments: string | null;
    workingTimeAvail: ListOfValuesReadDTO | null;
    workingTimeAvailEnd: string | null;
    workingTimeAvailStart: string | null;
    workingTimeAvailTxt: string | null;
    workingTimeGrtdEnd: string | null;
    workingTimeGrtdStart: string | null;
    workingTimeGrtdTxt: string | null;
    workingTimeGtd: ListOfValuesReadDTO | null;
}

export interface ActivityNimUpdateDTO {
    accessEnd: string | null;
    accessStart: string | null;
    activityHazards: ActivityHazardUpdateDTO[];
    activityLocations: ActivityLocationNimUpdateDTO[];
    activityParticipants: ActivityParticipantNimUpdateDTO[];
    activityResponsible: PersonReferenceDTO | null;
    baselineEnd: string | null;
    baselineStart: string | null;
    coactivityHazards: CoactivityHazardUpdateDTO[];
    constraints: string | null;
    contactPhone: string | null;
    contaminatingWorks: boolean | null;
    description: string | null;
    duration: number | null;
    durationType: ListOfValuesReferenceDTO | null;
    earliestStart: string | null;
    facility: ListOfValuesReferenceDTO | null;
    impactedFacilities: ListOfValuesReferenceDTO[];
    interventionPeriod: InterventionPeriodNimReferenceDTO | null;
    latestEnd: string | null;
    locationHazards: LocationHazardUpdateDTO[];
    materialResource: string | null;
    maxIndividualWorkingTime: number | null;
    maxNbParticipants: number | null;
    modusOperandi: string | null;
    notRequiresSafetyApproval: boolean | null;
    priority: ListOfValuesReferenceDTO | null;
    procedureDocuments: ActivityProcedureDocumentUpdateDTO[] | null;
    proposedDate: string | null;
    proposedInterventionPeriod: InterventionPeriodNimReferenceDTO | null;
    radiographyAffectedZoneId: number | null;
    radiographyDeviceType: ListOfValuesReferenceDTO | null;
    radiographySourceId: number | null;
    radiographyXraySource: string | null;
    scheduledEnd: string | null;
    scheduledStart: string | null;
    shortTermWorkers: boolean | null;
    status: ListOfValuesReferenceDTO | null;
    system: SystemBsSrcReferenceDTO | null;
    title: string;
    totalCollectiveWorkingTime: number | null;
    type: ListOfValuesReferenceDTO | null;
    version: number | null;
    warningDuration: number | null;
    warningDurationType: ListOfValuesReferenceDTO | null;
    waste: string | null;
    whenComments: string | null;
    whereComments: string | null;
    whoComments: string | null;
    workingTimeAvail: ListOfValuesReferenceDTO | null;
    workingTimeAvailEnd: string | null;
    workingTimeAvailStart: string | null;
    workingTimeAvailTxt: string | null;
    workingTimeGrtdEnd: string | null;
    workingTimeGrtdStart: string | null;
    workingTimeGrtdTxt: string | null;
    workingTimeGtd: ListOfValuesReferenceDTO | null;
}

export interface ActivityParticipantNimReadDTO {
    cernContractResponsible: PersonReadDTO | null;
    companyContract: ContractNimReadDTO | null;
    egroup: EgroupDTO | null;
    person: PersonReadDTO | null;
    professionalVisitId: number | null;
    role: ListOfValuesReadDTO;
    unknown: string | null;
}

export interface ActivityParticipantNimUpdateDTO {
    companyContract: ContractNimReferenceDTO | null;
    egroup: EgroupReferenceDTO | null;
    person: PersonReferenceDTO | null;
    role: ListOfValuesReferenceDTO;
    unknown: string | null;
}

export interface ActivityParticipantReadDTO {
    cernContractResponsible: PersonReadDTO | null;
    companyContract: ContractsReadDTO | null;
    egroup: EgroupDTO | null;
    person: PersonReadDTO | null;
    professionalVisitId: number | null;
    role: ListOfValuesReadDTO;
}

export interface ActivityProcedureDocumentReadDTO {
    documentNumber: string;
    documentType: ListOfValuesReadDTO;
}

export interface ActivityProcedureDocumentUpdateDTO {
    documentNumber: string | null;
    documentType: ListOfValuesReferenceDTO | null;
}

export interface ActivityReadDTO extends RecordDTO {
    activityHazards: HazardActivityDTO[];
    contaminatingWorks: boolean | null;
    description: string | null;
    duration: number | null;
    durationType: ListOfValuesReadDTO | null;
    facility: ListOfValuesReadDTO | null;
    id: number;
    interventionPeriod: InterventionPeriodNimReadDTO | null;
    isActive: boolean | null;
    locationHazards: HazardLocationDTO[];
    locations: ActivityLocationDTO[];
    maxIndWorkTime: number | null;
    modusOperandi: string | null;
    participants: ActivityParticipantReadDTO[] | null;
    proposedInterventionPeriod: InterventionPeriodNimReadDTO | null;
    proposedStartDate: string | null;
    responsible: PersonReadDTO | null;
    scheduledEnd: string | null;
    scheduledStart: string | null;
    status: ListOfValuesReadDTO<ActivityStatusCode>;
    title: string;
    totalColWorkTime: number | null;
}

export interface ActivityReferenceDTO {
    id: number | null;
}

export interface AdamsAccessLogsReadDTO {
    accessEndDate: string;
    accessEndTime: string;
    accessPoint: AccessPointReadDTO;
    accessPointName: string;
    accessStartDate: string;
    accessStartTime: string;
    egroup: EgroupDTO | null;
    gtdWorkingTime: ListOfValuesReadDTO | null;
    person: PersonReadDTO;
}

export interface AddSignatureTaskUpdateDTO {
    assigneePerson: PersonReferenceDTO | null;
}

export interface AlarmDTO {
}

export interface AlarmTriggeredReadDTO {
    alarmType: ListOfValuesReadDTO;
    id: number | null;
}

export interface ApiErrorDTO<T> {
    errorItems: T[];
    messageEn: string;
    messageFr: string;
    status: HttpStatus;
    statusCode: number;
    timestamp: Date;
}

export interface ApiValidationErrorItemDTO {
    field: string;
    invalidValue: any | null;
    messageEn: string;
    messageFr: string;
}

export interface ApplicationSettingsDTO {
    availableLanguages: ListOfValuesReadDTO[];
    backendVersion: string;
    currentUser: PersonRichReadDTO;
    environment: EnvironmentDTO;
    features: { [P in FeatureFlag]?: boolean };
    frontendSentryDSN: string | null;
    preferredLanguage: ListOfValuesReadDTO<LanguageCode> | null;
    taskTypes: TaskTypeDTO[];
}

export interface AttachmentDTO {
    createdBy: PersonReadDTO | null;
    createdDate: string | null;
    description: string | null;
    editedBy: PersonReadDTO | null;
    editedDate: string | null;
    fileExtension: string | null;
    fileSize: string | null;
    id: number;
    mimeType: string | null;
    originalFileName: string;
    virusChecked: boolean;
}

export interface AttachmentFileDTO {
    fileData: any | null;
    id: number;
    mimeType: string | null;
    originalFileName: string | null;
}

export interface AttachmentUploadDTO {
    description: string | null;
}

export interface AttachmentsIdsDTO {
    attachmentsIds: number[] | null;
}

export interface AttachmentsLinkDTO {
    attachmentsIds: number[] | null;
    documentId: number | null;
    documentType: DocumentType | null;
    taskId: number | null;
}

export interface CoActivityHazardDTO {
    compensatoryMeasure: string | null;
    type: string;
}

export interface CoactivityHazardUpdateDTO {
    compensatoryMeasure: string | null;
    type: string | null;
}

export interface CommentDTO {
    createdBy: PersonRichReadDTO;
    createdDate: string;
    legacyType: CommentLegacyType | null;
    richText: boolean | null;
    task: TaskDTO | null;
    text: string;
}

export interface CommentUpdateDTO {
    comment: string | null;
}

export interface CompensatoryMeasureReadDTO {
    comments: string | null;
    type: CompensatoryMeasureTypeReadDTO;
}

export interface CompensatoryMeasureTypeReadDTO {
    allowSelect: string | null;
    code: string | null;
    defaultSelect: string | null;
    description: string | null;
    hazardCompensatoryMeasuresTypes: HazardCompensatoryMeasuresTypeReadDTO[];
    id: number | null;
    label: string | null;
    message: string | null;
    orderInType: number | null;
}

export interface CompensatoryMeasureTypeReferenceDTO {
    code: string | null;
}

export interface CompensatoryMeasureUpdateDTO {
    comments: string | null;
    type: CompensatoryMeasureTypeReferenceDTO;
}

export interface ContractNimReadDTO {
    cernContractResponsible: PersonReadDTO | null;
    contractNumber: string | null;
    employer: SupplierReadDTO | null;
    id: number;
    type: ContractTypeCode | null;
    visible: boolean | null;
}

export interface ContractNimReferenceDTO {
    id: number | null;
}

export interface ContractsReadDTO {
    cernContractResponsible: PersonReadDTO | null;
    contractNumber: string | null;
    employer: SupplierReadDTO | null;
    type: ContractTypeCode | null;
    visible: boolean | null;
}

export interface CryoEquipmentLocalReadDTO {
    childCount: number | null;
    code: string;
    description: string | null;
    level: number | null;
    location: string | null;
    parent: string | null;
    rootParent: string | null;
    rootParentLocation: string | null;
    site: string | null;
    visible: boolean | null;
}

export interface CsamSensorReadDTO {
    alarmType: string | null;
    building: number | null;
    cernSafetyEquipmentName: string | null;
    csamAlarmType: string | null;
    detectorAddress: number | null;
    detectorGroup: number | null;
    fireBrigadeDescription: string | null;
    floor: string | null;
    id: number;
    room: string | null;
    searchLabel: string | null;
    sensorId: number | null;
    sensorName: string | null;
    zone: number | null;
}

export interface CsamSensorReferenceDTO {
    id: number | null;
}

export interface DataPointReadDTO<T> {
    counts: { [index: string]: number };
    date: string;
}

export interface DimrMasterReadDTO {
    id: number;
}

export interface DimrSuggestedValuesDTO {
    rpo: PersonReadDTO | null;
    rso: PersonReadDTO | null;
    rssoRpe: PersonReadDTO | null;
}

export interface DimrVersionContextReadDTO {
    alaraLevel: ListOfValuesReferenceDTO | null;
    alaraLevelForced: boolean | null;
    calculatedEstColDose: number | null;
    calculatedMaxEstIndDose: number | null;
    canEditPcrNotification: boolean;
    canForceAlaraLevel: boolean;
    canSkipApprovals: boolean;
    defaultMaxEstAirborneContamination: number | null;
    defaultMaxEstSurfaceContamination: number | null;
    documentAction: DocumentActionDTO | null;
    hasOpenSignature: boolean;
    id: number | null;
    isLocationInNonControlledArea: boolean;
    isRoleDgHhseRpgl: boolean;
    isRoleRp: boolean;
    isRoleRpe: boolean;
    isRoleRsoDsoGlimos: boolean;
    maxAvgEstDoseRate: number | null;
    maxEstAirborneCont: number | null;
    maxEstDoseRate: number | null;
    maxEstSurfaceCont: number | null;
    now: string | null;
    rpAssessmentsContexts: RpAssessmentContextDTO[] | null;
    showDimrDoses: boolean;
    taskCode: string | null;
    taskType: TaskType | null;
    user: PersonReadDTO | null;
}

export interface DimrVersionGridActivityReadDTO extends RecordDTO {
    alaraLevel: ListOfValuesReadDTO | null;
    dimrMaster: DimrMasterReadDTO;
    dimrMasterVersionId: number;
    id: number;
    owner: PersonReadDTO | null;
    rpAssessments: RpAssessmentReadDTO[];
    rpo: PersonReadDTO | null;
    rso: PersonReadDTO | null;
    rssoRpe: PersonReadDTO | null;
    status: ListOfValuesReadDTO<DimrStatusCode>;
    title: string | null;
    version: number;
    versionNumber: number;
    workDosePlanningVersions: WdpVersionDimrReadDTO[];
    workEndDate: string | null;
    workStartDate: string | null;
}

export interface DimrVersionGridReadDTO extends RecordDTO {
    activitiesIds: number[];
    alaraLevel: ListOfValuesReadDTO | null;
    dimrMaster: DimrMasterReadDTO;
    dimrMasterVersionId: number;
    id: number;
    owner: PersonReadDTO | null;
    rpo: PersonReadDTO | null;
    rso: PersonReadDTO | null;
    rssoRpe: PersonReadDTO | null;
    status: ListOfValuesReadDTO<DimrStatusCode>;
    title: string | null;
    version: number;
    versionNumber: number;
    workDosePlanningVersions: WdpVersionDimrReadDTO[];
    workEndDate: string | null;
    workStartDate: string | null;
}

export interface DimrVersionNumberReadDTO {
    status: ListOfValuesReadDTO<DimrStatusCode>;
    versionNumber: number;
}

export interface DimrVersionReadDTO extends RecordDTO {
    actualCollectiveDose: number | null;
    actualDosesDate: string | null;
    actualMaxIndividualDose: number | null;
    alaraLevel: ListOfValuesReadDTO | null;
    alaraLevelForced: boolean;
    attachments: AttachmentDTO[];
    bypassWorkflow: boolean | null;
    calculatedEstColDose: number | null;
    calculatedMaxEstIndDose: number | null;
    description: string | null;
    dimrMaster: DimrMasterReadDTO;
    dimrMasterId: number;
    dimrMasterVersionId: number;
    editedBy: PersonReadDTO | null;
    editedDate: string | null;
    estCollectiveDose: number | null;
    forceReapproval: boolean | null;
    id: number;
    mainFacility: ListOfValuesReadDTO | null;
    maxAvgEstDoseRate: number | null;
    maxEstAirborneCont: number | null;
    maxEstDoseRate: number | null;
    maxEstIndividualDose: number | null;
    maxEstSurfaceCont: number | null;
    owner: PersonReadDTO | null;
    pcrNotification: boolean | null;
    riskAnalysisAssessment: string | null;
    rpAssessments: RpAssessmentReadDTO[];
    rpo: PersonReadDTO | null;
    rso: PersonReadDTO | null;
    rssoRpe: PersonReadDTO | null;
    skippedApproval: boolean | null;
    status: ListOfValuesReadDTO<DimrStatusCode>;
    submittedBy: PersonReadDTO | null;
    title: string | null;
    version: number;
    versionNumber: number;
    versionNumbers: DimrVersionNumberReadDTO[];
    workDosePlanningVersions: WdpVersionDimrReadDTO[];
    workEndDate: string | null;
    workStartDate: string | null;
}

export interface DimrVersionReferenceDTO {
    id: number | null;
}

export interface DimrVersionUpdateDTO {
    actualCollectiveDose: number | null;
    actualDosesDate: string | null;
    actualMaxIndividualDose: number | null;
    alaraLevel: ListOfValuesReferenceDTO | null;
    alaraLevelForced: boolean | null;
    bypassWorkflow: boolean | null;
    calculatedEstColDose: number | null;
    calculatedMaxEstIndDose: number | null;
    description: string | null;
    dimrMasterId: number | null;
    estCollectiveDose: number | null;
    forceReapproval: boolean | null;
    mainFacility: ListOfValuesReferenceDTO | null;
    maxAvgEstDoseRate: number | null;
    maxEstAirborneCont: number | null;
    maxEstDoseRate: number | null;
    maxEstIndividualDose: number | null;
    maxEstSurfaceCont: number | null;
    owner: PersonReferenceDTO | null;
    pcrNotification: boolean | null;
    riskAnalysisAssessment: string | null;
    rpAssessments: RpAssessmentUpdateDTO[] | null;
    rpo: PersonReferenceDTO | null;
    rso: PersonReferenceDTO | null;
    rssoRpe: PersonReferenceDTO | null;
    submittedBy: PersonReferenceDTO | null;
    title: string | null;
    version: number | null;
    workDosePlanningVersions: WdpVersionReferenceDTO[] | null;
    workEndDate: string | null;
    workStartDate: string | null;
}

export interface DimrVersionWithStatusReferenceDTO extends HasId {
    status: ListOfValuesReferenceDTO | null;
}

export interface DocumentDTO {
}

export interface DocumentPdfDTO {
    isPdfPreview: boolean;
}

export interface DocumentVersionReferenceDTO {
    masterId: number;
    versionNumber: number;
}

export interface EgroupDTO {
    id: number;
    isActive: boolean;
    isPrivate: boolean;
    name: string | null;
}

export interface EgroupReferenceDTO {
    id: number | null;
}

export interface EgroupWithMembersDTO {
    id: number;
    inFoundation: boolean;
    isPrivate: boolean;
    members: PersonReadDTO[];
    name: string | null;
}

export interface EquipmentReadDTO {
    code: string;
    description: string | null;
}

export interface EquipmentReferenceDTO {
    code: string | null;
}

export interface EquipmentValidationReadDTO {
    invalid: string[] | null;
    valid: EquipmentReadDTO[];
}

export interface FavouriteActivityNimReadDTO {
    person: PersonReadDTO;
}

export interface FavouriteActivityPayloadUpdateDTO {
    isFavourite: boolean;
}

export interface FavouritePersonTagReadDTO {
    person: PersonReadDTO;
    tag: TagReadDTO;
}

export interface FieldAttributesDTO {
    cloneable: number;
    defaultIfEditable: number;
    editable: number;
    mandatoryIfEditable: number;
    newValue: number;
    overwriteIfNotEditable: number;
    rules: number | null;
    validateMandatory: number;
    visibleIfNotEditable: number;
}

export interface FirePermitAvailableActionsReadDTO {
    attachable: boolean;
    canClone: boolean;
}

export interface FirePermitCompanyEmailReadDTO {
    companyEmail: string | null;
}

export interface FirePermitCompanyEmailUpdateDTO {
    companyEmail: string | null;
}

export interface FirePermitContextReadDTO {
    currentStatus: ListOfValuesReadDTO | null;
    currentUser: PersonReadDTO | null;
    documentAction: DocumentActionDTO | null;
    task: TaskType | null;
}

export interface FirePermitGridReadDTO extends RecordDTO {
    activity: ActivityReadDTO;
    endDate: string | null;
    id: number;
    location: LocationDTO | null;
    responsible: PersonReadDTO;
    startDate: string | null;
    status: ListOfValuesReadDTO<FirePermitStatusCode>;
}

export interface FirePermitLocationHazardReadDTO {
    hazard: string | null;
    location: LocationDTO | null;
}

export interface FirePermitLocationHazardUpdateDTO {
    hazard: string | null;
    location: LocationReferenceDTO | null;
}

export interface FirePermitReadDTO extends RecordDTO {
    activity: ActivityReadDTO;
    approversInstructions: string | null;
    availableActions: FirePermitAvailableActionsReadDTO;
    cernEmployee: PersonReadDTO | null;
    company: SupplierReadDTO | null;
    companyFax: string | null;
    endDate: string | null;
    faxLanguage: ListOfValuesReadDTO | null;
    fireDetection: ListOfValuesReadDTO | null;
    fireFighterDevices: string | null;
    fireInspectionPremises: string | null;
    firePermitActivityHazards: ListOfValuesReadDTO[];
    firePermitCompanyEmails: FirePermitCompanyEmailReadDTO[];
    firePermitLocationHazards: FirePermitLocationHazardReadDTO[];
    firePermitWarningDevices: ListOfValuesReadDTO[];
    firePermitWorkTypes: ListOfValuesReadDTO[];
    id: number;
    is37s: IS37GridReadDTO[];
    location: LocationDTO | null;
    locationInformation: string | null;
    modusOperandi: string | null;
    otherWarningDevice: string | null;
    otherWorkType: string | null;
    pdf: DocumentPdfDTO | null;
    protectionMeans: string | null;
    protectionMeansExecutant: ListOfValuesReadDTO | null;
    responsible: PersonReadDTO;
    specialInstructions: string | null;
    startDate: string | null;
    status: ListOfValuesReadDTO<FirePermitStatusCode>;
    terminationDate: string | null;
    version: number | null;
    workToBePerformed: string | null;
    worker: ListOfValuesReadDTO;
}

export interface FirePermitReferenceDTO {
    id: number | null;
}

export interface FirePermitUpdateDTO {
    activity: ActivityReferenceDTO | null;
    approversInstructions: string | null;
    cernEmployee: PersonReferenceDTO | null;
    company: SupplierReferenceDTO | null;
    endDate: string | null;
    faxLanguage: ListOfValuesReferenceDTO | null;
    fireDetection: ListOfValuesReferenceDTO | null;
    fireFighterDevices: string | null;
    fireInspectionPremises: string | null;
    firePermitActivityHazards: ListOfValuesReferenceDTO[] | null;
    firePermitCompanyEmails: FirePermitCompanyEmailUpdateDTO[] | null;
    firePermitLocationHazards: FirePermitLocationHazardUpdateDTO[] | null;
    firePermitWarningDevices: ListOfValuesReferenceDTO[] | null;
    firePermitWorkTypes: ListOfValuesReferenceDTO[] | null;
    is37s: IS37ReferenceDTO[] | null;
    location: LocationReferenceDTO | null;
    locationInformation: string | null;
    modusOperandi: string | null;
    otherWarningDevice: string | null;
    otherWorkType: string | null;
    protectionMeans: string | null;
    protectionMeansExecutant: ListOfValuesReferenceDTO | null;
    responsible: PersonReferenceDTO | null;
    specialInstructions: string | null;
    startDate: string | null;
    terminationDate: string | null;
    version: number | null;
    workToBePerformed: string | null;
    worker: ListOfValuesReferenceDTO | null;
}

export interface HasId {
    id: number | null;
}

export interface HazardActivityDTO {
    commentHazardType: string | null;
    type: ListOfValuesReadDTO;
}

export interface HazardActivityReadDTO {
    commentHazardType: string | null;
    compensatoryMeasures: CompensatoryMeasureReadDTO[];
    type: ListOfValuesReadDTO;
}

export interface HazardCoactivityReadDTO {
    compensatoryMeasure: string;
    type: string | null;
}

export interface HazardCompensatoryMeasuresTypeReadDTO {
    hazardType: ListOfValuesReadDTO;
    id: number;
}

export interface HazardLocationDTO {
    compensatoryMeasure: string | null;
    hazard: string;
    location: LocationDTO;
}

export interface HazardLocationReadDTO {
    compensatoryMeasure: string;
    hazard: string;
    location: LocationDTO;
}

export interface HowDTO {
    constraints: string | null;
    materialResource: string | null;
    modusOperandi: string | null;
    notesDeCoupure: NdcReadDTO[] | null;
    procedureDocuments: DocumentDTO[] | null;
    waste: string | null;
}

export interface I18nMessageDTO {
    english: string | null;
    french: string | null;
}

export interface IS37AlarmTypeDetailsReadDTO {
    alarmType: ListOfValuesReadDTO | null;
    csamType: string | null;
    id: number | null;
    responsibleGroup: OrgUnitReadDTO | null;
    roleTarget: string | null;
}

export interface IS37AlarmTypeReadDTO {
    details: IS37AlarmTypeDetailsReadDTO;
    type: ListOfValuesReadDTO;
}

export interface IS37AlarmTypeReferenceDTO {
    type: ListOfValuesReferenceDTO | null;
}

export interface IS37AvailableActionsReadDTO {
    attachable: boolean;
    canClone: boolean;
}

export interface IS37ChangeRequestContextReadDTO {
    currentIs37StatusCode: string | null;
    currentStartDate: string | null;
    is37DisablingTypeCode: string | null;
    now: string | null;
}

export interface IS37ChangeRequestCreateDTO {
    createdDate: string | null;
    editedDate: string | null;
    is37: IS37ReferenceDTO | null;
    newEndDate: string | null;
    newRecommissioningEnd: string | null;
    newRecommissioningStart: string | null;
    newStartDate: string | null;
    requestorComments: string | null;
    version: number | null;
}

export interface IS37ChangeRequestReadDTO {
    createdBy: PersonReadDTO;
    createdDate: string;
    id: number;
    is37Id: number;
    newEndDate: string | null;
    newRecommissioningEnd: string | null;
    newRecommissioningStart: string | null;
    newStartDate: string | null;
    requestorComments: string;
    status: ListOfValuesReadDTO<Is37ChangeRequestStatusCode>;
    version: number | null;
}

export interface IS37ContextReadDTO {
    canCreateUnscheduledIs37s: boolean;
    currentAlarmType: IS37AlarmTypeReferenceDTO | null;
    currentUser: PersonReadDTO | null;
    documentAction: DocumentActionDTO | null;
    now: string;
    task: TaskType | null;
    taskAction: TaskAction | null;
}

export interface IS37GridReadDTO extends RecordDTO {
    disablementType: ListOfValuesReadDTO<IS37AlarmDisablementTypeCode> | null;
    endDate: string | null;
    id: number;
    responsible: PersonReadDTO;
    startDate: string | null;
    status: ListOfValuesReadDTO<IS37StatusCode>;
}

export interface IS37InterventionReadDTO {
    company: SupplierReadDTO | null;
    doneBy: PersonReadDTO | null;
    inputDate: string | null;
    interventionDate: string | null;
    notes: string | null;
    operator: PersonReadDTO | null;
}

export interface IS37InterventionUpdateDTO {
    company: SupplierReadDTO | null;
    doneBy: PersonReferenceDTO | null;
    inputDate: string | null;
    interventionDate: string | null;
    operator: PersonReferenceDTO | null;
}

export interface IS37ReadDTO extends RecordDTO {
    activity: ActivityReadDTO;
    alarmType: IS37AlarmTypeReadDTO | null;
    availableActions: IS37AvailableActionsReadDTO;
    changeRequests: IS37ChangeRequestReadDTO[];
    compensatoryMeasures: string | null;
    disablement: IS37InterventionReadDTO | null;
    disablementType: ListOfValuesReadDTO<IS37AlarmDisablementTypeCode> | null;
    endDate: string | null;
    firePermit: FirePermitReadDTO | null;
    frsOfficer: PersonReadDTO | null;
    id: number;
    locationDetails: string | null;
    locations: LocationDTO[];
    pdf: DocumentPdfDTO | null;
    recommissioning: IS37InterventionReadDTO | null;
    recommissioningEnd: string | null;
    recommissioningStart: string | null;
    requestedDate: string | null;
    responsible: PersonReadDTO;
    sensors: CsamSensorReadDTO[];
    startDate: string | null;
    status: ListOfValuesReadDTO<IS37StatusCode>;
    tasks: TaskDTO[];
    version: number | null;
    workOrderId: number | null;
    workTypeJustification: string | null;
    workTypes: ListOfValuesReadDTO[];
}

export interface IS37ReferenceDTO {
    id: number | null;
}

export interface IS37UpdateDTO {
    activity: ActivityReferenceDTO | null;
    alarmType: IS37AlarmTypeReferenceDTO | null;
    compensatoryMeasures: string | null;
    disablement: IS37InterventionUpdateDTO | null;
    disablementType: ListOfValuesReferenceDTO | null;
    endDate: string | null;
    firePermit: FirePermitReferenceDTO | null;
    frsOfficer: PersonReferenceDTO | null;
    locationDetails: string | null;
    locations: LocationReferenceDTO[] | null;
    recommissioning: IS37InterventionUpdateDTO | null;
    recommissioningEnd: string | null;
    recommissioningStart: string | null;
    requestedDate: string | null;
    responsible: PersonReferenceDTO | null;
    sensors: CsamSensorReferenceDTO[] | null;
    startDate: string | null;
    version: number | null;
    workTypeJustification: string | null;
    workTypes: ListOfValuesReferenceDTO[] | null;
}

export interface ImpactAndTestQuestionReadDTO {
    answer: boolean | null;
    comments: string | null;
    type: ListOfValuesReadDTO;
}

export interface InterventionPeriodDTO {
    egroupsNotAllowed: boolean | null;
    end: string;
    facility: ListOfValuesReadDTO;
    id: number;
    name: string;
    start: string;
    type: ListOfValuesReadDTO;
}

export interface InterventionPeriodNimReadDTO {
    egroupsNotAllowed: boolean | null;
    id: number | null;
    interventionFacility: ListOfValuesReadDTO;
    periodEnd: string;
    periodName: string;
    periodStart: string;
    periodType: ListOfValuesReadDTO;
}

export interface InterventionPeriodNimReferenceDTO {
    id: number | null;
}

export interface ListOfValuesReadDTO<Code extends string = string> {
    allowSelect: string;
    code: Code;
    defaultSelect: string;
    description: string;
    descriptionEn: string;
    descriptionFr: string;
    label: string;
    labelEn: string;
    labelFr: string;
    orderInType: number | null;
}

export interface ListOfValuesReferenceDTO {
    code: string | null;
}

export interface ListOfValuesWithCountReadDTO {
    count: number;
    value: ListOfValuesReadDTO;
}

export interface LocationDTO {
    accessPoints: AccessPointReadDTO[];
    areaClassification: ListOfValuesReadDTO | null;
    buildingNumber: string | null;
    childrenDosimetryMandatory: boolean | null;
    code: string;
    facility: ListOfValuesReadDTO | null;
    highestAreaClassification: ListOfValuesReadDTO | null;
    id: number;
    impactAlias: string | null;
    impactName: string;
    locationType: LocationTypeCode;
    maxSubareaClassification: ListOfValuesReadDTO | null;
    node: LocationNodeDTO | null;
    operDosimetryMandatory: boolean | null;
    radiationRisk: boolean | null;
    selectable: boolean;
    sigle: string | null;
    startOfWork: boolean | null;
}

export interface LocationHazardDTO {
    compensatoryMeasure: string | null;
    location: LocationDTO | null;
    type: ListOfValuesReadDTO | null;
}

export interface LocationHazardUpdateDTO {
    compensatoryMeasure: string | null;
    hazard: string | null;
    location: LocationReferenceDTO | null;
}

export interface LocationNodeDTO {
    childCount: number;
    nodeLevel: number;
    parentId: number | null;
    path: string[];
}

export interface LocationReferenceDTO {
    id: number | null;
}

export interface LockoutAvailableActionsReadDTO {
    attachable: boolean;
    canClone: boolean;
}

export interface LockoutContextReadDTO {
    currentStatus: ListOfValuesReadDTO | null;
    currentUser: PersonReadDTO | null;
    documentAction: DocumentActionDTO | null;
    now: string;
    task: TaskType | null;
    taskAction: TaskAction | null;
}

export interface LockoutGridReadDTO extends RecordDTO {
    activity: ActivityReadDTO;
    id: number;
    lockoutLocations: LocationDTO[];
    lockoutType: ListOfValuesReadDTO;
    requestEndDate: string | null;
    requestStartDate: string | null;
    status: ListOfValuesReadDTO<LockoutStatusCode>;
}

export interface LockoutReadDTO extends RecordDTO {
    activity: ActivityReadDTO;
    availableActions: LockoutAvailableActionsReadDTO;
    comments: string | null;
    electricalEquipmentText: string | null;
    equipmentToLock: EquipmentReadDTO[];
    equipmentWorkedOn: EquipmentReadDTO[];
    full: boolean | null;
    grantedWorkingTime: ListOfValuesReadDTO | null;
    grantedWorkingTimeEnd: string | null;
    grantedWorkingTimeStart: string | null;
    id: number;
    lockEdmsProcedure: string | null;
    lockExecutionDate: string | null;
    lockOtherProcedure: string | null;
    lockType: ListOfValuesReadDTO | null;
    lockout1level: string | null;
    lockout2level: string | null;
    lockoutComments: string | null;
    lockoutCryoEquipments: EquipmentReadDTO[];
    lockoutLocations: LocationDTO[];
    lockoutType: ListOfValuesReadDTO;
    pdf: DocumentPdfDTO | null;
    powerConvertersText: string | null;
    requestEndDate: string | null;
    requestStartDate: string | null;
    status: ListOfValuesReadDTO<LockoutStatusCode>;
    unlockEdmsProcedure: string | null;
    unlockExecutionComments: string | null;
    unlockExecutionDate: string | null;
    unlockOtherProcedure: string | null;
    version: number;
}

export interface LockoutReferenceDTO {
    id: number | null;
}

export interface LockoutUpdateDTO {
    activity: ActivityReferenceDTO | null;
    comments: string | null;
    electricalEquipmentText: string | null;
    equipmentToLock: EquipmentReferenceDTO[] | null;
    equipmentWorkedOn: EquipmentReferenceDTO[] | null;
    full: boolean | null;
    grantedWorkingTime: ListOfValuesReferenceDTO | null;
    grantedWorkingTimeEnd: string | null;
    grantedWorkingTimeStart: string | null;
    lockEdmsProcedure: string | null;
    lockExecutionDate: string | null;
    lockOtherProcedure: string | null;
    lockType: ListOfValuesReferenceDTO | null;
    lockout1level: string | null;
    lockout2level: string | null;
    lockoutComments: string | null;
    lockoutCryoEquipments: EquipmentReferenceDTO[] | null;
    lockoutLocations: LocationReferenceDTO[] | null;
    lockoutType: ListOfValuesReferenceDTO | null;
    powerConvertersText: string | null;
    requestEndDate: string | null;
    requestStartDate: string | null;
    unlockEdmsProcedure: string | null;
    unlockExecutionComments: string | null;
    unlockExecutionDate: string | null;
    unlockOtherProcedure: string | null;
    version: number | null;
}

export interface NdcAffectedNetworksAndEquipmentReadDTO {
    affectedNetworks: ListOfValuesReadDTO[];
    complementaryInformation: string | null;
    equipment: EquipmentReadDTO[];
    location: LocationDTO | null;
}

export interface NdcAffectedNetworksAndEquipmentUpdateDTO {
    affectedNetworks: ListOfValuesReferenceDTO[] | null;
    complementaryInformation: string | null;
    equipment: EquipmentReferenceDTO[] | null;
    location: LocationReferenceDTO | null;
}

export interface NdcContextReadDTO {
    documentAction: DocumentActionDTO | null;
    isSelectableAnyLocation: boolean;
    task: TaskType | null;
}

export interface NdcDistributionListReadDTO {
    emailCount: number;
    peopleCount: number;
}

export interface NdcDistributionTargetReadDTO {
    egroup: EgroupDTO | null;
    email: string | null;
    person: PersonReadDTO | null;
    type: ListOfValuesReadDTO<NdcDistributionTypeCode>;
}

export interface NdcDistributionTargetUpdateDTO {
    egroup: EgroupReferenceDTO | null;
    email: string | null;
    person: PersonReferenceDTO | null;
    type: ListOfValuesReferenceDTO | null;
}

export interface NdcLocationReadDTO {
    designation: string | null;
    equipment: EquipmentReadDTO[] | null;
    location: LocationDTO | null;
}

export interface NdcLocationUpdateDTO {
    designation: string | null;
    equipment: EquipmentReferenceDTO[] | null;
    location: LocationReferenceDTO | null;
}

export interface NdcReadDTO extends RecordDTO {
    activity: ActivityReferenceDTO;
    affectedNetworksAndEquipment: NdcAffectedNetworksAndEquipmentReadDTO[];
    allowAnyLocation: boolean;
    approvedBy: PersonReadDTO | null;
    approvedDate: string | null;
    attachments: AttachmentDTO[];
    bldgLevelsOccupantsAffected: boolean;
    bldgOccupantsAffected: boolean;
    bldgTsosAndDtsosAffected: boolean;
    cancelledBy: PersonReadDTO | null;
    cutExecutor: PersonReadDTO | null;
    distribution: string | null;
    distributionDate: string | null;
    distributionTargets: NdcDistributionTargetReadDTO[];
    editedBy: PersonReadDTO | null;
    editedDate: string | null;
    endDate: string | null;
    facilityCoordinatorsAffected: boolean;
    finishedBy: PersonReadDTO | null;
    id: number;
    interventionType: ListOfValuesReadDTO | null;
    language: ListOfValuesReadDTO<LanguageCode> | null;
    locations: NdcLocationReadDTO[];
    ndcNumber: string | null;
    note: string | null;
    pdf: DocumentPdfDTO | null;
    reasonForCancel: string | null;
    requestedBy: PersonReadDTO;
    responsibleGroup: OrgUnitReadDTO | null;
    severity: ListOfValuesReadDTO | null;
    startDate: string | null;
    status: ListOfValuesReadDTO<NdcStatusCode>;
    subtitle: string | null;
    title: string | null;
    version: number;
    workToBePerformed: string | null;
}

export interface NdcReferenceDTO {
    id: number | null;
}

export interface NdcUpdateDTO {
    activity: ActivityReferenceDTO | null;
    affectedNetworksAndEquipment: NdcAffectedNetworksAndEquipmentUpdateDTO[] | null;
    bldgLevelsOccupantsAffected: boolean;
    bldgOccupantsAffected: boolean;
    bldgTsosAndDtsosAffected: boolean;
    cutExecutor: PersonReferenceDTO | null;
    distributionTargets: NdcDistributionTargetUpdateDTO[] | null;
    endDate: string | null;
    facilityCoordinatorsAffected: boolean;
    interventionType: ListOfValuesReferenceDTO | null;
    language: ListOfValuesReferenceDTO | null;
    locations: NdcLocationUpdateDTO[] | null;
    note: string | null;
    requestedBy: PersonReferenceDTO | null;
    responsibleGroup: OrgUnitReferenceDTO | null;
    severity: ListOfValuesReferenceDTO | null;
    startDate: string | null;
    subtitle: string | null;
    title: string | null;
    version: number | null;
    workToBePerformed: string | null;
}

export interface NestedRulesDTO<S> {
    field: string;
    rules: RulesDTO<S>;
}

export interface OnSiteActivityCommentReadDTO {
    locationId: number;
    notifyActivityResponsible: boolean | null;
    status: ListOfValuesReadDTO;
    text: string | null;
    warning: boolean | null;
}

export interface OptionalDTO<T> {
    present: boolean;
    value: T | null;
}

export interface OrgUnitReadDTO {
    code: string | null;
    description: string | null;
    id: number;
    isLeaf: boolean;
    orgLevel: number;
    parent: string | null;
    path: string | null;
    type: OrgUnitTypeCode | null;
}

export interface OrgUnitReferenceDTO {
    id: number | null;
}

export interface ParticipantDTO {
}

export interface PersonReadDTO {
    email: string | null;
    fullName: string;
    id: number;
    orgUnit: OrgUnitReadDTO | null;
    orgUnitCode: string | null;
    personId: string;
    personOrganization: string | null;
    phone1: string | null;
    phone3Display: string | null;
    preferredLanguage: string;
    searchLabel: string;
    username: string | null;
}

export interface PersonReferenceDTO {
    id: number | null;
}

export interface PersonRichReadDTO {
    department: OrgUnitReadDTO | null;
    email: string | null;
    firstName: string;
    fullName: string;
    group: OrgUnitReadDTO | null;
    id: number;
    lastName: string;
    orgUnit: OrgUnitReadDTO | null;
    orgUnitCode: string | null;
    personId: string;
    personOrganization: string | null;
    phone1: string | null;
    phone3Display: string | null;
    preferredLanguage: string;
    roleCodes: string[] | null;
    searchLabel: string;
    section: OrgUnitReadDTO | null;
    username: string | null;
}

export interface PersonTotalDoseDTO {
    activityId: number | null;
    personId: number | null;
    totalDose: number | null;
}

export interface PriorityDTO {
    description: string | null;
    id: number;
}

export interface PvParticipantReadDTO {
    accessOption: ListOfValuesReadDTO;
    accessOptionGranted: boolean;
    prtInformationId: number | null;
}

export interface RPAssessmentDTO {
    contaminatingWorks: boolean | null;
    maxIndividualWorkingTime: number | null;
    totalCollectiveWorkingTime: number | null;
}

export interface RadiologicalConditionReadDTO {
    author: PersonReadDTO | null;
    createdBy: PersonReadDTO | null;
    createdDate: string | null;
    id: number;
    text: string;
}

export interface RecordDTO {
    createdBy: PersonReadDTO;
    createdDate: string;
}

export interface RoleDTO {
}

export interface RpAssessmentContextDTO {
    canEditInDraft: boolean | null;
    doseRateAlarmThreshold: number | null;
    estCollectiveDose: number | null;
    highestAreaClassification: ListOfValuesReferenceDTO | null;
    isRoleDgHhseRpGl: boolean | null;
    isRoleE3: boolean | null;
    isRoleOwnerCreator: boolean | null;
    isRoleRp: boolean | null;
    isRoleRpe: boolean | null;
    isRoleRsoDsoGlimos: boolean | null;
    isRoleRsso: boolean | null;
    isRoleSlGlDh: boolean | null;
    maxEstIndividualDose: number | null;
    operationalDosimetryMandatory: boolean | null;
    pcrs: PersonReadDTO[] | null;
}

export interface RpAssessmentGridReadDTO {
    activity: ActivityReadDTO;
    dimrVersion: DimrVersionGridReadDTO | null;
    id: number;
}

export interface RpAssessmentReadDTO {
    activity: ActivityReadDTO;
    actualCollectiveDose: number | null;
    actualDosesDate: string | null;
    actualMaxIndividualDose: number | null;
    attachments: AttachmentDTO[];
    avgEstDoseRate: number | null;
    contaminatingWorks: boolean | null;
    doseRateAlarmThreshold: number | null;
    equipmentJobCode: ListOfValuesReadDTO | null;
    estCollectiveDose: number | null;
    extremityDosimetryMandatory: boolean | null;
    generalJobCode: ListOfValuesReadDTO | null;
    highestAreaClassification: ListOfValuesReadDTO | null;
    historicalRadiologicalConditions: RadiologicalConditionReadDTO[];
    historicalRpRecommendations: RpRecommendationReadDTO[];
    id: number;
    individualDoseAlarm: number | null;
    locations: LocationDTO[];
    maxEstAirborneCont: number | null;
    maxEstDoseRate: number | null;
    maxEstIndividualDose: number | null;
    maxEstSurfaceCont: number | null;
    maxIndividualWorkTime: number | null;
    operDosimetryMandatory: boolean | null;
    rpAssessmentPcrs: PersonReadDTO[];
    rpComments: string | null;
    rpPresence: ListOfValuesReadDTO | null;
    rsoComments: string | null;
    rssoRpe: PersonReadDTO | null;
    totalCollectiveWorkTime: number | null;
    valuesForcedManual: boolean;
    wdpWorkSteps: WdpWorkStepReferencedReadDTO[];
}

export interface RpAssessmentReferenceDTO {
    id: number;
}

export interface RpAssessmentUpdateDTO {
    activity: ActivityReferenceDTO | null;
    actualCollectiveDose: number | null;
    actualDosesDate: string | null;
    actualMaxIndividualDose: number | null;
    avgEstDoseRate: number | null;
    contaminatingWorks: boolean | null;
    doseRateAlarmThreshold: number | null;
    equipmentJobCode: ListOfValuesReferenceDTO | null;
    estCollectiveDose: number | null;
    extremityDosimetryMandatory: boolean | null;
    generalJobCode: ListOfValuesReferenceDTO | null;
    highestAreaClassification: ListOfValuesReferenceDTO | null;
    id: number | null;
    individualDoseAlarm: number | null;
    locations: LocationReferenceDTO[] | null;
    maxEstAirborneCont: number | null;
    maxEstDoseRate: number | null;
    maxEstIndividualDose: number | null;
    maxEstSurfaceCont: number | null;
    maxIndividualWorkTime: number | null;
    operDosimetryMandatory: boolean | null;
    rpAssessmentPcrs: PersonReferenceDTO[] | null;
    rpComments: string | null;
    rpPresence: ListOfValuesReferenceDTO | null;
    rsoComments: string | null;
    rssoRpe: PersonReferenceDTO | null;
    totalCollectiveWorkTime: number | null;
    valuesForcedManual: boolean | null;
    wdpWorkSteps: WdpWorkStepInternalReferenceDTO[];
}

export interface RpRecommendationReadDTO {
    author: PersonReadDTO | null;
    id: number;
    text: string;
}

export interface RulesDTO<S> {
    attributes: { [index: string]: FieldAttributesDTO };
    context: S | null;
    variables: VariableDTO[];
}

export interface SafetyDTO {
    activityHazards: ActivityHazardDTO[] | null;
    coActivityHazards: CoActivityHazardDTO[] | null;
    locationHazards: LocationHazardDTO[] | null;
    lockouts: DocumentDTO[] | null;
    safetyDocuments: DocumentDTO[] | null;
}

export interface SavedFilterReadDTO {
    createdBy: PersonReadDTO;
    filterType: SavedFilterType;
    id: number;
    name: string;
    sorting: string | null;
    specification: string;
    subscribed: boolean;
    version: number;
}

export interface SavedFilterUpdateDTO {
    filterType: SavedFilterType;
    id: number | null;
    name: string | null;
    sorting: string | null;
    specification: string | null;
    subscribed: boolean | null;
    version: number | null;
}

export interface SupplierReadDTO {
    code: string | null;
    email: string | null;
    id: number;
    name: string;
    phone: string | null;
    status: string | null;
    technicalEmail: string | null;
}

export interface SupplierReferenceDTO {
    id: number | null;
}

export interface SystemBsSrcReadDTO {
    childCount: number;
    fullname: string;
    id: number;
    level: number;
    name: string;
    parentId: number;
    path: string[];
    visible: boolean;
}

export interface SystemBsSrcReferenceDTO {
    id: number | null;
}

export interface SystemDTO {
    code: string | null;
}

export interface TagActivityReadDTO {
    tag: TagReadDTO;
}

export interface TagReadDTO {
    deleteId: number;
    editId: number;
    name: string;
    owner: PersonReadDTO;
    tagId: number;
    untagId: number;
    viewId: number;
    visibility: ListOfValuesReadDTO | null;
}

export interface TaskAddAssigneesDTO {
    comment: CommentUpdateDTO | null;
    newAssignees: PersonReferenceDTO[] | null;
}

export interface TaskDTO {
    actions: TaskAction[];
    assignees: PersonReadDTO[];
    assignmentLabelEn: string;
    assignmentLabelFr: string;
    closedDate: string | null;
    code: TaskCode;
    commentEnabled: boolean | null;
    createdDate: string;
    document: TaskDocumentDTO | null;
    documentId: number;
    documentType: DocumentType;
    documentVersion: DocumentVersionReferenceDTO | null;
    id: string;
    taskActive: boolean;
    taskCompletionCode: TaskAction | null;
    taskExecutor: PersonReadDTO | null;
    taskSilent: boolean;
    taskTimeout: string | null;
    type: TaskTypeDTO;
    updatedDate: string;
    workflowUrl: string | null;
}

export interface TaskDocumentDTO {
    activityId: number | null;
    createdBy: PersonReadDTO;
    createdDate: string;
    facility: ListOfValuesReadDTO | null;
    status: ListOfValuesReadDTO | null;
    title: string | null;
}

export interface TaskPayloadCommentUpdateDTO<T> {
    comment: string | null;
    data: T | null;
}

export interface TaskTypeDTO {
    type: TaskType;
    typeNameEn: string;
    typeNameFr: string;
}

export interface TestDTO {
}

export interface TestTypeDTO {
    answer: boolean | null;
    comment: string | null;
}

export interface TestsDTO {
    alarms: AlarmDTO[] | null;
    test: TestDTO[] | null;
    type: TestTypeDTO | null;
}

export interface VariableDTO {
    operands: number[] | null;
    type: VariableTypeDTO;
    value: any | null;
}

export interface VicAvailableActionsReadDTO {
    canClone: boolean;
}

export interface VicCernParticipantReadDTO {
    participant: PersonReadDTO;
    participantRole: ListOfValuesReadDTO;
}

export interface VicCernParticipantUpdateDTO {
    participant: PersonReferenceDTO | null;
    participantRole: ListOfValuesReferenceDTO | null;
}

export interface VicCommentReadDTO {
    content: string | null;
    createdBy: PersonReadDTO;
    createdDate: string;
    id: number;
}

export interface VicCompanyParticipantReadDTO {
    company: SupplierReadDTO;
    id: number;
    participant: PersonReadDTO;
    participantCompanyType: ListOfValuesReadDTO;
}

export interface VicCompanyParticipantUpdateDTO {
    company: SupplierReferenceDTO | null;
    participant: PersonReferenceDTO | null;
    participantCompanyType: ListOfValuesReferenceDTO | null;
}

export interface VicContextReadDTO {
    currentUser: PersonReadDTO | null;
    documentAction: DocumentActionDTO | null;
    isV1: boolean | null;
    task: TaskType | null;
}

export interface VicDistributionReadDTO {
    distributionType: ListOfValuesReadDTO;
    person: PersonReadDTO;
}

export interface VicDistributionUpdateDTO {
    distributionType: ListOfValuesReferenceDTO | null;
    person: PersonReferenceDTO | null;
}

export interface VicGridReadDTO extends RecordDTO {
    facility: ListOfValuesReadDTO;
    id: number;
    scheduledEndDate: string | null;
    scheduledStartDate: string | null;
    status: ListOfValuesReadDTO<VicStatusCode>;
    title: string;
}

export interface VicReadDTO extends RecordDTO {
    activities: ActivityReadDTO[];
    additionalAuthorization: boolean | null;
    additionalCryogenicLockout: boolean | null;
    additionalDimr: boolean | null;
    additionalElectricalLockout: boolean | null;
    additionalFirePermit: boolean | null;
    additionalIs37: boolean | null;
    additionalWdp: boolean | null;
    attachments: AttachmentDTO[];
    availableActions: VicAvailableActionsReadDTO;
    checkAccessConditions: boolean | null;
    checkEquipment: boolean | null;
    checkPowerSupply: boolean | null;
    checkRescueOrganization: boolean | null;
    checkToilets: boolean | null;
    checkWorkingZone: boolean | null;
    clonedFrom: VicGridReadDTO | null;
    documents: string | null;
    edmsNumber: string | null;
    emergencyInfo: string | null;
    facility: ListOfValuesReadDTO | null;
    id: number;
    interventionPerimeter: string | null;
    otherPoints: string | null;
    pdf: DocumentPdfDTO | null;
    remarks: string | null;
    riskAssessmentAttachment: boolean | null;
    riskAssessmentEdms: boolean | null;
    scheduledEndDate: string | null;
    scheduledStartDate: string | null;
    serviceNeeds: string | null;
    status: ListOfValuesReadDTO<VicStatusCode>;
    title: string;
    version: number;
    vicApplicability: ListOfValuesReadDTO | null;
    vicCernParticipants: VicCernParticipantReadDTO[];
    vicCompanyParticipants: VicCompanyParticipantReadDTO[];
    vicCreationDate: string | null;
    vicDistributions: VicDistributionReadDTO[];
    vicReports: VicReportReadDTO[];
    vicRiskAssessments: VicRiskAssessmentReadDTO[];
    vicSafetyDocs: ListOfValuesReadDTO | null;
    vicVersion: string | null;
    workProcedure: string | null;
    worksToBePerformed: string | null;
}

export interface VicReferenceDTO {
    id: number | null;
}

export interface VicReportReadDTO {
    creationDate: string;
    creator: PersonReadDTO;
    fileContentId: number;
    fileName: string;
    id: number | null;
}

export interface VicRiskAssessmentReadDTO {
    answer: boolean | null;
    mitigation: string | null;
    vicRiskType: ListOfValuesReadDTO;
}

export interface VicRiskAssessmentUpdateDTO {
    answer: boolean | null;
    mitigation: string | null;
    vicRiskType: ListOfValuesReferenceDTO | null;
}

export interface VicUpdateDTO {
    activities: ActivityReferenceDTO[] | null;
    additionalAuthorization: boolean | null;
    additionalCryogenicLockout: boolean | null;
    additionalDimr: boolean | null;
    additionalElectricalLockout: boolean | null;
    additionalFirePermit: boolean | null;
    additionalIs37: boolean | null;
    additionalWdp: boolean | null;
    checkAccessConditions: boolean | null;
    checkEquipment: boolean | null;
    checkPowerSupply: boolean | null;
    checkRescueOrganization: boolean | null;
    checkToilets: boolean | null;
    checkWorkingZone: boolean | null;
    clonedFrom: VicReferenceDTO | null;
    documents: string | null;
    edmsNumber: string | null;
    emergencyInfo: string | null;
    facility: ListOfValuesReferenceDTO | null;
    interventionPerimeter: string | null;
    otherPoints: string | null;
    remarks: string | null;
    riskAssessmentAttachment: boolean | null;
    riskAssessmentEdms: boolean | null;
    scheduledEndDate: string | null;
    scheduledStartDate: string | null;
    serviceNeeds: string | null;
    title: string | null;
    version: number | null;
    vicApplicability: ListOfValuesReferenceDTO | null;
    vicCernParticipants: VicCernParticipantUpdateDTO[];
    vicCompanyParticipants: VicCompanyParticipantUpdateDTO[];
    vicCreationDate: string | null;
    vicDistributions: VicDistributionUpdateDTO[] | null;
    vicRiskAssessments: VicRiskAssessmentUpdateDTO[] | null;
    vicSafetyDocs: ListOfValuesReferenceDTO | null;
    vicVersion: string | null;
    workProcedure: string | null;
    worksToBePerformed: string | null;
}

export interface WdpAvailableActionsReadDTO {
    canCancel: boolean;
    canReopen: boolean;
    canSave: boolean;
    showAsPerformedTab: boolean;
}

export interface WdpCheckPointReadDTO {
    checkPointDate: string;
    percentageOfCompletion: number;
}

export interface WdpCheckPointUpdateDTO {
    checkPointDate: string | null;
    percentageOfCompletion: number | null;
}

export interface WdpFollowupLastVarianceReadDTO {
    date: string;
    variance: number;
}

export interface WdpFollowupReadDTO {
    data: WdpFollowupTimeSeriesPointReadDTO[];
    lastCostVariance: WdpFollowupLastVarianceReadDTO | null;
    lastScheduleVariance: WdpFollowupLastVarianceReadDTO | null;
    totalCollectiveDoseVisible: boolean;
}

export interface WdpFollowupTimeSeriesPointReadDTO {
    costPerformanceIndex: number;
    costVariance: number;
    date: string;
    earnedValue: number;
    estimatedTotalCollectiveDose: number;
    schedulePerformanceIndex: number;
    scheduleVariance: number;
    totalCollectiveDose: number;
}

export interface WdpParticipantReadDTO {
    doseHistoryVisible: boolean | null;
    doseTaken: number | null;
    doseTakenVisible: boolean | null;
    dosimetryStatus: string | null;
    estimatedDose: number | null;
    estimationNext12Months: number | null;
    name: string | null;
    operationalDoseLast12Months: number | null;
    operationalDoseLast31Days: number | null;
    participantsIdx: number;
    person: PersonReadDTO | null;
    replacement: boolean;
    totalIndividualWorkingTime: number | null;
    unknown: boolean;
    wdpWorkingTeamIdxs: WdpWorkingTeamReferenceDTO[];
}

export interface WdpParticipantReferenceDTO {
    participantsIdx: number;
}

export interface WdpParticipantUpdateDTO {
    estimatedDose: number | null;
    name: string | null;
    person: PersonReferenceDTO | null;
    unknown: boolean | null;
    wdpWorkingTeamIdxs: WdpWorkingTeamReferenceDTO[] | null;
}

export interface WdpPerformedTaskReadDTO {
    coefficient: number | null;
    comment: string | null;
    description: string | null;
    doseReceivedFromDosiserv: number | null;
    doseReceivedManuallyInput: number | null;
    doseTakenVisible: boolean | null;
    estimatedDose: number | null;
    estimatedDoseRate: number | null;
    expectedEndDate: string | null;
    expectedStartDate: string | null;
    exposureTime: number | null;
    isLeaf: boolean | null;
    numberOfParticipants: number | null;
    parent: WdpPerformedTaskReferenceDTO | null;
    performedTasksIdx: number;
    wdpCheckPoints: WdpCheckPointReadDTO[];
    wdpParticipantIdxs: WdpParticipantReferenceDTO[];
    wdpWorkingPositionIdxs: WdpWorkingPositionReferenceDTO[];
    wdpWorkingTeamIdxs: WdpWorkingTeamReferenceDTO[];
    workStep: WdpWorkStepReferenceDTO | null;
}

export interface WdpPerformedTaskReferenceDTO {
    performedTasksIdx: number;
}

export interface WdpPerformedTaskUpdateDTO {
    coefficient: number | null;
    comment: string | null;
    description: string | null;
    doseReceivedManuallyInput: number | null;
    expectedEndDate: string | null;
    expectedStartDate: string | null;
    exposureTime: number | null;
    isLeaf: boolean | null;
    numberOfParticipants: number | null;
    parent: WdpPerformedTaskReferenceDTO | null;
    wdpCheckPoints: WdpCheckPointUpdateDTO[] | null;
    wdpParticipantIdxs: WdpParticipantReferenceDTO[] | null;
    wdpWorkingPositionIdxs: WdpWorkingPositionReferenceDTO[] | null;
    wdpWorkingTeamIdxs: WdpWorkingTeamReferenceDTO[] | null;
    workStep: WdpWorkStepReferenceDTO | null;
}

export interface WdpRpAssessmentReadDTO {
    assessmentsIdx: number;
    avgEstDoseRate: number | null;
    estCollectiveDose: number | null;
    maxEstDoseRate: number | null;
    maxEstIndividualDose: number | null;
    maxIndividualWorkTime: number | null;
    rpAssessment: RpAssessmentReadDTO;
    totalCollectiveWorkTime: number | null;
    wdpWorkStepIdxs: WdpWorkStepReferencedReadDTO[];
}

export interface WdpRpAssessmentUpdateDTO {
    rpAssessment: RpAssessmentReferenceDTO | null;
    wdpWorkStepIdxs: WdpWorkStepReferenceDTO[];
}

export interface WdpVersionContextReadDTO {
    isEditor: boolean;
    isRpValidate: boolean;
    strict: boolean;
}

export interface WdpVersionDimrReadDTO extends RecordDTO {
    cancelled: boolean | null;
    id: number;
    title: string;
    versionNumber: number;
    wdpMasterId: number;
}

export interface WdpVersionGridReadDTO extends RecordDTO {
    cancelled: boolean | null;
    dimr: DimrVersionGridReadDTO | null;
    id: number;
    title: string;
    versionNumber: number;
    wdpFacilities: ListOfValuesReadDTO[];
    wdpMasterId: number;
}

export interface WdpVersionNumberReadDTO {
    cancelled: boolean | null;
    versionNumber: number;
}

export interface WdpVersionReadDTO extends RecordDTO {
    attachments: AttachmentDTO[];
    availableActions: WdpAvailableActionsReadDTO;
    avgEstDoseRate: number | null;
    cancelled: boolean | null;
    dimr: DimrVersionGridActivityReadDTO | null;
    estCollectiveDose: number | null;
    id: number;
    maxEstDoseRate: number | null;
    maxEstIndividualDose: number | null;
    maxIndividualWorkTime: number | null;
    title: string;
    totalCollectiveWorkTime: number | null;
    version: number;
    versionNumber: number;
    versionNumbers: WdpVersionNumberReadDTO[];
    wdpEditors: PersonReadDTO[];
    wdpFacilities: ListOfValuesReadDTO[];
    wdpFollowup: WdpFollowupReadDTO;
    wdpMasterId: number;
    wdpParticipants: WdpParticipantReadDTO[];
    wdpPerformedTasks: WdpPerformedTaskReadDTO[];
    wdpRpAssessments: WdpRpAssessmentReadDTO[];
    wdpWorkSteps: WdpWorkStepReadDTO[];
    wdpWorkingPositions: WdpWorkingPositionReadDTO[];
    wdpWorkingTeams: WdpWorkingTeamReadDTO[];
}

export interface WdpVersionReferenceDTO {
    id: number;
}

export interface WdpVersionUpdateDTO {
    cancelled: boolean | null;
    dimr: DimrVersionWithStatusReferenceDTO | null;
    title: string | null;
    version: number | null;
    versionNumber: number | null;
    wdpEditors: PersonReferenceDTO[] | null;
    wdpFacilities: ListOfValuesReferenceDTO[] | null;
    wdpMasterId: number | null;
    wdpParticipants: WdpParticipantUpdateDTO[] | null;
    wdpPerformedTasks: WdpPerformedTaskUpdateDTO[] | null;
    wdpRpAssessments: WdpRpAssessmentUpdateDTO[] | null;
    wdpWorkSteps: WdpWorkStepUpdateDTO[] | null;
    wdpWorkingPositions: WdpWorkingPositionUpdateDTO[] | null;
    wdpWorkingTeams: WdpWorkingTeamUpdateDTO[] | null;
}

export interface WdpWorkStepInternalReferenceDTO {
    workStepsIdx: number | null;
}

export interface WdpWorkStepReadDTO {
    coefficient: number | null;
    description: string | null;
    doseRate: number | null;
    estimatedDose: number | null;
    estimatedExposureTime: number | null;
    estimatedTotalExposureTime: number | null;
    estimatedTotalTaskDose: number | null;
    expectedEndDate: string | null;
    expectedStartDate: string | null;
    id: number;
    isLeaf: boolean | null;
    numberOfParticipants: number | null;
    parent: WdpWorkStepReferenceDTO | null;
    responsible: PersonReadDTO | null;
    rpAssessment: RpAssessmentReferenceDTO | null;
    wdpParticipantIdxs: WdpParticipantReferenceDTO[];
    wdpPerformedTaskIdxs: WdpPerformedTaskReferenceDTO[];
    wdpWorkingPositionIdxs: WdpWorkingPositionReferenceDTO[];
    wdpWorkingTeamIdxs: WdpWorkingTeamReferenceDTO[];
    workStepsIdx: number;
}

export interface WdpWorkStepReferenceDTO {
    workStepsIdx: number;
}

export interface WdpWorkStepReferencedReadDTO {
    description: string;
    path: string;
    versionNumber: number;
    wdpMasterId: number;
    workStepsIdx: number;
}

export interface WdpWorkStepUpdateDTO {
    coefficient: number | null;
    description: string | null;
    estimatedExposureTime: number | null;
    expectedEndDate: string | null;
    expectedStartDate: string | null;
    isLeaf: boolean | null;
    numberOfParticipants: number | null;
    parent: WdpWorkStepReferenceDTO | null;
    responsible: PersonReferenceDTO | null;
    wdpParticipantIdxs: WdpParticipantReferenceDTO[] | null;
    wdpWorkingPositionIdxs: WdpWorkingPositionReferenceDTO[] | null;
    wdpWorkingTeamIdxs: WdpWorkingTeamReferenceDTO[] | null;
}

export interface WdpWorkingPositionReadDTO {
    description: string | null;
    doseRate: number | null;
    finalDoseRate: number | null;
    name: string;
    reductionFactor: number | null;
    workingPositionsIdx: number;
}

export interface WdpWorkingPositionReferenceDTO {
    workingPositionsIdx: number;
}

export interface WdpWorkingPositionUpdateDTO {
    description: string | null;
    doseRate: number | null;
    name: string | null;
    reductionFactor: number | null;
}

export interface WdpWorkingTeamReadDTO {
    description: string | null;
    name: string;
    teamsIdx: number;
    wdpParticipantIdxs: WdpParticipantReferenceDTO[];
    wdpPerformedTaskIdxs: WdpPerformedTaskReferenceDTO[];
    wdpWorkStepIdxs: WdpWorkStepInternalReferenceDTO[];
}

export interface WdpWorkingTeamReferenceDTO {
    teamsIdx: number;
}

export interface WdpWorkingTeamUpdateDTO {
    description: string | null;
    name: string | null;
}

export interface WhenDTO {
    duration: number | null;
    durationType: ListOfValuesReadDTO | null;
    from: string | null;
    interventionPeriod: InterventionPeriodDTO | null;
    proposedStartDate: string | null;
    to: string | null;
    workingTime: ListOfValuesReadDTO | null;
    workingTimeComment: string | null;
}

export interface WhereDTO {
    comment: string | null;
    impactedFacilities: ListOfValuesReadDTO[] | null;
    locations: ActivityLocationDTO[] | null;
}

export interface WhoDTO {
    comment: string | null;
    contactPhone: string | null;
    maxNumberOfParticipants: number | null;
    participants: ParticipantDTO[] | null;
    person: PersonReadDTO | null;
    role: RoleDTO | null;
    shortTermRadiationWorkers: boolean | null;
}

export interface WorkOrderReadDTO {
    activityId: string | null;
    createdBy: PersonReadDTO | null;
    createdDate: string | null;
    description: string | null;
    editedBy: PersonReadDTO | null;
    editedDate: string | null;
    equipmentCode: string | null;
    equipmentDescription: string | null;
    facilityId: string | null;
    id: number;
    isClosed: boolean | null;
    locationCode: string | null;
    locationId: string | null;
    locationSigle: string | null;
    mrcCode: string | null;
    mrcDescription: string | null;
    requestedEnd: string | null;
    requestedStart: string | null;
    scheduledEnd: string | null;
    scheduledStart: string | null;
    statusCode: string | null;
    statusCodeDesc: string | null;
}

export interface WorkflowDTO {
    historyEntries: WorkflowHistoryEntryDTO[];
    id: number;
    workflowUrl: string | null;
}

export interface WorkflowHistoryEntryDTO {
    created: string;
    currentlyNotified: boolean;
    messageEn: string;
    messageFr: string;
}

export const enum ActivityStatusCode {
    NEW = 'S_NEW',
    DRAFT = 'S_DRAFT',
    SAVED = 'S_SAVED',
    WAITING_SYSTEM_APPROVAL = 'S_WSYA',
    WAITING_APPROVAL = 'S_WFAPPROVAL',
    WAITING_MEETINGS_APPROVAL = 'S_WMA',
    WAITING_TO_BE_SCHEDULED = 'S_WTBS',
    SCHEDULED = 'S_SCHEDULED',
    WAITING_SAFETY_APPROVAL = 'S_WSAA',
    WAITING_SURFACE_APPROVAL = 'S_WFEDHAPPROVAL',
    WAITING_VISIT_SAFETY_APPROVAL = 'S_WVSOAPPROVAL',
    APPROVED = 'S_APPROVED',
    IN_PROGRESS = 'S_IP',
    LATE = 'S_LATE',
    INTERRUPTED = 'S_INTERRUPTED',
    FINISHED = 'S_FINISHED',
    CLOSED = 'S_CLOSED',
    REJECTED = 'S_REJECTED',
    CANCELLED = 'S_CANCELLED',
}

export const enum ActivityTypeCode {
    CONSOLIDATION = 'ACTT_CO',
    MAINTENANCE = 'ACTT_MA',
    UPGRADE = 'ACTT_UP',
    INSPECTION = 'ACTT_INSP',
    INSTALLATION = 'ACTT_INST',
    TEST = 'ACTT_TS',
    GUIDED_VISIT = 'ACTT_VS',
    REMOTE_INTERVENTION = 'ACTT_RI',
    MACHINING = 'ACTT_MCH',
    WELDING = 'ACTT_WLD',
    OPERATION = 'ACTT_OPE',
    RADIOGRAPHY = 'ACTT_RAD',
    PROFESSIONAL_VISIT = 'ACTT_PV',
    URGENT_INTERVENTION = 'ACTT_PIQ',
}

export const enum CernClassCode {
    CERN_CONTRACTOR_EMPLOYEE = 'ENTC',
}

export enum CommentLegacyType {
    DIMR_COMMENT = 'DIMR_COMMENT',
    DIMR_FEEDBACK = 'DIMR_FEEDBACK',
    DIMR_CLOSURE = 'DIMR_CLOSURE',
    VIC_COMMENT = 'VIC_COMMENT',
}

export enum ComputedVariableTypeDTO {
    IDENTITY = 'IDENTITY',
    EQUAL = 'EQUAL',
    NOT = 'NOT',
    AND = 'AND',
    OR = 'OR',
    ONE_OF = 'ONE_OF',
    CONCAT = 'CONCAT',
    CONDITIONAL = 'CONDITIONAL',
    DATE_GREATER_THAN = 'DATE_GREATER_THAN',
    DATE_ADD = 'DATE_ADD',
    NUMBER_GREATER_THAN = 'NUMBER_GREATER_THAN',
    NUMBER_GREATER_THAN_EQUAL = 'NUMBER_GREATER_THAN_EQUAL',
    STRING_LENGTH = 'STRING_LENGTH',
    COLLECTION_LENGTH = 'COLLECTION_LENGTH',
    IS_DISTINCT = 'IS_DISTINCT',
}

export const enum ContractTypeCode {
    CONTRACT = 'Contract',
    ORDER = 'Order',
}

export const enum DimrAlaraLevelCode {
    LEVEL_1 = 'DIMR_LEVEL1',
    LEVEL_2 = 'DIMR_LEVEL2',
    LEVEL_3 = 'DIMR_LEVEL3',
}

export const enum DimrStatusCode {
    DRAFT = 'DS_DRAFT',
    RP_VALIDATION = 'DS_RP_VALIDATION',
    VALIDATING = 'DS_VALIDATING',
    WAITING_APPROVAL = 'DS_WA',
    APPROVED = 'DS_APPROVED',
    IN_PROGRESS = 'DS_IP',
    LATE = 'DS_LATE',
    WAITING_FEEDBACK = 'DS_WFB',
    WAITING_WDP_CLOSURE = 'DS_WWDP',
    WAITING_DIMR_CLOSURE = 'DS_WDIMR',
    CLOSED = 'DS_CLOSED',
    CANCELLED = 'DS_CANCEL',
    REJECTED = 'DS_REJECT',
    OBSOLETE = 'DS_OBSOLETE',
}

export enum DocumentActionDTO {
    CREATE = 'CREATE',
    SAVE = 'SAVE',
    PERFORM = 'PERFORM',
}

export enum DocumentType {
    NDC = 'NDC',
    IS37 = 'IS37',
    IS37_CR = 'IS37_CR',
    FP = 'FP',
    DIMR = 'DIMR',
    RPA = 'RPA',
    WDP = 'WDP',
    LOK = 'LOK',
    VIC = 'VIC',
    ACTIVITY = 'ACTIVITY',
}

export const enum DurationTypeCode {
    DAY = 'DT_DAY',
    HOUR = 'DT_HOUR',
    MINUTE = 'DT_MINUTE',
}

export enum EnvironmentDTO {
    PRODUCTION = 'PRODUCTION',
    TEST = 'TEST',
    DEVELOPMENT = 'DEVELOPMENT',
    LOCAL = 'LOCAL',
}

export const enum FacilityCode {
    LHC = 'F_LHC',
    CMS = 'F_CMS',
    ATLAS = 'F_ATLAS',
    ALICE = 'F_ALICE',
    LHCB = 'F_LHCB',
    BOOSTER = 'F_BOOSTER',
    PS = 'F_PS',
    SPS = 'F_SPS',
    LEIR = 'F_LEIR',
    AD = 'F_AD',
    ADTARGET = 'F_ADTARGET',
    LINAC2 = 'F_LINAC2',
    LINAC3 = 'F_LINAC3',
    LINAC4 = 'F_LINAC4',
    HIRADMAT = 'F_HIRADMAT',
    EAST_TARGET = 'F_EASTTARGET',
    NTOF = 'F_NTOF',
    ISOLDE = 'F_ISOLDE',
    BA80 = 'F_BA80',
    SURFACE = 'F_SURFACE',
    COMPUTER_CENTRE = 'F_CC',
    ATELIER_109 = 'F_A109',
    GIF = 'F_GIF',
    NA62 = 'F_NA62',
    AWAKE = 'F_AWAKE',
    ELENA = 'F_ELENA',
    NEUTRINO_PLATFORM = 'F_NEUTRINO_PLATFORM',
    AD_HALL_EXPERIMENTS = 'F_AD_HALL_EXPERIMENTS',
    LIGHT = 'F_LIGHT',
    NORTH_AREA = 'F_NORTH_AREA_BEAM_LINES',
    MEDICIS = 'F_MEDICIS_AND_TARGET_AREA',
    CIXP = 'F_CIXP',
    HL_LHC = 'F_HL_LHC',
    HL_LHC_IT_STRING = 'F_HL_LHC_IT_STRING',
    DEMO = 'F_DEMO',
}

export enum FeatureFlag {
    V9 = 'V9',
    COMPLETE_TIMERS = 'COMPLETE_TIMERS',
}

export const enum FirePermitFaxLanguageCode {
    ENGLISH = 'FP_FL_ENGLISH',
    FRENCH = 'FP_FL_FRENCH',
}

export const enum FirePermitFireDetectionCode {
    EXISTS_OUTAGE_NECESSARY = 'FP_FD_EXISTS_OUTAGE',
    NOT_EXISTS = 'FP_FD_NOT_EXISTS',
}

export const enum FirePermitStatusCode {
    NEW = 'FPS_NEW',
    SAVED = 'FPS_SAVED',
    WAITING_APPROVAL = 'FPS_WFA',
    APPROVED = 'FPS_APPROVED',
    WAITING_END = 'FPS_WAITING_FP_END',
    FINISHED = 'FPS_FINISHED',
    CLOSED = 'FPS_CLOSED',
    REJECTED = 'FPS_REJECTED',
    CANCELLED = 'FPS_CANCELLED',
}

export const enum FirePermitWarningDeviceCode {
    OTHER = 'FP_WD_OTHER',
}

export const enum FirePermitWorkTypeCode {
    OTHER = 'FP_WT_OTHER',
    ELECTRIC_WELDING = 'FP_WT_EL_WELD',
    OXY_WELDING = 'FP_WT_OXY_WELD',
    ELECTRIC_CUTTING = 'FP_WT_EL_CUT',
    OXY_CUTTING = 'FP_WT_OXY_CUT',
    CUTTING_WITH_PROPANE_LANCE = 'FP_WT_CUT_PL',
    PLASMA_CUTTING = 'FP_WT_PLASMA_CUT',
    GRINDING_SECTIONING = 'FP_WT_GR_SEC',
    BLOWTORCH = 'FP_WT_BLOWTORCH',
}

export const enum FirePermitWorkerTypeCode {
    INTERNAL = 'FP_WORKER_CERN',
    EXTERNAL = 'FP_WORKER_COMPANY',
}

/**
 * Values:
 * - `CONTINUE`
 * - `SWITCHING_PROTOCOLS`
 * - `PROCESSING`
 * - `CHECKPOINT`
 * - `OK`
 * - `CREATED`
 * - `ACCEPTED`
 * - `NON_AUTHORITATIVE_INFORMATION`
 * - `NO_CONTENT`
 * - `RESET_CONTENT`
 * - `PARTIAL_CONTENT`
 * - `MULTI_STATUS`
 * - `ALREADY_REPORTED`
 * - `IM_USED`
 * - `MULTIPLE_CHOICES`
 * - `MOVED_PERMANENTLY`
 * - `FOUND`
 * - `MOVED_TEMPORARILY` - @deprecated
 * - `SEE_OTHER`
 * - `NOT_MODIFIED`
 * - `USE_PROXY` - @deprecated
 * - `TEMPORARY_REDIRECT`
 * - `PERMANENT_REDIRECT`
 * - `BAD_REQUEST`
 * - `UNAUTHORIZED`
 * - `PAYMENT_REQUIRED`
 * - `FORBIDDEN`
 * - `NOT_FOUND`
 * - `METHOD_NOT_ALLOWED`
 * - `NOT_ACCEPTABLE`
 * - `PROXY_AUTHENTICATION_REQUIRED`
 * - `REQUEST_TIMEOUT`
 * - `CONFLICT`
 * - `GONE`
 * - `LENGTH_REQUIRED`
 * - `PRECONDITION_FAILED`
 * - `PAYLOAD_TOO_LARGE`
 * - `REQUEST_ENTITY_TOO_LARGE` - @deprecated
 * - `URI_TOO_LONG`
 * - `REQUEST_URI_TOO_LONG` - @deprecated
 * - `UNSUPPORTED_MEDIA_TYPE`
 * - `REQUESTED_RANGE_NOT_SATISFIABLE`
 * - `EXPECTATION_FAILED`
 * - `I_AM_A_TEAPOT`
 * - `INSUFFICIENT_SPACE_ON_RESOURCE` - @deprecated
 * - `METHOD_FAILURE` - @deprecated
 * - `DESTINATION_LOCKED` - @deprecated
 * - `UNPROCESSABLE_ENTITY`
 * - `LOCKED`
 * - `FAILED_DEPENDENCY`
 * - `TOO_EARLY`
 * - `UPGRADE_REQUIRED`
 * - `PRECONDITION_REQUIRED`
 * - `TOO_MANY_REQUESTS`
 * - `REQUEST_HEADER_FIELDS_TOO_LARGE`
 * - `UNAVAILABLE_FOR_LEGAL_REASONS`
 * - `INTERNAL_SERVER_ERROR`
 * - `NOT_IMPLEMENTED`
 * - `BAD_GATEWAY`
 * - `SERVICE_UNAVAILABLE`
 * - `GATEWAY_TIMEOUT`
 * - `HTTP_VERSION_NOT_SUPPORTED`
 * - `VARIANT_ALSO_NEGOTIATES`
 * - `INSUFFICIENT_STORAGE`
 * - `LOOP_DETECTED`
 * - `BANDWIDTH_LIMIT_EXCEEDED`
 * - `NOT_EXTENDED`
 * - `NETWORK_AUTHENTICATION_REQUIRED`
 */
export enum HttpStatus {
    CONTINUE = 'CONTINUE',
    SWITCHING_PROTOCOLS = 'SWITCHING_PROTOCOLS',
    PROCESSING = 'PROCESSING',
    CHECKPOINT = 'CHECKPOINT',
    OK = 'OK',
    CREATED = 'CREATED',
    ACCEPTED = 'ACCEPTED',
    NON_AUTHORITATIVE_INFORMATION = 'NON_AUTHORITATIVE_INFORMATION',
    NO_CONTENT = 'NO_CONTENT',
    RESET_CONTENT = 'RESET_CONTENT',
    PARTIAL_CONTENT = 'PARTIAL_CONTENT',
    MULTI_STATUS = 'MULTI_STATUS',
    ALREADY_REPORTED = 'ALREADY_REPORTED',
    IM_USED = 'IM_USED',
    MULTIPLE_CHOICES = 'MULTIPLE_CHOICES',
    MOVED_PERMANENTLY = 'MOVED_PERMANENTLY',
    FOUND = 'FOUND',
    /**
     * @deprecated
     */
    MOVED_TEMPORARILY = 'MOVED_TEMPORARILY',
    SEE_OTHER = 'SEE_OTHER',
    NOT_MODIFIED = 'NOT_MODIFIED',
    /**
     * @deprecated
     */
    USE_PROXY = 'USE_PROXY',
    TEMPORARY_REDIRECT = 'TEMPORARY_REDIRECT',
    PERMANENT_REDIRECT = 'PERMANENT_REDIRECT',
    BAD_REQUEST = 'BAD_REQUEST',
    UNAUTHORIZED = 'UNAUTHORIZED',
    PAYMENT_REQUIRED = 'PAYMENT_REQUIRED',
    FORBIDDEN = 'FORBIDDEN',
    NOT_FOUND = 'NOT_FOUND',
    METHOD_NOT_ALLOWED = 'METHOD_NOT_ALLOWED',
    NOT_ACCEPTABLE = 'NOT_ACCEPTABLE',
    PROXY_AUTHENTICATION_REQUIRED = 'PROXY_AUTHENTICATION_REQUIRED',
    REQUEST_TIMEOUT = 'REQUEST_TIMEOUT',
    CONFLICT = 'CONFLICT',
    GONE = 'GONE',
    LENGTH_REQUIRED = 'LENGTH_REQUIRED',
    PRECONDITION_FAILED = 'PRECONDITION_FAILED',
    PAYLOAD_TOO_LARGE = 'PAYLOAD_TOO_LARGE',
    /**
     * @deprecated
     */
    REQUEST_ENTITY_TOO_LARGE = 'REQUEST_ENTITY_TOO_LARGE',
    URI_TOO_LONG = 'URI_TOO_LONG',
    /**
     * @deprecated
     */
    REQUEST_URI_TOO_LONG = 'REQUEST_URI_TOO_LONG',
    UNSUPPORTED_MEDIA_TYPE = 'UNSUPPORTED_MEDIA_TYPE',
    REQUESTED_RANGE_NOT_SATISFIABLE = 'REQUESTED_RANGE_NOT_SATISFIABLE',
    EXPECTATION_FAILED = 'EXPECTATION_FAILED',
    I_AM_A_TEAPOT = 'I_AM_A_TEAPOT',
    /**
     * @deprecated
     */
    INSUFFICIENT_SPACE_ON_RESOURCE = 'INSUFFICIENT_SPACE_ON_RESOURCE',
    /**
     * @deprecated
     */
    METHOD_FAILURE = 'METHOD_FAILURE',
    /**
     * @deprecated
     */
    DESTINATION_LOCKED = 'DESTINATION_LOCKED',
    UNPROCESSABLE_ENTITY = 'UNPROCESSABLE_ENTITY',
    LOCKED = 'LOCKED',
    FAILED_DEPENDENCY = 'FAILED_DEPENDENCY',
    TOO_EARLY = 'TOO_EARLY',
    UPGRADE_REQUIRED = 'UPGRADE_REQUIRED',
    PRECONDITION_REQUIRED = 'PRECONDITION_REQUIRED',
    TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
    REQUEST_HEADER_FIELDS_TOO_LARGE = 'REQUEST_HEADER_FIELDS_TOO_LARGE',
    UNAVAILABLE_FOR_LEGAL_REASONS = 'UNAVAILABLE_FOR_LEGAL_REASONS',
    INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
    NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',
    BAD_GATEWAY = 'BAD_GATEWAY',
    SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE',
    GATEWAY_TIMEOUT = 'GATEWAY_TIMEOUT',
    HTTP_VERSION_NOT_SUPPORTED = 'HTTP_VERSION_NOT_SUPPORTED',
    VARIANT_ALSO_NEGOTIATES = 'VARIANT_ALSO_NEGOTIATES',
    INSUFFICIENT_STORAGE = 'INSUFFICIENT_STORAGE',
    LOOP_DETECTED = 'LOOP_DETECTED',
    BANDWIDTH_LIMIT_EXCEEDED = 'BANDWIDTH_LIMIT_EXCEEDED',
    NOT_EXTENDED = 'NOT_EXTENDED',
    NETWORK_AUTHENTICATION_REQUIRED = 'NETWORK_AUTHENTICATION_REQUIRED',
}

export const enum IS37AlarmDisablementTypeCode {
    PERMANENT_DISABLING = 'ADT_PD',
    TEMPORARY_DISABLING_FULL_PERIOD = 'ADT_FP',
    TEMPORARY_DISABLING_DETECTION_NIGHT_WEEKEND = 'ADT_EN',
    TEMPORARY_DISABLING_DETECTION_WEEKEND = 'ADT_OW',
    UNSCHEDULED_DISABLING_BREAKDOWN = 'ADT_UDB',
    UNSCHEDULED_DISABLING_URGENT = 'ADT_UDU',
}

export const enum IS37AlarmTypeCode {
    FLAMMABLE_GAS = 'ISAT_FLAMMABLE_GAS',
    PRESENCE_OF_SMOKE = 'ISAT_SMOKE',
}

export const enum IS37ApprovalRoutingCode {
    WITH_TSO = 'AR_SRL',
    WITH_FRS_CCC_TI = 'AR_FANCT',
}

export const enum IS37StatusCode {
    DRAFT = 'IS_DRAFT',
    WAITING_FOR_APPROVAL = 'IS_WA',
    APPROVED = 'IS_APPROVED',
    WAITING_FOR_DISABLING = 'IS_WD',
    DISABLED = 'IS_DISABLED',
    WAITING_FOR_SCHEDULING = 'IS_WS',
    SCHEDULED = 'IS_SCHEDULED',
    WAITING_FOR_RECOMMISSIONING = 'IS_WR',
    RECOMMISSIONED = 'IS_RECOMMISSIONED',
    CLOSED = 'IS_CLOSED',
    CANCELLED = 'IS_CANCELLED',
    REJECTED = 'IS_REJECTED',
}

export const enum IS37WorkTypeCode {
    OTHER = 'IWT_OTHERS',
    ELECTRIC_WELDING = 'IWT_EW',
    OXY_WELDING = 'IWT_OW',
    ELECTRIC_CUTTING = 'IWT_EC',
    OXY_CUTTING = 'IWT_OC',
    PROPANE_LANCE_CUTTING = 'IWT_CPL',
    PLASMA_CUTTING = 'IWT_PC',
    GRINDING_SECTIONING = 'IWT_GS',
    BLOWTORCH = 'IWT_WB',
}

export const enum ImpactRoleCode {
    IMPACT_ADMIN = 'ROLE_IMP_ADMIN',
    IMPACT_SAFETY_COORDINATOR = 'ROLE_IMP_SAF_COO',
    IMPACT_LOCATION_COORDINATOR = 'ROLE_IMP_LOC_COO',
    IMPACT_MEETING_RESPONSIBLE = 'ROLE_IMP_MEETING_RES',
    IMPACT_SYSTEM_APPROVER = 'ROLE_IMP_SYS_APP',
    IMPACT_SYSTEM_RESPONSIBLE = 'ROLE_IMP_SYS_RES',
    IMPACT_FACILITY_COORDINATOR = 'ROLE_IMP_FAC_COO',
    IMPACT_FACILITY_SAFETY_APPROVER = 'ROLE_IMP_FAC_SAF_APP',
    IMPACT_FACILITY_OPERATOR = 'ROLE_IMP_FAC_OP',
    IMPACT_FACILITY_RP = 'ROLE_IMP_IMP_FAC_RP',
    IMPACT_RP_ADMIN = 'ROLE_IMP_RP_ADMIN',
    FACILITY_ADMIN = 'ROLE_FAC_ADM',
    IMPACT_AUDIT = 'ROLE_IMP_AUDIT',
    SITE_COORDINATOR = 'ROLE_SITE_COORD',
    VSO = 'ROLE_VSO',
    PRT_RO_CERN_USER = 'ROLE_PRT_RO_CERN_USER',
    GSAO = 'ROLE_GSAO',
    EROS_ADVISOR = 'ROLE_EROS_ADVISOR',
    HSE_VIC_ADVISOR = 'ROLE_HSE_VIC_ADVISOR',
    SITE_COORD_READ = 'ROLE_SITE_COORD_READ',
    NDC_GROUP_APPROVER = 'ROLE_NDC_GROUP_APPROVER',
    NDC_GROUP_EDITOR = 'ROLE_NDC_GROUP_EDITOR',
    RSO = 'ROLE_RSO',
}

export const enum InterventionPeriodTypeCode {
    N_A = 'IP_NA',
    EXTENDED_TECHNICAL_STOP = 'IP_ETS',
    TECHNICAL_STOP = 'IP_TS',
    SHUTDOWN = 'IP_SD',
    OPERATION = 'IP_OP',
    ANY_TIME = 'IP_ANY',
}

export const enum Is37ChangeRequestStatusCode {
    REQUESTED = 'ICR_REQUESTED',
    ACCEPTED = 'ICR_ACCEPTED',
    REJECTED = 'ICR_REJECTED',
    CANCELLED = 'ICR_CANCELLED',
    NEW = 'ICR_NEW',
}

export const enum LanguageCode {
    FRENCH = 'LANG_FRENCH',
    ENGLISH = 'LANG_ENGLISH',
}

export const enum LinkedDocumentCode {
    TRANSPORT_REQUEST = 'TRANSPORT_REQUEST',
    JMT_DOCUMENT = 'JMT_DOCUMENT',
    EDMS_PROCEDURE = 'EDMS_PROCEDURE',
}

export const enum LocationTypeCode {
    ROOM = 'LOCAL',
    FLOOR = 'ETAGE',
    MACHINE = 'MACHINE',
    BUILDING = 'BATIMENT',
    GREEN_SPACE = 'ESPACE_VERT',
    STRUCTURE = 'OUVRAGE',
    GROUPING_FACILITY_NODE = 'GROUPING_FACILITY_NODE',
    UNDERGROUND_EQUIPMENT = 'EQUIP_SOUT',
    BARAQUE = 'BARAQUE',
    ROAD = 'VOIRIE',
    CUSTOM_POINT_GAS = 'CUSTOM_POINT GAZ',
    ZONE = 'ZONE',
    CUSTOM_EXPERIMENT = 'CUSTOM_EXPERIMENT',
    GROUPING_FACILITY = 'GROUPING_FACILITY',
    CUSTOM_ZONE_SECURITY = 'CUSTOM_ZONE SÉCURITÉ',
    SITE = 'SITE',
}

export enum LockoutActionDTO {
    CREATE = 'CREATE',
    SAVE = 'SAVE',
    PERFORM = 'PERFORM',
    COMPLETE_WORK = 'COMPLETE_WORK',
    LOCK = 'LOCK',
    UNLOCK = 'UNLOCK',
    PROVIDE_YELLOW_PAPER = 'PROVIDE_YELLOW_PAPER',
    CONFIRM_RECEPTION_YELLOW_PAPER = 'CONFIRM_RECEPTION_YELLOW_PAPER',
    RETURN_YELLOW_PAPER = 'RETURN_YELLOW_PAPER',
    CONFIRM_RETURN_YELLOW_PAPER = 'CONFIRM_RETURN_YELLOW_PAPER',
}

export const enum LockoutStatusCode {
    NEW = 'LOCK_NEW',
    SAVED = 'LOCK_SAVED',
    SUBMITTED = 'LOCK_SUBMITTED',
    WAITING_LOCKOUT_APPROVAL = 'LOCK_WAITING_LOCKOUT_APPROVAL',
    LOCKOUT_APPROVED = 'LOCK_LOCKOUT_APPROVED',
    WAITING_UNLOCK_APPROVAL = 'LOCK_WAITING_UNLOCK_APPROVAL',
    FINISHED = 'LOCK_FINISHED',
    REJECTED = 'LOCK_REJECTED',
    CANCELLED = 'LOCK_CANCELLED',
    WAITING_FOR_APPROVAL = 'LOCK_WAITING_APPROVAL',
    LOCKED = 'LOCK_LOCKED',
}

export const enum LockoutTypeCode {
    ELECTRICAL = 'LOCK_ELECTRICAL',
    CRYO = 'LOCK_CRYO',
    ELECTRICAL_ID = '1444088',
    CRYO_ID = '1444087',
}

export const enum NdcDistributionTypeCode {
    EGROUP = 'NDC_DT_E_GROUP',
    EMAIL = 'NDC_DT_E_MAIL',
    PERSON = 'NDC_DT_PERSON',
}

export const enum NdcRoleCode {
    APPROVER = 'NDC_GROUP_APPROVER',
    EDITOR = 'NDC_GROUP_EDITOR',
}

export const enum NdcSeverityCode {
    DEGRADATION = 'NDC_SVR_DEGRADATION',
    CUT = 'NDC_SVR_CUT',
}

export const enum NdcStatusCode {
    SAVED = 'NDC_S_SAVED',
    WAITING_FOR_APPROVAL = 'NDC_S_WA',
    APPROVED = 'NDC_S_APPROVED',
    REJECTED = 'NDC_S_REJECTED',
    DISTRIBUTED = 'NDC_S_DISTRIBUTED',
    CANCELLED = 'NDC_S_CANCELLED',
    IN_PROGRESS = 'NDC_S_IP',
    FINISHED = 'NDC_S_FINISHED',
    WAITING_NOTIFY_CANCEL = 'NDC_S_WNOTIFY_CANCEL',
    WAITING_NOTIFY_FINISH = 'NDC_S_WNOTIFY_FINISH',
}

export const enum OrgUnitTypeCode {
    DEPARTMENT = 'DEPART',
    GROUP = 'GROUP',
    SECTION = 'SECT',
}

export const enum ParticipantRoleCode {
    RP_CONTACT = 'PR_RP',
    EXECUTANT = 'PR_EXECUTANT',
    PV_ORGANIZER = 'PR_PV_ORGANIZER',
    PV_VISITOR = 'PR_PV_VISITOR',
    COMPANY = 'PR_COMPANY',
    SUPERVISOR = 'PR_SUPERVISOR',
    ESCORT = 'PR_ESCORT',
    VISITOR = 'PR_VISITOR',
    RADIOLOGIST = 'PR_RADIOLOGIST',
    CONSULTANT = 'PR_CONSULTANT',
    TSO = 'PR_TSO',
    EROS_ADVISOR = 'PR_EROS_ADVISOR',
    HSE_ADVISOR = 'PR_HSE_VIC_ADVISOR',
    LOCATION_RESPONSIBLE = 'PR_LR',
}

export const enum RadiationAreaClassificationStatusCode {
    LEVEL_0 = 'RAC_0',
    LEVEL_1 = 'RAC_1',
    LEVEL_2 = 'RAC_2',
    LEVEL_3 = 'RAC_3',
    LEVEL_4 = 'RAC_4',
    LEVEL_5 = 'RAC_5',
}

export const enum RoleCode {
    ALARA_SCIENTIFIC_SECRETARY = 'ALARA-SC-SEC',
    ALARM = 'ALARM',
    ALARM_TECHNICAL_EXECUTOR = 'ALARM_TECH_EXEC',
    DDH = 'DDH',
    DDSO = 'DDSO',
    DEPUTY_ALARA_SCIENTIFIC_SECRETARY = 'DPT-ALARA-SC-SEC',
    DG = 'DG',
    DDG = 'DDG',
    DGL = 'DGL',
    DH = 'DH',
    DLEXGLIMOS = 'DLEXGLIMOS',
    DRSO = 'DRSO',
    DSO = 'DSO',
    DTSO = 'DTSO',
    EROS_ADVISOR = 'EROS_ADVISOR',
    FACILITY_RP = 'FACILITY_RP',
    FACILITY_SAFETY_APPROVER = 'FACILITY_SAFETY_APPR',
    FGSO = 'FGSO',
    GL = 'GL',
    GSAO = 'GSAO',
    HSE_VIC_ADVISOR = 'HSE_VIC_ADVISOR',
    LEXGLIMOS = 'LEXGLIMOS',
    LOCKOUT_CRYOGENIC = 'LOCKOUT_CRYOGENIC',
    LOCKOUT_ELECTRICAL = 'LOCKOUT_ELECTRICAL',
    LOCATION_RESPONSIBLE = 'LR',
    NDC_GROUP_APPROVER = 'NDC_GROUP_APPROVER',
    NDC_GROUP_EDITOR = 'NDC_GROUP_EDITOR',
    PCR = 'PCR',
    RADIOLOGIST = 'RADIOLOGIST',
    RPE = 'RPE',
    RPO = 'RPO',
    RPR = 'RPR',
    RPVNR = 'RPVNR',
    RP_DOSES_ACCESS = 'RP_DOSES_ACCESS',
    RSO = 'RSO',
    RSSO = 'RSSO',
    SITE_COORDINATOR = 'SITE_COORD',
    SITE_COORDINATOR_READ = 'SITE_COORD_READ',
    SL = 'SL',
    TSO = 'TSO',
}

export const enum RoleTargetCode {
    CERN = 'CERN',
}

export enum SavedFilterType {
    NDC = 'NDC',
    IS37 = 'IS37',
    FP = 'FP',
    DIMR = 'DIMR',
    WDP = 'WDP',
    LOK = 'LOK',
    VIC = 'VIC',
    ACTIVITY = 'ACTIVITY',
    TASKS = 'TASKS',
}

export enum TaskAction {
    ADD = 'ADD',
    APPROVE = 'APPROVE',
    CANCEL = 'CANCEL',
    COMPLETE = 'COMPLETE',
    CREATE = 'CREATE',
    FINISH = 'FINISH',
    NEW_VERSION = 'NEW_VERSION',
    NO_SENSORS = 'NO_SENSORS',
    REJECT = 'REJECT',
    SCHEDULED = 'SCHEDULED',
    SUBMITTED = 'SUBMITTED',
}

export const enum TaskCode {
    ACTIVITY_EXTRA_APPROVAL = 'activityExtraApproval',
    ACTIVITY_FACILITY_COORDINATOR_SCHEDULE = 'activityFacilityCoordinatorSchedule',
    ACTIVITY_FACILITY_COORDINATOR_SIGNATURE = 'activityFacilityCoordinatorSignature',
    ACTIVITY_FACILITY_SAFETY_APPROVER_SIGNATURE = 'activityFacilitySafetyApproverSignature',
    ACTIVITY_GSAO_SIGNATURE = 'activityGsaoSignature',
    ACTIVITY_LOCATION_COORDINATOR_SIGNATURE = 'activityLocationCoordinatorSignature',
    ACTIVITY_LR_SIGNATURE = 'activityLrSignature',
    ACTIVITY_RPO_SIGNATURE = 'activityRpoSignature',
    ACTIVITY_RP_SIGNATURE = 'activityRpSignature',
    ACTIVITY_SOME_SIGNATURE = 'activitySomeSignature',
    ACTIVITY_SYSTEM_APPROVER_SIGNATURE = 'activitySystemApproverSignature',
    ACTIVITY_VISIT_SAFETY_APPROVAL = 'activityVisitSafetyApproval',
    ADD_IS37_SENSORS = 'addIs37Sensors',
    ADD_IS37_SENSORS_TECH = 'addIs37SensorsTech',
    ADD_SIGNATURE_TASK = 'addSignatureTask',
    ALARM_RESPONSIBLE_SIGNATURE = 'alarmResponsibleSignature',
    CANCEL_ACTIVITY = 'cancelActivity',
    CANCEL_CHANGE_REQUEST = 'cancelChangeRequest',
    CANCEL_CRYO_LOCKOUT = 'cancelCryoLockout',
    CANCEL_ELECTRICAL_LOCKOUT = 'cancelElectricalLockout',
    CANCEL_IS37 = 'cancelIs37',
    CANCEL_IS37_ALARM_RESP = 'cancelIs37AlarmResp',
    CANCEL_IS37_ALARM_RESP_APPROVAL = 'cancelIs37AlarmRespApproval',
    CANCEL_IS37_CHANGE_REQUEST = 'cancelIs37ChangeRequest',
    CANCEL_IS37_CREATOR = 'cancelIs37Creator',
    CANCEL_IS37_TI = 'cancelIs37TI',
    CANCEL_IS37_TI_APPROVAL = 'cancelIs37TIApproval',
    CANCEL_VIC = 'cancelVic',
    CHANGE_ALARM_TYPE = 'changeAlarmType',
    CHANGE_ALARM_TYPE_TECH = 'changeAlarmTypeTech',
    CLOSE_ACTIVITY = 'closeActivity',
    CREATE_CHANGE_REQUEST = 'createChangeRequest',
    CREATE_IS37_CHANGE_REQUEST = 'createIs37ChangeRequest',
    CREATOR_COMPLETE_WORK = 'creatorCompleteWork',
    CRYO_RESPONSIBLE_APPROVAL = 'cryoResponsibleApproval',
    CRYO_RESPONSIBLE_LOCK = 'cryoResponsibleLock',
    CRYO_RESPONSIBLE_UNLOCK = 'cryoResponsibleUnlock',
    DDSO_SIGNATURE = 'ddsoSignature',
    DIMR_ALARA2_SIGNATURE_DDSO = 'dimrAlara2SignatureDdso',
    DIMR_ALARA2_SIGNATURE_DGL_HSE = 'dimrAlara2SignatureDglHse',
    DIMR_ALARA2_SIGNATURE_DIMR_RPR = 'dimrAlara2SignatureDimrRpr',
    DIMR_ALARA2_SIGNATURE_DIMR_RPR_DGL = 'dimrAlara2SignatureDimrRprDgl',
    DIMR_ALARA2_SIGNATURE_DIMR_RPR_GL = 'dimrAlara2SignatureDimrRprGl',
    DIMR_ALARA2_SIGNATURE_DIMR_RPR_SL = 'dimrAlara2SignatureDimrRprSl',
    DIMR_ALARA2_SIGNATURE_DIMR_RSO = 'dimrAlara2SignatureDimrRso',
    DIMR_ALARA2_SIGNATURE_DLEXGLIMOS = 'dimrAlara2SignatureDlexglimos',
    DIMR_ALARA2_SIGNATURE_DRSO = 'dimrAlara2SignatureDrso',
    DIMR_ALARA2_SIGNATURE_DSO = 'dimrAlara2SignatureDso',
    DIMR_ALARA2_SIGNATURE_EXP_DLEXGLIMOS = 'dimrAlara2SignatureExpDlexglimos',
    DIMR_ALARA2_SIGNATURE_EXP_DPT_TECH_COORD = 'dimrAlara2SignatureExpDptTechCoord',
    DIMR_ALARA2_SIGNATURE_EXP_DRSO = 'dimrAlara2SignatureExpDrso',
    DIMR_ALARA2_SIGNATURE_EXP_LEXGLIMOS = 'dimrAlara2SignatureExpLexglimos',
    DIMR_ALARA2_SIGNATURE_EXP_RSO = 'dimrAlara2SignatureExpRso',
    DIMR_ALARA2_SIGNATURE_EXP_TECH_COORD = 'dimrAlara2SignatureExpTechCoord',
    DIMR_ALARA2_SIGNATURE_GL_HSE = 'dimrAlara2SignatureGlHse',
    DIMR_ALARA2_SIGNATURE_LEXGLIMOS = 'dimrAlara2SignatureLexglimos',
    DIMR_ALARA2_SIGNATURE_OWNERS_DDH = 'dimrAlara2SignatureOwnersDdh',
    DIMR_ALARA2_SIGNATURE_OWNERS_DGL = 'dimrAlara2SignatureOwnersDgl',
    DIMR_ALARA2_SIGNATURE_OWNERS_DH = 'dimrAlara2SignatureOwnersDh',
    DIMR_ALARA2_SIGNATURE_OWNERS_GL = 'dimrAlara2SignatureOwnersGl',
    DIMR_ALARA2_SIGNATURE_RSO = 'dimrAlara2SignatureRso',
    DIMR_ALARA2_SIGNATURE_RSO_HSE = 'dimrAlara2SignatureRsoHse',
    DIMR_APPROVAL_EDIT = 'dimrApprovalEdit',
    DIMR_CANCEL = 'dimrCancel',
    DIMR_CLOSURE = 'dimrClosure',
    DIMR_CLOSURE_SIGNATURE_DDSO = 'dimrClosureSignatureDdso',
    DIMR_CLOSURE_SIGNATURE_DIMR_RPO = 'dimrClosureSignatureDimrRpo',
    DIMR_CLOSURE_SIGNATURE_DIMR_RSO = 'dimrClosureSignatureDimrRso',
    DIMR_CLOSURE_SIGNATURE_DLEXGLIMOS = 'dimrClosureSignatureDlexglimos',
    DIMR_CLOSURE_SIGNATURE_DRSO = 'dimrClosureSignatureDrso',
    DIMR_CLOSURE_SIGNATURE_DSO = 'dimrClosureSignatureDso',
    DIMR_CLOSURE_SIGNATURE_LEXGLIMOS = 'dimrClosureSignatureLexglimos',
    DIMR_CLOSURE_SIGNATURE_LOCATION_RPO = 'dimrClosureSignatureLocationRpo',
    DIMR_CLOSURE_SIGNATURE_RSO = 'dimrClosureSignatureRso',
    DIMR_FEEDBACK = 'dimrFeedback',
    DIMR_FEEDBACK_SIGNATURE_DDSO = 'dimrFeedbackSignatureDdso',
    DIMR_FEEDBACK_SIGNATURE_DIMR_RPO = 'dimrFeedbackSignatureDimrRpo',
    DIMR_FEEDBACK_SIGNATURE_DIMR_RSO = 'dimrFeedbackSignatureDimrRso',
    DIMR_FEEDBACK_SIGNATURE_DRSO = 'dimrFeedbackSignatureDrso',
    DIMR_FEEDBACK_SIGNATURE_DSO = 'dimrFeedbackSignatureDso',
    DIMR_FEEDBACK_SIGNATURE_EXP_DLEXGLIMOS = 'dimrFeedbackSignatureExpDlexglimos',
    DIMR_FEEDBACK_SIGNATURE_EXP_DRSO = 'dimrFeedbackSignatureExpDrso',
    DIMR_FEEDBACK_SIGNATURE_EXP_LEXGLIMOS = 'dimrFeedbackSignatureExpLexglimos',
    DIMR_FEEDBACK_SIGNATURE_EXP_RSO = 'dimrFeedbackSignatureExpRso',
    DIMR_FEEDBACK_SIGNATURE_LEXGLIMOS = 'dimrFeedbackSignatureLexglimos',
    DIMR_FEEDBACK_SIGNATURE_OWNER = 'dimrFeedbackSignatureOwner',
    DIMR_FEEDBACK_SIGNATURE_OWNER_DGL = 'dimrFeedbackSignatureOwnerDgl',
    DIMR_FEEDBACK_SIGNATURE_OWNER_GL = 'dimrFeedbackSignatureOwnerGl',
    DIMR_FEEDBACK_SIGNATURE_OWNER_SL = 'dimrFeedbackSignatureOwnerSl',
    DIMR_FEEDBACK_SIGNATURE_RPO = 'dimrFeedbackSignatureRpo',
    DIMR_FEEDBACK_SIGNATURE_RPR = 'dimrFeedbackSignatureRpr',
    DIMR_FEEDBACK_SIGNATURE_RSO = 'dimrFeedbackSignatureRso',
    DIMR_FEEDBACK_SIGNATURE_RSSO_RPE = 'dimrFeedbackSignatureRssoRpe',
    DIMR_IN_PROGRESS_EDIT = 'dimrInProgressEdit',
    DIMR_NEW_VERSION = 'dimrNewVersion',
    DIMR_OWNER_SIGNATURE = 'dimrOwnerSignature',
    DIMR_RP_COMMENT = 'dimrRpComment',
    DIMR_RP_VALIDATION_EDIT = 'dimrRpValidationEdit',
    DIMR_RSO_COMMENT = 'dimrRsoComment',
    DIMR_SUBMIT = 'dimrSubmit',
    DIMR_VALIDATION_SIGNATURE_LOCATION_RPO = 'dimrValidationSignatureLocationRpo',
    DIMR_VALIDATION_SIGNATURE_LOCATION_RPR = 'dimrValidationSignatureLocationRpr',
    DIMR_VALIDATION_SIGNATURE_RPE = 'dimrValidationSignatureRpe',
    DIMR_VALIDATION_SIGNATURE_RPO = 'dimrValidationSignatureRpo',
    DIMR_VALIDATION_SIGNATURE_RPR = 'dimrValidationSignatureRpr',
    DIMR_VALIDATION_SIGNATURE_RSSO_RPE = 'dimrValidationSignatureRssoRpe',
    DISABLE_SENSORS = 'disableSensors',
    DISABLE_SENSORS_RESPONSIBLE = 'disableSensorsResponsible',
    DISABLE_SENSORS_TI = 'disableSensorsTI',
    DLEXGLIMOS_SIGNATURE = 'dlexglimosSignature',
    DLR_SIGNATURE = 'dlrSignature',
    DSO_SIGNATURE = 'dsoSignature',
    DTSO_SIGNATURE = 'dtsoSignature',
    DTSO_SIGNATURE_EXPERIMENT = 'dtsoSignatureExperiment',
    EDIT_ACTIVITY = 'editActivity',
    EDIT_CRYO_LOCKOUT = 'editCryoLockout',
    EDIT_ELECTRICAL_LOCKOUT = 'editElectricalLockout',
    EDIT_IS37 = 'editIs37',
    EDIT_SENSORS = 'editSensors',
    EDIT_SENSORS_ALARM_RESP = 'editSensorsAlarmResp',
    EDIT_SENSORS_TECH = 'editSensorsTech',
    ELECTRICAL_CONFIRM_YELLOW_PAPER_RECEPTION = 'electricalConfirmYellowPaperReception',
    ELECTRICAL_PROVIDE_YELLOW_PAPER = 'electricalProvideYellowPaper',
    ELECTRICAL_RECEIVE_YELLOW_PAPER = 'electricalReceiveYellowPaper',
    ELECTRICAL_RESPONSIBLE_APPROVAL = 'electricalResponsibleApproval',
    ELECTRICAL_RESPONSIBLE_LOCK = 'electricalResponsibleLock',
    ELECTRICAL_RESPONSIBLE_UNLOCK = 'electricalResponsibleUnlock',
    ELECTRICAL_RETURN_YELLOW_PAPER = 'electricalReturnYellowPaper',
    FP_ATTACH = 'fpAttach',
    FP_CANCEL = 'fpCancel',
    FP_CLOSEOUT = 'fpCloseout',
    FP_EDIT = 'fpEdit',
    FP_SIGNATURE_RESPONSIBLE = 'fpSignatureResponsible',
    FP_SIGNATURE_RPO = 'fpSignatureRpo',
    FP_SIGNATURE_RSO = 'fpSignatureRso',
    FP_SUBMIT = 'fpSubmit',
    FRS_SIGNATURE = 'frsSignature',
    GREEN_SPACE_RESP_SIGNATURE = 'greenSpaceRespSignature',
    GSAO_SIGNATURE = 'gsaoSignature',
    INTERRUPT_ACTIVITY = 'interruptActivity',
    LEXGLIMOS_SIGNATURE = 'lexglimosSignature',
    LR_SIGNATURE = 'lrSignature',
    NDC_CANCEL_CREATOR = 'ndcCancelCreator',
    NDC_CANCEL_DISTRIBUTED_APPROVER = 'ndcCancelDistributedApprover',
    NDC_CANCEL_DISTRIBUTED_CREATOR = 'ndcCancelDistributedCreator',
    NDC_EDIT = 'ndcEdit',
    NDC_MANUAL_FINISH = 'ndcManualFinish',
    NDC_POOL = 'ndcPool',
    NDC_SUBMIT = 'ndcSubmit',
    RECOMMISSION_SENSORS = 'recommissionSensors',
    RECOMMISSION_SENSORS_RESPONSIBLE = 'recommissionSensorsResponsible',
    RECOMMISSION_SENSORS_TI = 'recommissionSensorsTI',
    RESPONSIBLE_SIGNATURE = 'responsibleSignature',
    RESUME_ACTIVITY = 'resumeActivity',
    ROAD_RESP_SIGNATURE = 'roadRespSignature',
    SCHEDULE_RECOMMISSIONING = 'scheduleRecommissioning',
    SUBMIT_ACTIVITY = 'submitActivity',
    SUBMIT_CRYO_LOCKOUT = 'submitCryoLockout',
    SUBMIT_ELECTRICAL_LOCKOUT = 'submitElectricalLockout',
    SUBMIT_IS37 = 'submitIs37',
    SUBMIT_VIC = 'submitVic',
    TSO_SIGNATURE = 'tsoSignature',
    TSO_SIGNATURE_EXPERIMENT = 'tsoSignatureExperiment',
    VIC_COMMENT = 'vicComment',
    VIC_SIGNATURE = 'VicSignature',
}

export enum TaskType {
    ACTIVITY_INTERRUPT = 'ACTIVITY_INTERRUPT',
    ACTIVITY_RESUME = 'ACTIVITY_RESUME',
    ADD_COMMENT = 'ADD_COMMENT',
    ADD_IS37_SENSORS = 'ADD_IS37_SENSORS',
    ADD_SIGNATURE_TASK = 'ADD_SIGNATURE_TASK',
    ATTACH_IS37 = 'ATTACH_IS37',
    CANCEL = 'CANCEL',
    CANCEL_CHANGE_REQUEST = 'CANCEL_CHANGE_REQUEST',
    CHANGE_ALARM_TYPE = 'CHANGE_ALARM_TYPE',
    CLOSE = 'CLOSE',
    COMPLETE_WORK = 'COMPLETE_WORK',
    CONFIRM_RECEPTION_YELLOW_PAPER = 'CONFIRM_RECEPTION_YELLOW_PAPER',
    CONFIRM_RETURN_YELLOW_PAPER = 'CONFIRM_RETURN_YELLOW_PAPER',
    CREATE_CHANGE_REQUEST = 'CREATE_CHANGE_REQUEST',
    CREATE_IS37_CHANGE_REQUEST = 'CREATE_IS37_CHANGE_REQUEST',
    DIMR_CLOSURE = 'DIMR_CLOSURE',
    DIMR_FEEDBACK = 'DIMR_FEEDBACK',
    DIMR_NEW_VERSION = 'DIMR_NEW_VERSION',
    DISABLE_SENSORS = 'DISABLE_SENSORS',
    EDIT = 'EDIT',
    EDIT_SENSORS = 'EDIT_SENSORS',
    FACILITY_COORDINATOR_SCHEDULE = 'FACILITY_COORDINATOR_SCHEDULE',
    FINISH = 'FINISH',
    FP_CLOSEOUT = 'FP_CLOSEOUT',
    LOCK_LOCKOUT = 'LOCK_LOCKOUT',
    PROVIDE_YELLOW_PAPER = 'PROVIDE_YELLOW_PAPER',
    RECOMMISSION_SENSORS = 'RECOMMISSION_SENSORS',
    RETURN_YELLOW_PAPER = 'RETURN_YELLOW_PAPER',
    SCHEDULE_RECOMMISSIONING = 'SCHEDULE_RECOMMISSIONING',
    SIGNATURE = 'SIGNATURE',
    SUBMIT = 'SUBMIT',
    UNLOCK_LOCKOUT = 'UNLOCK_LOCKOUT',
}

export enum VariableTypeDTO {
    FIELD_FORM = 'FIELD_FORM',
    NESTED_SINGLETON = 'NESTED_SINGLETON',
    NESTED_COLLECTION = 'NESTED_COLLECTION',
    CONTEXT = 'CONTEXT',
    ORIGINAL_FORM = 'ORIGINAL_FORM',
    INDEX = 'INDEX',
    RUNTIME = 'RUNTIME',
    ATTRIBUTE_OBJECT = 'ATTRIBUTE_OBJECT',
    ATTRIBUTE_ARRAY = 'ATTRIBUTE_ARRAY',
    ARRAY_ACCESS = 'ARRAY_ACCESS',
    OPTIONAL = 'OPTIONAL',
    CONSTANT = 'CONSTANT',
    COMPUTED = 'COMPUTED',
}

export const enum VicDistributionTypeCode {
    FOR_APPROVAL = 'DT_APPROVAL',
    FOR_INFORMATION = 'DT_INFORMATION',
}

export const enum VicStatusCode {
    NEW = 'VS_NEW',
    SAVE = 'VS_SAVE',
    WAITING_APPROVAL = 'VS_EDH_APPROVAL',
    APPROVED = 'VS_APPROVED',
    REJECTED = 'VS_REJECTED',
    CANCELLED = 'VS_CANCELLED',
}

export const enum WorkingTimeCode {
    ANY_DAY = 'WH_ANYD',
    OTHER = 'WH_OTHER',
    NORMAL = 'WH_NORMAL',
    ANY_TIME = 'WH_ANYT',
    EXTENDED_NORMAL = 'WH_EXTENDED_NORMAL',
}
