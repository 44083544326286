import { AxiosResponse } from 'axios';
import { PreciseRulesDTO } from '../../forms/rule/deserializer';
import { buildPath } from '../../lib/url';
import { backendClient as client } from '../clients';
import {
    CommentUpdateDTO,
    FirePermitContextReadDTO,
    FirePermitGridReadDTO,
    FirePermitReadDTO,
    FirePermitStatusCode,
    FirePermitUpdateDTO,
    ListOfValuesReadDTO,
    SupplierReadDTO,
} from '../dto';
import { CreateOne, GetManyPaged, GetManyUnpaged, GetOne, RequestMethod, UpdateOne } from '../types';

export const FP_PATH = 'fire-permit';

export const getFirePermit: GetOne<{ id: string }, FirePermitReadDTO> = async ({ id, config }) =>
    client.get(buildPath([FP_PATH, id]), config).then((response: AxiosResponse) => response.data);

export const getFirePermits: GetManyPaged<FirePermitGridReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([FP_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const createFirePermit: CreateOne<{ data: FirePermitUpdateDTO }, FirePermitReadDTO> = async ({ data, config }) =>
    client.post(buildPath([FP_PATH]), data, config).then((response: AxiosResponse) => response.data);

export const updateFirePermit: UpdateOne<
    { id: number; taskId: string; data: FirePermitUpdateDTO },
    FirePermitReadDTO
> = async ({ id, taskId, data, config }) =>
    client
        .put(buildPath([FP_PATH, id]), data, { ...config, params: { taskId } })
        .then((response: AxiosResponse) => response.data);

export const submitFirePermit: UpdateOne<{ id: number; data: FirePermitUpdateDTO }, FirePermitReadDTO> = async ({
    id,
    data,
    config,
}) => client.put(buildPath([FP_PATH, id, 'submit']), data, config).then((response: AxiosResponse) => response.data);

export const validateSubmitFirePermit: UpdateOne<{ id: number; data: FirePermitUpdateDTO }> = async ({
    id,
    data,
    config,
}) => client.put(buildPath([FP_PATH, id, 'validate']), data, config);

export const closeFirePermit: RequestMethod<{ id: number; data: FirePermitUpdateDTO }, any> = async ({
    id,
    data,
    config,
}) => client.put(buildPath([FP_PATH, id, 'close']), data, config);

export const cancelFirePermit: RequestMethod<{ id: number; comment: CommentUpdateDTO }, any> = async ({
    id,
    comment,
    config,
}) => client.post(buildPath([FP_PATH, id, 'cancel']), comment, config).then((response: AxiosResponse) => response.data);

export const getFirePermitValidationRules: GetOne<
    { id: number | undefined },
    PreciseRulesDTO<FirePermitContextReadDTO>
> = async ({ id, config }) =>
    client
        .get(buildPath([FP_PATH, 'rules']), { ...config, params: { id } })
        .then((response: AxiosResponse) => response.data);

export const getFirePermitStatuses: GetManyUnpaged<{}, ListOfValuesReadDTO<FirePermitStatusCode>> = async ({
    config,
} = {}) => client.get(buildPath([FP_PATH, 'statuses']), config).then((response: AxiosResponse) => response.data);

export const getFirePermitFaxLanguages: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([FP_PATH, 'fax-languages']), config).then((response: AxiosResponse) => response.data);

export const getFirePermitFireDetections: GetManyUnpaged<{ locationId: number | null }, ListOfValuesReadDTO> = async ({
    locationId,
    config,
}) =>
    client
        .get(buildPath([FP_PATH, 'fire-detections', ...(locationId !== null ? [locationId] : [])]), config)
        .then((response: AxiosResponse) => response.data);

export const getFirePermitProtectionMeansExecutants: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({
    config,
} = {}) =>
    client
        .get(buildPath([FP_PATH, 'protection-means-executants']), config)
        .then((response: AxiosResponse) => response.data);

export const getFirePermitWarningDevices: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([FP_PATH, 'warning-devices']), config).then((response: AxiosResponse) => response.data);

export const getFirePermitWorkTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([FP_PATH, 'work-types']), config).then((response: AxiosResponse) => response.data);

export const getFirePermitWorkerTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([FP_PATH, 'worker-types']), config).then((response: AxiosResponse) => response.data);

export const getFirePermitHazardTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([FP_PATH, 'hazard-types']), config).then((response: AxiosResponse) => response.data);

export const getFirePermitPdf: GetOne<{ id: number; langCode: string }, Blob> = async ({ id, langCode, config }) =>
    client
        .get(buildPath([FP_PATH, id, 'pdf']), { ...config, params: { langCode }, responseType: 'blob' })
        .then((response: AxiosResponse) => response.data);

export const getCompanies: GetManyUnpaged<{ activityId: number | undefined }, SupplierReadDTO> = async ({
    activityId,
    config,
}) => {
    if (activityId != undefined) {
        return client
            .get(buildPath(['activities', activityId, 'companies']), config)
            .then((response: AxiosResponse) => response.data);
    }
    return [];
};
