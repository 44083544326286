import { AxiosResponse } from 'axios';
import { PreciseRulesDTO } from '../forms/rule/deserializer';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import {
    ActivityContextReadDTO,
    ActivityGridReadDTO,
    ActivityNimReadDTO,
    ActivityNimUpdateDTO,
    CommentUpdateDTO,
    CompensatoryMeasureTypeReadDTO,
    ContractNimReadDTO,
    ListOfValuesReadDTO,
} from './dto';
import { CreateOne, GetManyPaged, GetManyUnpaged, GetOne, RequestMethod, UpdateOne } from './types';

export const ACTIVITY_PATH = 'activities_nim';

export const getActivity: GetOne<{ id: string }, ActivityNimReadDTO> = async ({ id, config }) =>
    client.get(buildPath([ACTIVITY_PATH, id]), config).then((response) => response.data);

export const getActivities: GetManyPaged<ActivityGridReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([ACTIVITY_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response) => response.data);

export const getActivityStatuses: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([ACTIVITY_PATH, 'statuses']), config).then((response: AxiosResponse) => response.data);

export const getActivityTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([ACTIVITY_PATH, 'activity-types']), config).then((response: AxiosResponse) => response.data);

export const getActivityPriorities: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client
        .get(buildPath([ACTIVITY_PATH, 'activity-priorities']), config)
        .then((response: AxiosResponse) => response.data);

export const getInterventionPeriodTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client
        .get(buildPath([ACTIVITY_PATH, 'intervention-periods']), config)
        .then((response: AxiosResponse) => response.data);

export const getActivityApprovalStatuses: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client
        .get(buildPath([ACTIVITY_PATH, 'approval-statuses']), config)
        .then((response: AxiosResponse) => response.data);

export const getActivityApprovalTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([ACTIVITY_PATH, 'approval-types']), config).then((response: AxiosResponse) => response.data);

export const getActivityMeetingTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([ACTIVITY_PATH, 'meeting-types']), config).then((response: AxiosResponse) => response.data);

export const getParticipantRoles: GetManyUnpaged<{ activityType: string | null }, ListOfValuesReadDTO> = async ({
    activityType,
    config,
}) =>
    client
        .get(buildPath([ACTIVITY_PATH, 'participant-roles', ...(activityType !== null ? [activityType] : [])]), config)
        .then((response: AxiosResponse) => response.data);

export const getCompensatoryMeasureTypes: GetManyPaged<CompensatoryMeasureTypeReadDTO> = async ({
    filter,
    page,
    size,
    sort,
    config,
} = {}) =>
    client
        .get(buildPath([ACTIVITY_PATH, 'compensatory-measure-types']), {
            ...config,
            params: { filter, page, size, sort },
        })
        .then((response) => response.data);

export const updateActivity: UpdateOne<
    { id: number; taskId: string; data: ActivityNimUpdateDTO },
    ActivityNimReadDTO
> = async ({ id, taskId, data, config }) =>
    client
        .put(buildPath([ACTIVITY_PATH, id]), data, { ...config, params: { taskId } })
        .then((response: AxiosResponse) => response.data);

export const createActivity: CreateOne<{ data: ActivityNimUpdateDTO }, ActivityNimReadDTO> = async ({ data, config }) =>
    client.post(buildPath([ACTIVITY_PATH]), data, config).then((response: AxiosResponse) => response.data);

export const submitActivity: UpdateOne<{ id: number; data: ActivityNimUpdateDTO }, ActivityNimReadDTO> = async ({
    id,
    data,
    config,
}) =>
    client.put(buildPath([ACTIVITY_PATH, id, 'submit']), data, config).then((response: AxiosResponse) => response.data);

export const validateSubmitActivity: UpdateOne<{ id: number; data: ActivityNimUpdateDTO }> = async ({
    id,
    data,
    config,
}) => client.put(buildPath([ACTIVITY_PATH, id, 'validate']), data, config);

export const getActivityValidationRules: GetOne<
    { id: number | undefined },
    PreciseRulesDTO<ActivityContextReadDTO>
> = async ({ id, config }) =>
    client
        .get(buildPath([ACTIVITY_PATH, 'rules']), { ...config, params: { id } })
        .then((response: AxiosResponse) => response.data);

export const cancelActivity: RequestMethod<{ id: number; comment: CommentUpdateDTO }, any> = async ({
    id,
    comment,
    config,
}) =>
    client
        .post(buildPath([ACTIVITY_PATH, id, 'cancel']), comment, config)
        .then((response: AxiosResponse) => response.data);

export const facilityCoordinatorSchedule: RequestMethod<{ id: number }, any> = async ({ id, config }) =>
    client
        .get(buildPath([ACTIVITY_PATH, id, 'facility-coordinator-schedule']), config)
        .then((response: AxiosResponse) => response.data);

export const closeActivity: RequestMethod<{ id: number }, any> = async ({ id, config }) =>
    client.get(buildPath([ACTIVITY_PATH, id, 'close']), config).then((response: AxiosResponse) => response.data);

export const interruptActivity: RequestMethod<{ id: number }, any> = async ({ id, config }) =>
    client.get(buildPath([ACTIVITY_PATH, id, 'interrupt']), config).then((response: AxiosResponse) => response.data);

export const resumeActivity: RequestMethod<{ id: number }, any> = async ({ id, config }) =>
    client.get(buildPath([ACTIVITY_PATH, id, 'resume']), config).then((response: AxiosResponse) => response.data);

export const addSignatureTask: RequestMethod<{ id: number }, any> = async ({ id, config }) =>
    client
        .get(buildPath([ACTIVITY_PATH, id, 'add-signature-task']), config)
        .then((response: AxiosResponse) => response.data);

export const getContracts: GetManyPaged<ContractNimReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([ACTIVITY_PATH, 'contracts']), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);
