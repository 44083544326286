import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import z from 'zod';
import { getDimrByVersionId } from '../../../api/documents/dimrs';
import { getWdpRules, getWdpVersion, getWdpVersionLatest } from '../../../api/documents/wdps';
import { DocumentType } from '../../../api/dto';
import { ImpactHelmet } from '../../../components/ImpactHelmet';
import { defaultUrlParamsSchema, withUrlParams } from '../../../components/wrappers/withUrlParams';
import { WdpForm } from '../../../forms/documents/WdpForm';
import { getRoute, ROUTES } from '../../../routes/routes';
import { WdpPageVariant } from './WdpPageVariant';

const schema = z.union([
    defaultUrlParamsSchema.extend({
        variant: z.literal(WdpPageVariant.CREATE),
        queryParams: z.object({
            dimr: z.coerce.number().optional(),
        }),
    }),
    defaultUrlParamsSchema.extend({
        variant: z.union([z.literal(WdpPageVariant.CLONE), z.literal(WdpPageVariant.READ)]),
        pathParams: z.object({
            id: z.coerce.number(),
            version: z.coerce.number().optional(),
        }),
    }),
]);

const WdpPageContent: React.FC<z.infer<typeof schema>> = ({ variant, pathParams, queryParams }) => {
    const [spreadsheetFullscreen, setSpreadsheetFullscreen] = useState(false);
    const intl = useIntl();
    const navigate = useNavigate();

    const {
        data: wdpData,
        isLoading: wdpDataLoading,
        isError: wdpDataFailed,
        remove,
        refetch,
    } = useQuery(
        [
            DocumentType.WDP,
            ...(variant !== WdpPageVariant.CREATE ? [pathParams.id, pathParams.version] : [undefined, undefined]),
        ],
        () => {
            if (variant === WdpPageVariant.CREATE) {
                throw new Error();
            }
            return pathParams.version !== undefined
                ? getWdpVersion({ id: String(pathParams.id), versionNumber: pathParams.version })
                : getWdpVersionLatest({ id: String(pathParams.id) });
        },
        {
            enabled: variant !== WdpPageVariant.CREATE,
        }
    );
    const dimrVersionId = variant === WdpPageVariant.CREATE ? queryParams.dimr : wdpData?.dimr?.id;
    useEffect(() => {
        if (wdpData) {
            if (variant === WdpPageVariant.READ) {
                navigate(
                    getRoute({
                        path: ROUTES.wdp.view,
                        params: { id: wdpData.wdpMasterId, version: wdpData.versionNumber },
                    }),
                    {
                        replace: true,
                    }
                );
            }
        }
    }, [wdpData, navigate]);
    const {
        data: dimrData,
        isLoading: isDimrDataLoading,
        isError: dimrDataFailed,
    } = useQuery(['dimr', dimrVersionId], () => getDimrByVersionId({ id: String(dimrVersionId) }), {
        enabled: dimrVersionId != null,
    });
    const {
        data: rulesData,
        isLoading: isRulesDataLoading,
        isError: rulesDataFailed,
    } = useQuery([DocumentType.WDP, 'rules'], () => getWdpRules());
    const refreshData = useCallback(() => {
        remove();
        return refetch();
    }, [remove, refetch]);

    if (
        (wdpDataLoading && variant !== WdpPageVariant.CREATE) ||
        (dimrVersionId !== undefined && isDimrDataLoading) ||
        isRulesDataLoading
    ) {
        return (
            <>
                <LinearProgress />
                <ImpactHelmet documentType={DocumentType.WDP} title={intl.formatMessage({ id: 'title.loading' })} />
            </>
        );
    } else if (wdpDataFailed || dimrDataFailed || rulesDataFailed) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                <FormattedMessage id="wdp.error.document" />
                <ImpactHelmet documentType={DocumentType.WDP} title={intl.formatMessage({ id: 'title.error' })} />
            </Alert>
        );
    } else if (
        variant === WdpPageVariant.CREATE ||
        ((variant === WdpPageVariant.READ || variant === WdpPageVariant.CLONE) && wdpData)
    ) {
        return (
            <WdpForm
                key={variant}
                data={
                    variant === WdpPageVariant.CREATE ? { variant, dimrData: dimrData } : { variant, wdpData: wdpData! }
                }
                refreshData={refreshData}
                rules={rulesData}
                spreadsheetFullscreen={spreadsheetFullscreen}
                setSpreadsheetFullscreen={setSpreadsheetFullscreen}
            />
        );
    } else {
        return <Fragment />;
    }
};

export const WdpPage = withUrlParams(WdpPageContent, schema);
