import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Fragment, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import z from 'zod';
import { getActivity } from '../../../api/activities';
import { getLockout, getLockoutType, getLockoutValidationRules } from '../../../api/documents/lockouts';
import { DocumentType, LockoutTypeCode } from '../../../api/dto';
import { ImpactHelmet } from '../../../components/ImpactHelmet';
import { defaultUrlParamsSchema, withUrlParams } from '../../../components/wrappers/withUrlParams';
import { LockoutForm } from '../../../forms/documents/LockoutForm';
import { LockoutPageVariant } from './LockoutPageVariant';

const schema = z.union([
    defaultUrlParamsSchema.extend({
        variant: z.literal(LockoutPageVariant.CREATE),
        queryParams: z.object({
            activity: z.coerce.number(),
            type: z.enum([LockoutTypeCode.ELECTRICAL, LockoutTypeCode.CRYO]),
        }),
    }),
    defaultUrlParamsSchema.extend({
        variant: z.union([z.literal(LockoutPageVariant.CLONE), z.literal(LockoutPageVariant.READ)]),
        pathParams: z.object({
            id: z.coerce.number(),
        }),
    }),
]);

export const LockoutPageContent: React.FC<z.infer<typeof schema>> = ({ variant, pathParams, queryParams }) => {
    const intl = useIntl();

    const {
        data: lockoutData,
        isLoading: lockoutDataLoading,
        isError: lockoutDataFailed,
        remove,
        refetch,
    } = useQuery(
        [DocumentType.LOK, variant !== LockoutPageVariant.CREATE ? pathParams.id : undefined],
        () => {
            if (variant === LockoutPageVariant.CREATE) {
                throw new Error();
            }
            return getLockout({ id: String(pathParams.id) });
        },
        {
            enabled: variant !== LockoutPageVariant.CREATE,
        }
    );

    const activityId = variant !== LockoutPageVariant.CREATE ? lockoutData?.activity.id : queryParams.activity;

    const {
        data: activityData,
        isLoading: isActivityDataLoading,
        isError: activityDataFailed,
    } = useQuery(
        ['activity', activityId],
        () => {
            if (activityId === undefined) {
                throw new Error();
            }
            return getActivity({ id: String(activityId) });
        },
        {
            enabled: activityId !== undefined,
        }
    );

    const {
        data: lockoutTypeData,
        isLoading: isLockoutTypeLoading,
        isError: lockoutTypeDataFailed,
    } = useQuery(
        ['lockoutType', variant === LockoutPageVariant.CREATE ? queryParams.type : undefined],
        () => {
            if (variant !== LockoutPageVariant.CREATE) {
                throw new Error();
            }
            return getLockoutType({ lockoutTypeCode: String(queryParams.type) });
        },
        {
            enabled: variant === LockoutPageVariant.CREATE,
        }
    );

    const refreshData = useCallback(() => {
        remove();
        return refetch();
    }, [remove, refetch]);

    const {
        data: rulesData,
        isLoading: isRulesDataLoading,
        isError: rulesDataFailed,
    } = useQuery([DocumentType.LOK, variant === LockoutPageVariant.READ ? pathParams.id : undefined, 'rules'], () =>
        getLockoutValidationRules({ id: variant === LockoutPageVariant.READ ? String(pathParams.id) : undefined })
    );

    if (
        (lockoutDataLoading && variant !== LockoutPageVariant.CREATE) ||
        (isActivityDataLoading && activityId !== undefined) ||
        (isLockoutTypeLoading && variant === LockoutPageVariant.CREATE) ||
        isRulesDataLoading
    ) {
        return <LinearProgress />;
    } else if (lockoutDataFailed || activityDataFailed || lockoutTypeDataFailed || rulesDataFailed) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                <FormattedMessage id="lockout.error.document" />
                <ImpactHelmet documentType={DocumentType.LOK} title={intl.formatMessage({ id: 'title.error' })} />
            </Alert>
        );
    } else if (
        activityData &&
        ((variant === LockoutPageVariant.CREATE && lockoutTypeData) ||
            ((variant === LockoutPageVariant.READ || variant === LockoutPageVariant.CLONE) && lockoutData))
    ) {
        return (
            <LockoutForm
                data={
                    variant === LockoutPageVariant.CREATE
                        ? { variant, activityData: activityData!, lockoutType: lockoutTypeData! }
                        : { variant, lockoutData: lockoutData!, activityData }
                }
                rules={rulesData}
                refreshData={refreshData}
            />
        );
    } else {
        return <Fragment />;
    }
};

export const LockoutPage = withUrlParams(LockoutPageContent, schema);
