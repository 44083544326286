import { default as qs, default as queryString } from 'query-string';
import { IntlShape } from 'react-intl';
import { EnvironmentDTO } from '../api/dto';
import { ENVIRONMENT_SLUGS } from '../components/application/ApplicationSettingsProvider';
import { PageContextState } from '../components/application/PageContextProvider';
import { getDocumentName, getDocumentTypeName, getDocumentUrl } from '../lib/records/documents/document';

const getDocsRoute = (route: string, environment: EnvironmentDTO): string =>
    `https://impact-ng.docs.cern.ch/${
        ENVIRONMENT_SLUGS[environment !== EnvironmentDTO.LOCAL ? environment : EnvironmentDTO.DEVELOPMENT]
    }${route}`;

const getServiceNowTicketRoute =
    (type: 'request' | 'incident') =>
    (options?: { context: PageContextState; intl: IntlShape }): string => {
        let subject: string | undefined;
        let description: string | undefined;
        if (options !== undefined) {
            const { context, intl } = options;
            if ('documentType' in context) {
                if ('documentId' in context && context.documentId != null) {
                    const ctx = {
                        ...context,
                        documentVersion:
                            'documentVersion' in context && context.documentVersion != null
                                ? context.documentVersion
                                : null,
                    };
                    subject = getDocumentName(ctx, intl);
                    description = `${window.location.origin}${getDocumentUrl(ctx)}`;
                } else {
                    subject = getDocumentTypeName(context.documentType, intl);
                }
            }
        }
        const params = {
            id: 'sc_cat_item',
            name: type,
            fe: 'impact-new',
            short_description: subject,
            comments: description,
        };
        return `https://cern.service-now.com/service-portal?${queryString.stringify(params)}`;
    };

export const getServiceNowKnowledgeBase = (kbId: `KB${number}`) =>
    `https://cern.service-now.com/service-portal?${queryString.stringify({
        id: 'kb_article',
        n: kbId,
    })}`;

const getInternationalCernPhoneNumber = (cernPhoneNumber: string): string => `+412276${cernPhoneNumber}`;
export const SUPPORT_PHONE_NUMBER = '68853';

export const EXTERNAL_ROUTES = {
    support: {
        help: (environment: EnvironmentDTO) => getDocsRoute('/documentation/', environment),
        question: getServiceNowTicketRoute('request'),
        incidentReport: getServiceNowTicketRoute('incident'),
        phone: `tel:${getInternationalCernPhoneNumber(SUPPORT_PHONE_NUMBER)}`,
    },
    privacyNotice:
        'https://cern.service-now.com/service-portal?id=privacy_policy&se=intervention-scheduling-app-support&notice=impact',
    changelog: (environment: EnvironmentDTO) => getDocsRoute('/documentation/IMPACT%20Upgrade/Versions/', environment),
} as const;

export const getEdmsUrl = (documentId: string): string => {
    return `https://edms.cern.ch/document/${encodeURIComponent(documentId)}`;
};

export const getJmtUrl = (documentId: string): string => {
    return `https://edms5.cern.ch/pls/jmt/jmtw_job.info?${qs.stringify({ p_job_no: documentId })}`;
};

export const getEdhUrl = (documentId: string): string => {
    return `https://edh.cern.ch/Document/${encodeURIComponent(documentId)}`;
};
