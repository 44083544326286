import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Fragment, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import z from 'zod';
import { getActivity } from '../../../api/activities';
import {
    getDimrValidationRules,
    getDimrVersion,
    getDimrVersionLatest,
    getSuggestedValues,
} from '../../../api/documents/dimrs';
import { DocumentType } from '../../../api/dto';
import { ImpactHelmet } from '../../../components/ImpactHelmet';
import { defaultUrlParamsSchema, withUrlParams } from '../../../components/wrappers/withUrlParams';
import { DimrForm } from '../../../forms/documents/DimrForm';
import { getRoute, ROUTES } from '../../../routes/routes';
import { DIMRPageVariant } from './DIMRPageVariant';

const schema = z.union([
    defaultUrlParamsSchema.extend({
        variant: z.literal(DIMRPageVariant.CREATE),
        queryParams: z.object({
            activity: z.coerce.number(),
        }),
    }),
    defaultUrlParamsSchema.extend({
        variant: z.literal(DIMRPageVariant.READ),
        pathParams: z.object({
            id: z.coerce.number(),
            version: z.coerce.number().optional(),
        }),
    }),
]);

const DIMRPageContent: React.FC<z.infer<typeof schema>> = ({ variant, pathParams, queryParams }) => {
    const intl = useIntl();
    const navigate = useNavigate();

    const {
        data: dimrData,
        isLoading: dimrDataLoading,
        isError: dimrDataFailed,
        remove,
        refetch,
    } = useQuery(
        [
            DocumentType.DIMR,
            ...(variant === DIMRPageVariant.READ ? [pathParams.id, pathParams.version] : [undefined, undefined]),
        ],
        // The version is optional, if not set we assume it is the latest one
        () => {
            if (variant !== DIMRPageVariant.READ) {
                throw new Error();
            }
            return pathParams.version !== undefined
                ? getDimrVersion({ id: String(pathParams.id), versionNumber: pathParams.version })
                : getDimrVersionLatest({ id: String(pathParams.id) });
        },
        {
            enabled: variant === DIMRPageVariant.READ,
        }
    );

    useEffect(() => {
        if (dimrData && variant === DIMRPageVariant.READ) {
            navigate(
                getRoute({
                    path: ROUTES.dimr.view,
                    params: { id: dimrData.dimrMasterId, version: dimrData.versionNumber },
                }),
                {
                    replace: true,
                }
            );
        }
    }, [dimrData, navigate]);

    const activityId = variant === DIMRPageVariant.CREATE ? queryParams.activity : undefined;

    const {
        data: activityData,
        isLoading: isActivityDataLoading,
        isError: activityDataFailed,
    } = useQuery(
        ['activity', activityId],
        () => {
            if (variant !== DIMRPageVariant.CREATE) {
                throw new Error();
            }
            return getActivity({ id: String(queryParams.activity) });
        },
        {
            enabled: variant === DIMRPageVariant.CREATE,
        }
    );

    const {
        data: suggestedValuesData,
        isLoading: isSuggestedValuesDataLoading,
        isError: suggestedValuesFailed,
    } = useQuery(['values', activityId], () => getSuggestedValues({ activityId: String(activityId) }), {
        enabled: activityId !== undefined,
    });

    const {
        data: rulesData,
        isLoading: isRulesDataLoading,
        isError: rulesDataFailed,
    } = useQuery(
        [DocumentType.DIMR, dimrData?.id, dimrData?.version, 'rules'],
        () => getDimrValidationRules({ id: dimrData?.id! }),
        {
            enabled: variant === DIMRPageVariant.CREATE || dimrData !== undefined || !dimrDataFailed,
        }
    );

    const refreshData = useCallback(() => {
        remove();
        return refetch();
    }, [remove, refetch]);

    if (
        (dimrDataLoading && variant === DIMRPageVariant.READ) ||
        (isActivityDataLoading && activityId !== undefined) ||
        (isSuggestedValuesDataLoading && activityId !== undefined) ||
        isRulesDataLoading
    ) {
        return (
            <>
                <LinearProgress />
                <ImpactHelmet documentType={DocumentType.DIMR} title={intl.formatMessage({ id: 'title.loading' })} />
            </>
        );
    } else if (dimrDataFailed || activityDataFailed || rulesDataFailed || suggestedValuesFailed) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                <FormattedMessage id="dimr.error.document" />
                <ImpactHelmet documentType={DocumentType.DIMR} title={intl.formatMessage({ id: 'title.error' })} />
            </Alert>
        );
    } else if ((variant === DIMRPageVariant.CREATE && activityData) || (variant === DIMRPageVariant.READ && dimrData)) {
        return (
            <DimrForm
                data={
                    variant === DIMRPageVariant.CREATE
                        ? { variant, activityData: activityData!, suggestedValues: suggestedValuesData! }
                        : { variant, dimrData: dimrData! }
                }
                rules={rulesData}
                refreshData={refreshData}
            />
        );
    } else {
        return <Fragment />;
    }
};

export const DIMRPage = withUrlParams(DIMRPageContent, schema);
